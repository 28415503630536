import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle, Snackbar } from "@mui/material"



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    }
}));

const SnackbarComponentWithContent = (props) => {
    const classes = useStyles();


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        props.setSnackbarStateFalse({
            open: false,
            severity: props.snackbarState.severity,
            message: props.snackbarState.message,
            "content":""
        })
    };

    return (
        <div className={classes.root}>
            <Snackbar open={props.snackbarState.open} autoHideDuration={2800} onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} 
           >
                <Alert onClose={handleClose} severity={props.snackbarState.severity} style={{borderRadius:'16px'}} elevation={6} variant="filled">
                    <AlertTitle>{props.snackbarState.message}</AlertTitle>{props.snackbarState.content}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default SnackbarComponentWithContent