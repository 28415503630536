import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import './customAutoCompleteMultiple.css'
import { useState } from 'react';
import Chip from '@mui/material/Chip';
import '../../IntentData.css'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ThemeProvider, createTheme } from '@mui/material/styles';


  const theme = createTheme({
    components: {
        MuiAutocomplete: {
            styleOverrides: {
              listbox: {
                '& .MuiAutocomplete-option[aria-selected="true"]': {  // works
                  background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #21DA8C`,
                },
                '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': { // works
                  background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #21DA8C`,
                },
                '& .MuiAutocomplete-option:hover': { // works
                    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #21DA8C`,
                },
                
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#21DA8C"
                }
              }
            }
          }
    }
  });
export const CustomAutoCompleteMultiple=({options,styles,variant,disabled,placeholder,selected,updateSelected})=>{
  // console.log("options autoCom",options);
    const [value,SetValue]=useState(selected);
    // console.log("label",value);
    let newRow =false
    if(value[0].label===""){
      newRow=true;
    }
    const handleChange=(value)=>{
      // console.log("change",value);
      if(value.length!==0){
        SetValue(value);
        updateSelected(value)
    }
    }
    return(
      <>
        {!newRow?<div className='multi'>
        <ThemeProvider theme={theme}>
        <Autocomplete
        multiple
        id="tags-outlined"
        options={options}
        getOptionLabel={(option) => option.label}
        defaultValue={value}
        filterSelectedOptions
        onChange={(e,value)=>handleChange(value)}
        sx={styles}
        popupIcon={<KeyboardArrowDownRoundedIcon style={{ color: "rgba(3, 3, 3, 0.6)" }} />}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option.label}
              size="small"
              deleteIcon={<CloseRoundedIcon style={{ color: "#FFFFFF", fontSize: "medium", fontWeight: "700" }} />}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant={variant}
            small
          />
        )}
      />
        </ThemeProvider>
      
      </div>:<div className='multi'>
      <ThemeProvider theme={theme}>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={options}
        getOptionLabel={(option) => option.label}
        // value={[{"label":null}]}
        // freeSolo
         selectOnFocus
         clearOnBlur
        filterSelectedOptions
        onChange={(e,value)=>handleChange(value)}
        sx={styles}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option.label}
              size="small"
              deleteIcon={<CloseRoundedIcon style={{ color: "#FFFFFF", fontSize: "medium", fontWeight: "700" }} />}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant={variant}
            small
          />
        )}
      />
      </ThemeProvider>
      
      </div>}
      </>
      
        
    )
}