import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export const NewAttributeButton=({handleClick})=>{
    return (
        <Button variant="filled" style={{color:"#fff",borderRadius:"20px",paddingLeft:"5px",backgroundColor:"#21DA8C"}} onClick={(e)=>handleClick()}>
                    <AddIcon style={{marginRight:"5px"}}/><Typography variant='button medium' style={{fontWeight:800}}>ATTRIBUTE</Typography> 
        </Button>
    )
}