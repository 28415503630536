import { Typography } from '@mui/material';
import { CustomAutoComplete } from './CustomAutoComplete';
import {CustomAutoCompleteMultiple} from './Autocomplte/CustomAutoCompleteMultiple';
import TextField from '@mui/material/TextField';
import { DeleteButton } from './DeleteButton';
import { useState } from 'react';
import './newFilterValuesNumerical.css'
import { ThemeProvider, createTheme } from '@mui/material/styles';


  const theme = createTheme({
    components: {
        MuiAutocomplete: {
            styleOverrides: {
              listbox: {
                '& .MuiAutocomplete-option[aria-selected="true"]': {  // works
                    backgroundColor: '#21DA8C',
                },
                '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': { // works
                    backgroundColor: '#21DA8C',
                },
                '& .MuiAutocomplete-option:hover': { // works
                    backgroundColor: '#21DA8C',
                },
                
              },
            },
          }
    }
  });
export const NewFilterValuesNumerical=({obj,options,selectedWeightage,updateObj,handleDelete})=>{
    console.log("object coming",obj);
    const [dataObj,setDataObj]=useState(obj);
    const [minValue,setMinValue]=useState(obj.valueMin);
    const [maxValue,setMaxValue]=useState(obj.valueMax);
    const [minValueError,setMinValueError]=useState(false);
    const [maxValueError,setMaxValueError]=useState(false);
    const [minValueErrorMessage,setMinValueErrorMessage]=useState("");
    const [maxValueErrorMessage,setMaxValueErrorMessage]=useState("");

    // '& .MuiOutlinedInput-root': {
    //     '&:hover fieldset': {
    //       borderColor: 'yellow',
    //     },
    //   },


    // const handleValueChange=(input)=>{
    //     let newValue="";
    //     input.map(obj=>{
    //         if(newValue===""){
    //             newValue+=obj.value;
    //         }else{
    //             newValue=newValue+","+obj.value;
    //         }
    //     })
    //     setDataObj((latestobj)=>{
    //         const newObj={...latestobj}
    //         newObj.value=newValue
    //         updateObj(newObj);
    //         return newObj;
    //     })
    // }

    const handleWeightageChange=(input)=>{
        setDataObj((latestobj)=>{
            const newObj={...latestobj}
            newObj.importance=input?.value;
            updateObj(newObj);
            return newObj;
        })
    }

    const handleValuechange=async(value,type)=>{
        console.log("change",value,type);
        let previousMin=minValue;
        let previousMax=maxValue;
        if(value && value!==null){
            const newObj={...dataObj}
                if(type==='min'){
                    newObj.valueMin=value
                    setMinValue(value);
                }else{
                    newObj.valueMax=value;
                    setMaxValue(value);
                }
                let result=await updateObj(newObj);
                console.log("result is",result)
                if(result===""){
                    setMinValueError(false);
                    setMinValueErrorMessage("");
                    setMaxValueError(false);
                    setMaxValueErrorMessage("");
                    setDataObj(newObj);
                    // return newObj;
                }else{
                    if(type==='min'){
                        // setMinValue(previousMin);
                        setMinValueError(true);
                        setMinValueErrorMessage(result);
                        
                    }else{
                        // setMaxValue(previousMax)
                        setMaxValueError(true);
                        setMaxValueErrorMessage(result);
                    }
                    // return latestobj
                }
            // setDataObj((latestobj)=>{
                
            // })
        }
        
    }


    const handleDeleteRow=()=>{
        handleDelete(dataObj.rowid);
    }
    return(
        <div className='numerical' style={{display:"flex",alignItems:"center"}}>
            <div style={{display:"flex",flexDirection:"column",marginLeft:"10px",maxHeight: "35px"}}>
            <TextField
          id="outlined-number"
          type="number"
          error={minValueError}
          value={minValue}
          InputProps={{
            sx: {
            '&:hover fieldset': {
                borderColor:"#21DA8C !important"
            },
            '&:focus-within fieldset, &:focus-visible fieldset': {
                borderColor:"#21DA8C !important"
            },
            },
        }}
          InputLabelProps={{
            shrink: true,
          }}
          
        onChange={(e)=>handleValuechange(e.target.value,"min")}
          helperText={minValueErrorMessage}
        />
        </div>
        <div style={{margin:"10px"}}>
        <p>-</p>
        </div>
            <div style={{display:"flex",flexDirection:"column",maxHeight: "35px"}}>
            <TextField
          id="outlined-number"
          type="number"
          value={maxValue}
          error={maxValueError}
          InputProps={{
            sx: {
            '&:hover fieldset': {
                borderColor:"#21DA8C !important"
            },
            '&:focus-within fieldset, &:focus-visible fieldset': {
                borderColor:"#21DA8C !important"
            },
            },
        }}
          InputLabelProps={{
            shrink: true,
          }}
          helperText={maxValueErrorMessage}
          onChange={(e)=>handleValuechange(e.target.value,"max")}
        />
            </div>
            <div className='complete' style={{display:"flex",flexDirection:"column",marginLeft:"10px"}}>
                <CustomAutoComplete styles={{width:250}} variant={"outlined"} disabled={false} options={options} selected={selectedWeightage} updateSelected={handleWeightageChange} multiple={false}/>
            </div>
            <div ><DeleteButton handleDelete={handleDeleteRow}/></div>
        </div>
    )
}