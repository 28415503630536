import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import './IntentData.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const countries= [
    "Afghanistan",
    "Aland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "British Virgin Islands",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Cote Divoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "France, Metropolitan",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard and McDonald Islands",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iraq",
    "Ireland",
    "Islamic Republic of Iran",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (North)",
    "Korea (South)",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libyan Arab Jamahiriya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestinian Territory",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "S. Georgia and S. Sandwich Islands",
    "Saint Lucia",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "Spain",
    "Sri Lanka",
    "St. Helena",
    "St. Kitts and Nevis",
    "St. Pierre and Miquelon",
    "St. Vincent's & Grenadines",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen Islands",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom (Great Britain)",
    "United States",
    "United States Minor Outlying Islands",
    "United States Virgin Islands",
    "Uruguay",
    "USSR (former)",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City State",
    "Venezuela",
    "Vietnam",
    "Wallis And Futuna Islands",
    "Western Sahara",
    "Yemen",
    "Zaire",
    "Zambia",
    "Zimbabwe"
]

const industries=[
    "Agriculture",
    "Agriculture > Animals & Livestock",
    "Agriculture > Crops",
    "Agriculture > Forestry",
    "Business Services",
    "Business Services > Accounting",
    "Business Services > Advertising & Marketing",
    "Business Services > Commercial Printing",
    "Business Services > Graphic Design",
    "Business Services > HR & Recruiting",
    "Business Services > Import/Export",
    "Business Services > Management Consulting",
    "Business Services > Waste Management",
    "Construction",
    "Construction > Architecture",
    "Construction > Commercial Building",
    "Construction > Contractors",
    "Construction > Heavy Construction",
    "Construction > Residential Building",
    "Consumer Services",
    "Consumer Services > Auto Repair",
    "Consumer Services > Car Rental",
    "Consumer Services > Laundry & Dry Cleaning",
    "Consumer Services > Non-Auto Repair",
    "Consumer Services > Veterinary",
    "Consumer Services > Weight Health Management",
    "Cultural",
    "Cultural > Libraries",
    "Cultural > Museums & Art Galleries",
    "Cultural > Performance Arts",
    "Education",
    "Education > Colleges & Universities",
    "Education > K-12 Schools",
    "Education > Professional, Technical & Trade Schools",
    "Energy, Utilities & Waste",
    "Energy, Utilities & Waste > Energy",
    "Energy, Utilities & Waste > Utilities",
    "Energy, Utilities & Waste > Waste Treatment",
    "Finance",
    "Finance > Banking",
    "Finance > Credit Card Transaction Processing",
    "Finance > Investment Banking",
    "Finance > Portfolio Management & Financial Advice",
    "Finance > Venture Capital Private Equity & Fund Raising",
    "Government",
    "Government > Federal",
    "Government > Public Safety",
    "Government > State & Province",
    "Government > Towns, Cities & Municipalities",
    "Healthcare",
    "Healthcare > Hospitals & Clinics",
    "Healthcare > Medical Offices",
    "Healthcare > Pharmaceuticals",
    "Hospitality & Hotels",
    "Hospitality & Hotels > Lodging & Resorts",
    "Hospitality & Hotels > Restaurants",
    "Insurance",
    "Insurance > Accident & Health",
    "Insurance > Property & Casualty",
    "Legal",
    "Manufacturing",
    "Manufacturing > Aerospace & Defense",
    "Manufacturing > Automobile",
    "Manufacturing > Boats & Marine",
    "Manufacturing > Building Materials",
    "Manufacturing > Chemicals & Gases",
    "Manufacturing > Computer Equipment",
    "Manufacturing > Consumer Goods",
    "Manufacturing > Electronics",
    "Manufacturing > Food & Beverage",
    "Manufacturing > Furniture",
    "Manufacturing > Machinery",
    "Manufacturing > Metals & Mining",
    "Manufacturing > Plastics, Synthetics & Rubber",
    "Manufacturing > Semiconductors",
    "Manufacturing > Textiles & Apparel",
    "Manufacturing > Wood & Paper Products",
    "Media & Internet",
    "Media & Internet > Info Collection & Delivery",
    "Media & Internet > Media Broadcasting",
    "Media & Internet > Movie & Video",
    "Media & Internet > Music",
    "Media & Internet > Newspapers & News Services",
    "Media & Internet > Publishing",
    "Media & Internet > Search Engines & Internet Portals",
    "Non-Profit",
    "Real Estate",
    "Real Estate > Commercial",
    "Real Estate > Residential",
    "Recreation",
    "Resource Extraction",
    "Resource Extraction > Metals & Mining",
    "Resource Extraction > Oil & Gas",
    "Retail",
    "Retail > Apparel & Fashion",
    "Retail > Consumer Electronics",
    "Retail > Department Stores & Super Stores",
    "Retail > Drug Stores & Pharmacies",
    "Retail > eCommerce",
    "Retail > Furniture",
    "Retail > Gas Stations & Convenience",
    "Retail > Grocery",
    "Retail > Home Improvement & Hardware",
    "Retail > Jewelry",
    "Retail > Motor Vehicles",
    "Retail > Office Products",
    "Retail > Pet Products",
    "Software",
    "Software > Business Intelligence",
    "Software > Database & File Management",
    "Software > Financial/Legal/HR",
    "Software > Healthcare",
    "Software > Security",
    "Sports",
    "Telecommunications",
    "Telecommunications > Cable & Satellite",
    "Telecommunications > ISPs",
    "Telecommunications > Telephony & Wireless",
    "Transportation & Travel",
    "Transportation & Travel > Aviation & Aerospace",
    "Transportation & Travel > Marine Shipping",
    "Transportation & Travel > Transit & Non-Aviation Passengers",
    "Transportation & Travel > Travel Agencies & Reservation Services",
    "Transportation & Travel > Trucking & Logistics",
    "Transportation & Travel > Warehousing",
    "Wholesalers",
    "Wholesalers > Durable Goods",
    "Wholesalers > Non-Durable Goods",
    "Military",
    "Gaming",
    "Manufacturing > Industrial Engineering",
    "Finance > Trade & Development"
]

const companySize=[
    "Micro (1 - 9 Employees)",
    "Small (10 - 49 Employees)",
    "Medium-Small (50 - 199 Employees)",
    "Medium (200 - 499 Employees)",
    "Medium-Large (500 - 999 Employees)",
    "Large (1,000 - 4,999 Employees)",
    "XLarge (5,000 - 10,000 Employees)",
    "XXLarge (10,000+ Employees)"
]

const IntentFilters=(props)=>{
    console.log("props for filters--",props)

    const [selectedCountry,setSelectedCountry]=React.useState(props?.selectedCountries);
    const [selectedIndustry,setSelectedIndustry]=React.useState(props?.selectedIndustries);
    const [selectedCompany,setSelectedCompany]=React.useState(props?.selectedCompanySize);

    const handleChange=(type,newValues)=>{
        console.log("changed",type,newValues);
        if(type==='country'){
            const newCountries=newValues.map((obj)=>{
                if(obj){
                    return obj
                }   
            })
            console.log("changed values",type,newCountries);
            setSelectedCountry(newCountries);
            props.setSelectedCountries(newCountries);
        }
        if(type==='industry'){
            const newIndustries=newValues.map((obj)=>{
                if(obj){
                    return obj;
                }   
            })
            setSelectedIndustry(newIndustries)
            props.setSelectedIndustries(newIndustries);
        }
        if(type==='companySize'){
            const newCompanySizes=newValues.map((obj)=>{
                if(obj){
                    return obj;
                }   
            })
            setSelectedCompany(newCompanySizes);
            props.setSelectedCompanySize(newCompanySizes);
        }
        
    }
    return(<div>

    <div className="intentLevel">
    <div className="columnHeaderFilter" style={{paddingTop:"16px"}}>Country </div>
    <Autocomplete
        multiple
        id="tags-standard"
        options={countries}
        filterSelectedOptions
        getOptionLabel={(option) => option}
        defaultValue={props?.selectedCountries}
        value={props?.selectedCountries}
        onChange={(e,newValue)=>handleChange("country",newValue)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              size="small"
              {...getTagProps({ index })}
              deleteIcon={<CloseRoundedIcon style={{ color: "#FFFFFF", fontSize: "medium", fontWeight: "700" }} />}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size='small'
          />
        )}
        style={{marginLeft: "70px",width:"80vw"}}
      />
    </div>
    <div className="intentLevel">
    <div className="columnHeaderFilter">Industry </div>
    <Autocomplete
        multiple
        id="tags-standard"
        options={industries}
        getOptionLabel={(option) => option}
        defaultValue={props?.selectedIndustries}
        filterSelectedOptions
        value={props?.selectedIndustries}
        onChange={(e,newValue)=>handleChange("industry",newValue)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              size="small"
              deleteIcon={<CloseRoundedIcon style={{ color: "#FFFFFF", fontSize: "medium", fontWeight: "700" }} />}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size='small'
            sx={{color:"rgb(138, 138, 138)"}}
          />
        )}
        style={{marginLeft: "70px",width:"80vw"}}
      />
    </div>
    <div className="intentLevel">
    <div className="columnHeaderFilter">Company size</div>  
    <Autocomplete
    multiple
    id="tags-standard"
    filterSelectedOptions
    options={companySize}
    getOptionLabel={(option) => option}
    defaultValue={props.selectedCompanySize}
    value={props.selectedCompanySize}
    onChange={(e,newValue)=>handleChange("companySize",newValue)}
    renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              size="small"
              deleteIcon={<CloseRoundedIcon style={{ color: "#FFFFFF", fontSize: "medium", fontWeight: "700" }} />}
              {...getTagProps({ index })}
            />
          ))
        }
    renderInput={(params) => (
        <TextField
        {...params}
        variant="outlined"
        size='small'
        sx={{color:"rgb(138, 138, 138)"}}
        />
    )}
    style={{marginLeft: "70px",width:"80vw"}}
    />
    
    </div>
    
    </div>)
    
}  

export default IntentFilters;