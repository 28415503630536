import React, { useEffect, useState } from 'react'
import './ShowFilter.css';
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import CircularProgress from '@material-ui/core/CircularProgress'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import axios from "axios"
// import { REACT_APP_API_URL, REACT_APP_API_URL3 } from "./../Constant"
import { REACT_APP_API_URL, REACT_APP_API_URL2, REACT_APP_API_URL3, TENANTCONFIG_API } from "./../Constant";
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import SimpleBackdrop from "./SimpleBackdrop"
import SimpleSnackbar from "./SimpleSnackbar"
import Bar from "./Bar"
import AddNewFilterCondition from "./AddNewFilterCondition"
import { useAuthState } from "@bamboobox/b2logincheck"
import { useParams, useLocation } from 'react-router'
import FilterCriteriaComp from './FilterCriteria'
import ICPconditions from './ICPconditions';
import ClearIcon from '@mui/icons-material/Clear';
import {withStyles } from "@material-ui/core";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Alert from '@mui/material/Alert';
import "./../toast.css"
import { FilterTabs } from './Filters/FilterTabs';


//changes are going here
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import AddNewFilter from './AddNewFilter'
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
//changes are above
// const CustomTab = withStyles({
//     root: {
//       textTransform: "none",
//       minWidth: "13.8vw",
//       width: "13.8vw",
//       lineHeight: "15.83px"
//     },

//   })(Tab);

  const CustomTab = withStyles({
    root: {
      backgroundColor: '#A0A0A0',
    },
    selected: {
      backgroundColor: '#000000',
    },
  })(Tab);

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing(1),
    },
    colorPrimary: {
        color: "#1035e8"
    },
    root: {
        padding: "1%"
    },
    head: {
        padding: "1px",
        backgroundColor: "#ecf7fe",
        borderRight: "1px"
    },
    tabtext:{
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '13px',
        lineHeight: '16px',
        /* identical to box height */
        letterSpacing: '0.06em',
        textTransform: 'uppercase',
        color: '#A0A0A0',
    },
    Deshead:{

        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '13px',
        lineHeight: '16px',
        letterSpacing: '0.06em',
        textTransform: 'uppercase',
        color: '#595959',

    },
    Destext:{
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '14px',
        color: '#595959',
    }
}))

var currentICPFilterlen
var filterAttribute = []
var copyOfIcpConditionAttribute = []

const ShowFilter = (props) => {
    console.log("icpname is",props.icpName);
    toast.configure({
        autoClose: 6000,
        draggable: true,
        hideProgressBar: false,
        position: toast.POSITION.BOTTOM_LEFT,
        icon: false,
      });
    
    let [data, setData] = useState(undefined)
    const [valuesData,setValuesData]=useState(undefined);
    const [configMapper,setConfigMapper]=useState(undefined);
    const [icpname,setIcpName]=useState(props.icpName);
    const [render,setRender]=useState(false);
    console.log("icpname is usestate",props.icpName);
    const userData = useAuthState()
    const [open, setOpen] = React.useState({
        status: false,
        filtername: ""
    })
    const [backdropState, setBackdropState] = useState(false)
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        severity: "",
        message: ""
    })
     
   // let icpname = new URLSearchParams(useLocation().search).get('icp'); 
//    let icpname = props.icpName; 
   let description = props.description;

  const [value, setValue] = React.useState(0);
  const [filterName , setFilterName] = useState();
  const [filterId, setFilterId] = useState();

  const changedTab = (event, newValue) => {
    setValue(newValue);
  };
  const filterData = (filterInfo)=>{
      console.log("info is",filterInfo.filterName)
      setFilterName(filterInfo.filterName);
      setFilterId(filterInfo.filterId);
  }

    const onDelete = (filterId) => {
        setBackdropState(true)
        try {
            axios.delete(REACT_APP_API_URL + '/filter/' + userData.user.tenantId + '/' + icpname + '/delete/' + filterId, { withCredentials: true }).then((response) => {
                axios.get(REACT_APP_API_URL + '/scoredefinition/' + userData.user.tenantId + '/' + icpname, { withCredentials: true }).then((result) => {
                    result.data.filterCondition.sort((a, b) => {
                        return b.filterRank - a.filterRank
                    })
                    setData(result.data)
                    setBackdropState(false)
                    // setSnackbarState({
                    //     open: true,
                    //     severity: "success",
                    //     message: "ICP filter got deleted successfully"
                    // })
                    toast.success(<Alert severity="success">ICP filter got deleted successfully</Alert>,{
                        closeOnClick: true,
                        autoClose: true,
                        closeButton: true,
                        newestOnTop: true
                      });
                })
            })

        } catch (error) {
            console.log(error)
        }
    }





    const icpfilter = async () => {
        if (data === undefined) {
            try {
                copyOfIcpConditionAttribute = []
                filterAttribute = []
                await axios.get(REACT_APP_API_URL + '/scoredefinition/' + userData.user.tenantId + '/' + icpname, { withCredentials: true }).then((result) => {
                    result.data.filterCondition.sort((a, b) => {
                        return b.filterRank - a.filterRank
                    })
                    //current filter length
                    currentICPFilterlen = result.data.filterCondition.length
                    console.log(currentICPFilterlen)
                    copyOfIcpConditionAttribute.push(...result.data.filterCondition[currentICPFilterlen - 1].icpCondition)
                    console.log(copyOfIcpConditionAttribute)
                    //deleting record id and parameters of the icpcondition
                    for (let k = 0; k < copyOfIcpConditionAttribute.length; k++) {
                        delete copyOfIcpConditionAttribute[k].recordId
                        copyOfIcpConditionAttribute[k].parameters.splice(0, copyOfIcpConditionAttribute[k].parameters.length)

                    }

                    axios.get(REACT_APP_API_URL3 + '/' + userData.user.tenantId + '/describe.json', { withCredentials: true }).then((result) => {

                        if (result.status === 200) {
                            var json = JSON.parse(JSON.stringify(result.data))

                            //checking the size of json
                            //console.log(json.length)

                            for (let i = 0; i < json.length; i++) {

                                var numericalRelationValue = [
                                    {
                                        "value": ">"
                                    },
                                    {
                                        "value": "<"
                                    }
                                ]
                                var hierarchicalRelationValue = [
                                    {
                                        "value": "="
                                    }
                                ]

                                var obj = {
                                    Name: "",
                                    Type: "",
                                    Relation: []
                                }
                                if (json[i].ICP === 'yes') {
                                    //Here assign the object value into new object.

                                    obj.Name = json[i].name
                                    obj.Type = json[i].type



                                    if (json[i].type === 'hierarchical' || json[i].type === 'categorical') {
                                        console.log(json[i].type)
                                        obj.Relation = hierarchicalRelationValue
                                    }
                                    else {
                                        console.log("Else executed")
                                        console.log(json[i].type)
                                        obj.Relation = numericalRelationValue
                                    }

                                    filterAttribute.push(obj)

                                }
                            }

                            console.log(filterAttribute)
                        }
                        for (let i = 0; i < copyOfIcpConditionAttribute.length; i++) {
                            filterAttribute.splice(filterAttribute.findIndex(function (j) {
                                return j.Name === copyOfIcpConditionAttribute[i].name
                            }), 1)
                        }
                        console.log(filterAttribute)
                        console.log(copyOfIcpConditionAttribute)

                    });

                    setData(result.data)
                })

            } catch (err) {

                console.error(err.message)
            }
        }

    }

    const getValues=async()=>{
        await axios.get(REACT_APP_API_URL3 + '/' + userData.user.tenantId + '/describe.json', { withCredentials: true }).then((result) => {

            if (result.status === 200) {
                // var json = JSON.parse(JSON.stringify(result.data))
                console.log("resultData from api",result.data);
                setValuesData(result.data)

            }})
    }

    const getConfigMapper=async () => {
        try{
            fetch(`${TENANTCONFIG_API}/tenant/field/mapper/get/${userData.user.tenantId}/score-config-ui`,{ headers: { "Content-Type": "application/json" },credentials: "include"})
            .then((result) => {
                return result.json()
            })
            .then((data) =>{
                console.log("mapper Data",data)
                setConfigMapper(data);
            })
        }catch (error) {
            console.log("error",error )
        }  
    }

    useEffect(() => {
        const data=async()=>{
          await getConfigMapper();
          await getValues()
        }
        data()
      }, [])

    useEffect(() => {
        console.log("rerendered showfilter")
        setData(undefined);
        const data=async()=>{
            
            await getData();
        }
        data();
    }, [props.icpName,render])

    
    

    const setCollapse = (filterName) => {
        if (open.status === true) {
            setOpen(
                {
                    status: false,
                    filtername: ""
                }
            )
        }
        else {
            setOpen(
                {
                    status: true,
                    filtername: filterName
                }
            )
        }

    }

    const setSnackbarStateFalse = (snackbarStateFromBar) => {
        setSnackbarState(snackbarStateFromBar)
    }

    const onBackClick = () => {
        props.history.push('/')
    }

    const onEditClick = (filterid, filtername) => {
        props.history.push('/ICPconditions/' + icpname + '/' + filterid + '/' + filtername+'?icp='+icpname)
    }

    var noICP = "filterEdit"
    var num3 = 3

    const getData = async () => {
        try {
            await axios.get(REACT_APP_API_URL + '/scoredefinition/' + userData.user.tenantId + '/' + props.icpName, { withCredentials: true }).then((result) => {
                result.data.filterCondition.sort((a, b) => {
                    return b.filterRank - a.filterRank
                })
                setData(result.data)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const isEmptyCheck=(icpFilter)=>{
        let i=0
        console.log("isEmptyCheck372",icpFilter)
        for(i=0;i<icpFilter.length;i++){
            // debugger;
            let filter = icpFilter[i]
            console.log("filter376",filter)
        if(filter.value===null || filter.relation===null || filter.name===null || filter.value===undefined || filter.relation===undefined || filter.name===undefined  )
        {
            console.log("row data for each row",filter.value)
           
              return false
            }
    }
    return true
}

    const onAddNewCondition = (filterName, icpFilterCon, filterId, name, type, value, relation) => {

        var newFilterCondition = {
            "filterName": filterName,
            "filterRank": "",
            "icpFilter": icpFilterCon,
            "icpCondition": copyOfIcpConditionAttribute
        }

        newFilterCondition.icpFilter.push({ "name": name, "type": type, "value": value, "relation": relation })

        console.log("newfilterCondition396",newFilterCondition.icpFilter)
        if(!isEmptyCheck(newFilterCondition.icpFilter))
          {
            toast.error(<Alert severity="warning">Name , relation or value can't be empty</Alert>,{
                closeOnClick: true,
                autoClose: true,
                closeButton: true,
                newestOnTop: true
              });
          return 
            }

        try {
            axios.post(REACT_APP_API_URL + '/filter/' + userData.user.tenantId + '/' + icpname + '/' + filterId, newFilterCondition, { withCredentials: true }).then((response) => {
                console.log(response.data)
                if (response.data === "Filter with the same name can't be defined again") {
                    axios.get(REACT_APP_API_URL + '/scoredefinition/' + userData.user.tenantId + '/' + icpname, { withCredentials: true }).then((result) => {
                        result.data.filterCondition.sort((a, b) => {
                            return b.filterRank - a.filterRank
                        })
                        setData(result.data)
                        // setSnackbarState({
                        //     open: true,
                        //     severity: "success",
                        //     message: "Filter condition with same name can't be created"
                        // })
                        toast.error(<Alert severity="error">Filter condition with same name can't be created</Alert>,{
                            closeOnClick: true,
                            autoClose: true,
                            closeButton: true,
                            newestOnTop: true
                          });
                    })
                }
                else {
                    setBackdropState(true)
                    axios.get(REACT_APP_API_URL + '/scoredefinition/' + userData.user.tenantId + '/' + icpname, { withCredentials: true }).then((result) => {
                        result.data.filterCondition.sort((a, b) => {
                            return b.filterRank - a.filterRank
                        })
                        setData(result.data)
                        setBackdropState(false)
                        // setSnackbarState({
                        //     open: true,
                        //     severity: "success",
                        //     message: "Successfully created new filter condition"
                        // })
                        toast.success(<Alert severity="success">Successfully created new filter condition</Alert>,{
                            closeOnClick: true,
                            autoClose: true,
                            closeButton: true,
                            newestOnTop: true
                          });
                    })
                }

            })
        } catch (error) {
            console.log(error)
        }
    }

    const setBackdropTrue = () => {
        setBackdropState(true)
    }
    const setBackdropFalse = () => {
        setBackdropState(false)
    }

    const snackbarCallbackToShowFilter = (snackbarStateFromBar) => {
        console.log(snackbarStateFromBar)
        console.log("ShowFilter snackbar")
        setSnackbarState(snackbarStateFromBar)
    }

    const [style, setStyle] = useState({display: 'none'});
    const [index,setIndex]=useState('');

    const [openCondition, setOpenCondition] = React.useState(false);
    const classes = useStyles()
    data?.filterCondition.sort(function(a, b){return a.filterRank - b.filterRank})
    //let data1 = data.filterCondition?.sort(function(a,b){return a.filterRank - b.filterRank})
    //console.log("show data", data.filterCondition.sort(function(a, b){return a.filterRank - b.filterRank}));

    return (
        <div style={{ display:"flex", flexDirection :"column", width: "100%",maxHeight:'73vh'}}>
            <SimpleBackdrop backdropState={backdropState} />
            <SimpleSnackbar setSnackbarStateFalse={setSnackbarStateFalse} snackbarState={snackbarState} />
            <div style={{height: '73vh',overflow: "hidden",overflowY: "scroll"}}>
                    {(data === undefined || valuesData=== undefined || configMapper=== undefined)  ?
                                (<div style={{ position: "absolute", top: "40%", left: "50%", display: "flex", justifyContent: "center" }}>
                                    <div className={classes.root}><CircularProgress size="3rem" style={{color:'#21DA8C'}} /></div>
                                </div>) : 
                                (<React.Fragment>
                                    {/* <AddNewFilter icpname={icpname} label={icpname} previousICP={noICP} ICPlistData={data} content={num3} currentICPFilterlength={currentICPFilterlen} getData={getData} onBackClick={onBackClick} copyOfIcpConditionAttribute={copyOfIcpConditionAttribute} setBackdropTrue={setBackdropTrue} setBackdropFalse={setBackdropFalse} snackbarCallbackToShowFilter={snackbarCallbackToShowFilter} /> */}
                                    <FilterTabs setRender={setRender} render={render} newAttributes={configMapper} valuesData={valuesData} filterCondition={data?.filterCondition} icpName={props.icpName}/>
                                    
                                    {/* <AddNewFilterCondition  onAddNewCondition={onAddNewCondition}   filterAttribute={filterAttribute} /> */}
                                {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }} > */}
                                    {/* <Tabs  value={value} onChange={changedTab} aria-label="basic tabs example"
                                    className="tabs"
                                    style={{  
                                    marginLeft:'3%',       
                                    borderBottom: '1px solid #D3D3D3', color: "#A0A0A0" }}
                                    indicatorColor="primary"
                                    TabIndicatorProps={{
                                      style: {
                                        backgroundColor: "#21DA8C",
                                        height: "6px",
                                        borderRadius: "3px",
                                      }
                                    }}
                                    >
                                    {data.filterCondition.map((item,i) => (

                                        //label={<><Icon fontSize="inherit" /> Some other label</>}
                                        //icon={<ClearIcon style={{fontSize: "inherit"}} />} iconPosition="end" 
                                        // item.filterName=='default'?<Tab label={item.filterName} {...a11yProps(i)} />:
                                        item.filterName!=='default'?<CustomTab  label={<><div style={{display:'flex',alignItems:'center'}}
                                        onMouseEnter={e => {
                                            // setStyle({display: 'block'});
                                            console.log("index is",i)
                                            setIndex(i)
                                        }}
                                        onMouseLeave={e => {
                                            setStyle({display: 'none'})
                                            setIndex(' ');
                                        }}
                        
                                        >{item.filterName}<ClearIcon  onClick={() => onDelete(item.filterId)} style={index===i?{display: 'block',paddingLeft:'2px'}:style} /></div></>} {...a11yProps(i)} />:<CustomTab label={item.filterName} {...a11yProps(i)} /> ))}
                                        
                                    </Tabs> */}
                                    {/* <Tabs  value={value} onChange={changedTab} aria-label="basic tabs example"
                                    className="tabs"
                                    style={{  
                                    marginLeft:'3%',       
                                    borderBottom: '1px solid #D3D3D3', color: "#A0A0A0" }}
                                    indicatorColor="primary"
                                    TabIndicatorProps={{
                                      style: {
                                        backgroundColor: "#21DA8C",
                                        height: "6px",
                                        borderRadius: "3px",
                                      }
                                    }}
                                    >
                                        {
                                            data.filterCondition.map((condition,index)=>{
                                                <Tab label={condition.filterName} {...a11yProps(index)}/>
                                            })
                                        }
                                    </Tabs> */}
                                    
                                {/* </Box> */}
                                
                                    
                                    {/* {data?.filterCondition[value]?.filterName!=='default' &&<FilterCriteriaComp icpName = {icpname}  filterProps ={data.filterCondition[value]} setOpenCondition={setOpenCondition}/>} */}
                                                {/* {openCondition&& 
                                            data?.filterCondition.map((item, i)=>{
                                                if(i === value &&item?.filterName!=='default'){
                                            return <AddNewFilterCondition filterName={item?.filterName} onAddNewCondition={onAddNewCondition} filterId={item.filterId} icpFilter={item.icpFilter} filterAttribute={filterAttribute} setOpenCondition={setOpenCondition}/>
                                                }
                                            })} */}
                                    {/* <ICPconditions icpName = {icpname}  filterProps ={data?.filterCondition[value]}/> */}
                                    {/* <TabPanel value={value} index={value}>
                                        {data.filterCondition.map((item, i)=>{
                                            if(i === value ){
                                            return item.icpFilter.map((elem)=><h1>{elem?.value}</h1>)
                                        }})}
                                    </TabPanel> */}
                                 </React.Fragment>)}
            </div>
        </div >
    )
}

export default ShowFilter