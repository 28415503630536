// export const REACT_APP_API_URL = 'http://b2scoringapi.bamboobox.in';
// export const REACT_APP_API_URL2 = 'http://b2engagementscoringapi.bamboobox.in';
// export const REACT_APP_API_URL3 = 'http://b2unifiedprofileapi.bamboobox.in';
// export const LOGIN_URL = 'http://login.bamboobox.in'
// export const AUTH_URL = 'http://loginapi.bamboobox.in/authorize/cookie'
// export const TENANTCONFIG_API='http://b2tenantconfigapi.bamboobox.in'

export const REACT_APP_API_URL = window._env_.REACT_APP_API_URL;
export const REACT_APP_API_URL2 = window._env_.REACT_APP_API_URL2;
export const REACT_APP_API_URL3 = window._env_.REACT_APP_API_URL3;
export const LOGIN_URL = window._env_.LOGIN_URL;
export const AUTH_URL = window._env_.AUTH_URL;
export const TENANTCONFIG_API = window._env_.TENANTCONFIG_API;
export const B2_INTENT_SETTING= window._env_.B2_INTENT_SETTING;
export const B2_INTENT_BACKEND= window._env_.B2_INTENT_BACKEND;


// 9ac08989-b7f1-59d8-a29e-2b938c1a2c3
