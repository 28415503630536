import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import SimpleTextField from "./SimpleTextField";
import { useAuthState } from "@bamboobox/b2logincheck";
import axios from "axios";
import { REACT_APP_API_URL2 } from "./../Constant";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Alert from '@mui/material/Alert';
import "./../toast.css"
toast.configure({
  autoClose: 6000,
  draggable: true,
  hideProgressBar: false,
  position: toast.POSITION.BOTTOM_LEFT,
  icon: false,
});
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    border: "none"
  },
  contained: {
    backgroundColor: "primary"
  },
  TouchRipple: {
    backGroundColor: "#82B440"
  }
});

const thresholdScoreLabel = "Threshold Score"

const ThresholdScoreTable = (props) => {
  const userData = useAuthState();
  const classes = useStyles();
  const [rows, setRows] = React.useState()
  const [rowId, setRowId] = useState(0)
  const [initialData, setInitialData] = useState() //To Check if any value has been changed
  const [buttonDisable, setButtonDisable] = useState(true) //Conditionally enable and disable button

  const updateRows = async () => {
    try {
      await axios.get(REACT_APP_API_URL2 + '/threshold/' + userData.user.tenantId + '/' + props.icpname, { withCredentials: true }).then((result) => {
        setRows(result.data)
        setInitialData(result?.data?.individualThreshold?.individualEngagementThresholdScore)

      });
    } catch (error) {
      console.log(error)
    }

  }

  useEffect(() => {
    updateRows();
  },[])

  useEffect(() => {
    updateRows();
  }, [props.icpname])

  const thresholdValueFunc = (buyingGroupValue, type, rowId) => {
    const rowsData = { ...rows }
    rowsData.individualThreshold.individualEngagementThresholdScore = buyingGroupValue;
    //If any value changed, then enable update button
    if (rowsData.individualThreshold?.individualEngagementThresholdScore === initialData) {
      setButtonDisable(true)
    }
    else {
      setButtonDisable(false)
    }
    setRows({
      ...rowsData,
      individualThreshold: { ...rowsData.individualThreshold }
    });
  };

  const onUpdateThresholdScore = () => {
    //postcall for saving in the backend
    props.setBackdropStateFromChildComponent(true)
    let score = { "value": "" }
    score.value = rows?.individualThreshold?.individualEngagementThresholdScore;


    axios.post(REACT_APP_API_URL2 + '/threshold/' + userData.user.tenantId + '/' + props.icpname, score, { withCredentials: true }).then((result) => {
      props.setBackdropStateFromChildComponent(false)
      // props.snackbarCallbackToTab({
      //   open: true,
      //   severity: "success",
      //   message: "Successfully updated"
      // })
      toast.success(<Alert severity="success">Successfully updated</Alert>,{
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true
      });
    });
  }

  return (
    <React.Fragment>
      { rows === undefined ?
        <div style={{ display: 'flex', justifyContent: 'center', position: "absolute", top: "40%", left: "50%",}}><CircularProgress size="5rem" style={{color:'#21DA8C'}} /></div> :
        <React.Fragment>
          <TableContainer  >
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell classes={{ root: classes.root }} component="th" scope="row">
                    Individual Engagement Score Threshold
                  </TableCell>
                  <TableCell classes={{ root: classes.root }} align="left"><SimpleTextField rowId={rowId} label={thresholdScoreLabel} inputValue={rows?.individualThreshold?.individualEngagementThresholdScore} valueFunc={thresholdValueFunc} /></TableCell>

                </TableRow>
              </TableBody>
            </Table>
            <Button
              variant="contained"
              classes={{ contained: classes.contained }}
              disabled={buttonDisable}
              size="middle"
              style={{ marginTop: "4%", marginBottom: "5%", marginLeft: "1%", textTransform: "none" }}
              className={classes.button}
              onClick={() => onUpdateThresholdScore()}
            >
              Update
          </Button>
          </TableContainer>
        </React.Fragment>}
    </React.Fragment>
  );
}


export default ThresholdScoreTable
