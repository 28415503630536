import React, { useEffect, useState } from "react";
import { Typography } from '@mui/material';
import { CustomAutoComplete } from './CustomAutoComplete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
// const props={}
const options=[
    {
        label:"Very High",
        value:4
    },
    {
        label:"High",
        value:3
    },
    {
        label:"Medium",
        value:2
    },
    {
        label:"Low",
        value:1
    },
    {
        label:"Very Low",
        value:0
    }
]
// props.newAttributes={
//     "city": {
//         "icp": "yes",
//         "type": "STRING",
//         "label": "City",
//         "order": 28,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "email": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Official Email ID",
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "fbURL": {
//         "icp": "yes",
//         "type": "STRING",
//         "label": "Category",
//         "order": 30,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "phone": {
//         "icp": "",
//         "type": "NUMBER",
//         "label": "Phone",
//         "order": 22.9,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "state": {
//         "icp": "yes",
//         "type": "STRING",
//         "label": "State",
//         "order": 27,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "title": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Title",
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "ISExec": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Oracle CRM Number",
//         "order": 39,
//         "custom": true,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "labels": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Labels",
//         "order": 2,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": true,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "mobile": {
//         "icp": "",
//         "type": "STRING",
//         "label": "CIN Number",
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "b2stage": {
//         "icp": "",
//         "type": "STRING",
//         "label": "B2 Stage",
//         "order": 43,
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": true,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "comment": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Comments",
//         "order": 2.5,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": true,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "country": {
//         "icp": "yes",
//         "type": "STRING",
//         "label": "Country",
//         "order": 26,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "crmStage": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Contact Stage",
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "industry": {
//         "icp": "yes",
//         "type": "STRING",
//         "label": "Vertical Segment",
//         "order": 15,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "lastName": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Last Name",
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "accountId": {
//         "icp": "",
//         "type": "DECIMAL",
//         "label": null,
//         "order": 0,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "eventDate": {
//         "icp": "",
//         "type": "NUMBER",
//         "label": "Event Date",
//         "custom": false,
//         "source": "",
//         "default": "",
//         "subType": "",
//         "visible": true,
//         "metadataname": "",
//         "columnVisibility": true,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "firstName": {
//         "icp": "",
//         "type": "STRING",
//         "label": "First Name",
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "geography": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Geography",
//         "order": 14,
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "groupName": {
//         "icp": "yes",
//         "type": "STRING",
//         "label": "DMU",
//         "order": 24,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "icpScores": {
//         "icp": "",
//         "type": "DECIMAL",
//         "label": "ICP Score",
//         "order": 5,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": true,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "buyerGroup": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Buyer Group Size",
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "department": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Department",
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "eventCount": {
//         "icp": "",
//         "type": "NUMBER",
//         "label": "Event Count",
//         "custom": false,
//         "source": "",
//         "default": "",
//         "subType": "",
//         "visible": true,
//         "metadataname": "",
//         "columnVisibility": true,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "twitterURL": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Twitter URL",
//         "order": 31,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "accUpdatdAt": {
//         "icp": "",
//         "type": "TIMESTAMP",
//         "label": "Account Updated Date",
//         "order": 36,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "accountDesc": {
//         "icp": "yes",
//         "type": "STRING",
//         "label": "Customer Segment",
//         "order": 21,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "accountName": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Customer Name",
//         "order": 1,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": true,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "accountType": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Account Type",
//         "order": 2,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": true,
//         "dropdown": "",
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "companyName": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Account Name",
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "companyType": {
//         "icp": "yes",
//         "type": "STRING",
//         "label": "Customer Type",
//         "order": 35,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "contactName": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Contact Name",
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "contactRole": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Contact Role",
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "crmSubStage": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Contact Sub-Stage",
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "lastEngDate": {
//         "icp": "",
//         "type": "DECIMAL",
//         "label": "Last Engagement Date",
//         "order": 9,
//         "custom": false,
//         "source": "",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "metadataname": "",
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "linkedInURL": {
//         "icp": "",
//         "type": "STRING",
//         "label": "LinkedIn URL",
//         "order": 29,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "noOfContact": {
//         "icp": "",
//         "type": "DECIMAL",
//         "label": "Number of Contacts",
//         "custom": false,
//         "source": "",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "numOfStores": {
//         "icp": "",
//         "type": "NUMBER",
//         "label": "Customer number",
//         "order": 41,
//         "custom": true,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "personacode": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Persona Code",
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "phoneNumber": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Phone",
//         "order": 22,
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "programName": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Campaign Name",
//         "custom": false,
//         "source": "",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "metadataname": "",
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "subIndustry": {
//         "icp": "yes",
//         "type": "DECIMAL",
//         "label": "Service segment",
//         "order": 16,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "accCreatedAt": {
//         "icp": "",
//         "type": "TIMESTAMP",
//         "label": "Account Created Date",
//         "order": 42,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "accountOwner": {
//         "icp": "",
//         "type": "NUMBER",
//         "label": "customer Owner",
//         "order": 12,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "accountScore": {
//         "icp": "",
//         "type": "DECIMAL",
//         "label": "Account Score",
//         "order": 4,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": true,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "activityType": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Activity Type",
//         "custom": false,
//         "source": "",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "metadataname": "",
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "contactOwner": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Contact Owner",
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "firstEngDate": {
//         "icp": "",
//         "type": "STRING",
//         "label": "First Engagement Date",
//         "custom": false,
//         "source": "",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "metadataname": "",
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "groupWebsite": {
//         "icp": "yes",
//         "type": "STRING",
//         "label": "Circle",
//         "order": 33,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "personalevel": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Persona Level",
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "accountRegion": {
//         "icp": "yes",
//         "type": "STRING",
//         "label": "Region",
//         "order": 13,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "annualRevenue": {
//         "icp": "yes",
//         "type": "DECIMAL",
//         "label": "Annual Revenue($)",
//         "order": 14,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "contactRegion": {
//         "icp": "yes",
//         "type": "STRING",
//         "label": "Region",
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "contactSource": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Contact Source",
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "parentWebsite": {
//         "icp": "",
//         "type": "STRING",
//         "label": "DMU Identification",
//         "order": 32,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "accountWebsite": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Account Website",
//         "order": 11,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "b2AccountStage": {
//         "icp": "",
//         "type": "STRING",
//         "label": "B2 Account Stage",
//         "order": 3,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": true,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "businessFormat": {
//         "icp": "yes",
//         "type": "STRING",
//         "label": "Customer Record Type",
//         "order": 40,
//         "custom": true,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "conLinkedInURL": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Contact LinkedIn URL",
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "fieldSalesExec": {
//         "icp": "",
//         "type": "STRING",
//         "label": "PAN No.",
//         "order": 38,
//         "custom": true,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "numOfEmployees": {
//         "icp": "",
//         "type": "NUMBER",
//         "label": "Employee Count",
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "secondaryEmail": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Other Email ID",
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "b2_intent_level": {
//         "icp": "",
//         "case": "TITLE",
//         "type": "String",
//         "label": "Intent Level",
//         "order": 7,
//         "custom": false,
//         "source": "INTENT",
//         "default": "",
//         "subType": "",
//         "visible": true,
//         "metadataname": "",
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "crmAccountStage": {
//         "icp": "yes",
//         "type": "hierarchical",
//         "label": "Status",
//         "order": 20,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "position": 1,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "engagementDepth": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Engagement Depth",
//         "order": 17,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "engagementScore": {
//         "icp": "",
//         "type": "DECIMAL",
//         "label": "Engagement Score",
//         "order": 6,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": true,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "engagementWidth": {
//         "icp": "",
//         "type": "DECIMAL",
//         "label": "Engagement Width",
//         "order": 16,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "secondaryNumber": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Other Number",
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "companyHierarchy": {
//         "icp": "yes",
//         "type": "STRING",
//         "label": "Account Category",
//         "order": 23,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "lastActivityDate": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Last Activity Date",
//         "custom": false,
//         "source": "",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "metadataname": "",
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "last_intent_date": {
//         "icp": "",
//         "type": "TIMESTAMP",
//         "label": "Last Intent Date",
//         "order": 10,
//         "custom": false,
//         "source": "INTENT",
//         "default": "",
//         "subType": "",
//         "visible": true,
//         "metadataname": "",
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "topTargetAccount": {
//         "icp": "",
//         "type": "BOOLEAN",
//         "label": "",
//         "order": 19,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "topics_data_aggr": {
//         "icp": "",
//         "type": "LIST",
//         "label": "Intent Topics",
//         "order": 8,
//         "custom": false,
//         "source": "INTENT",
//         "default": "",
//         "subType": "",
//         "visible": true,
//         "metadataname": "",
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "Stage Change Date": {
//         "icp": "",
//         "type": "TIMESTAMP",
//         "label": "Stage Change Date",
//         "order": 37,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "accountStageTrend": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Account Stage Trend",
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "country_data_aggr": {
//         "type": "STRING",
//         "label": "Intent Country",
//         "custom": false,
//         "source": "",
//         "default": "",
//         "subType": "",
//         "visible": true,
//         "metadataname": "",
//         "columnVisibility": true,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "firstActivityDate": {
//         "icp": "",
//         "type": "STRING",
//         "label": "First Activity Date",
//         "custom": false,
//         "source": "",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "metadataname": "",
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "parentAccountName": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Parent DMU",
//         "order": 25,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "technograhicDetails": {
//         "icp": "yes",
//         "type": "STRING",
//         "label": "Business",
//         "order": 34,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "opportunityCreatedAt": {
//         "icp": "",
//         "type": "TIMESTAMP",
//         "label": "Opportunity Created At",
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "opportunityUpdatedAt": {
//         "icp": "",
//         "type": "TIMESTAMP",
//         "label": "Opportunity Updated At",
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "accountClassification": {
//         "icp": "yes",
//         "type": "DECIMAL",
//         "label": "Account LOB",
//         "order": 18,
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "contactEngagementScore": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Individual Engagement Score",
//         "custom": false,
//         "source": "CONTACT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     },
//     "departmentPersonalevel": {
//         "icp": "",
//         "type": "STRING",
//         "label": "Persona Department Level",
//         "custom": false,
//         "source": "ACCOUNT",
//         "default": "",
//         "subType": "",
//         "visible": false,
//         "attributeSelector": false,
//         "attributeValueLink": ""
//     }
// }
// const attributes=Object.keys(props.newAttributes).map((key)=>{
//     if(props.newAttributes[key].icp.toLowerCase()==='yes'){
//         return {
//             "label":props.newAttributes[key].label,
//             "value":key
//         }
//     }
// })

export const NewAttriButeCondition=(props)=>{
    console.log("props for new conditions",props);
    const getAttributes=(attributes)=>{
        const existingOptions=props.filtersData.map((obj)=>obj.name);
        const newOptions=attributes.filter((obj)=>!existingOptions.includes(obj.name));
        // newOptions.map((obj)=>{
        //     const newObj=
        // })
        console.log("existing",existingOptions,newOptions)
        return newOptions;
    }
    const [attributes,setAttributes]=useState(getAttributes(props.valuesData));
    const [value,setValue]=useState();
    const [weightage,setWeightage]=useState();

    const filterOptions=()=>{
        const existingOptions=props.filtersData.map((obj)=>obj.name);
        const newOptions=attributes.filter((obj)=>!existingOptions.includes(obj.name));
        // newOptions.map((obj)=>{
        //     const newObj=
        // })
        console.log("existing",existingOptions,newOptions)
        return newOptions;
    }

    const HandleSave=()=>{
        const newAttributeTemplate={
            "type": "",
            "name": "",
            "subtype": "",
            "comments": "",
            "hierarchy": "",
            "attributeWeightage": "",
            "position": 1,
            "parameters": [
                {
                    "rowid": 0,
                    "valueMin": 0,
                    "valueMax": 0,
                    "importance": 0,
                    "value": "default",
                    "comments": null
                }
            ]
        }
        newAttributeTemplate.type=value.type;
        newAttributeTemplate.name=value.name;
        newAttributeTemplate.subtype=value?.subtype || "";
        newAttributeTemplate.comments=value?.comments || null;
        newAttributeTemplate.hierarchy=value?.hierarchy || "";
        newAttributeTemplate.attributeWeightage=weightage.toString();
        newAttributeTemplate.position=value?.position || 1;
        newAttributeTemplate.parameters.importance=weightage;
        props.addNewCondition(newAttributeTemplate);
    }

    const handleValueChange=(value)=>{
        console.log("value changesd",value);
        setValue(value);
    }

    const handleWeightageChange=(weightage)=>{
        // filterOptions();
        console.log("weightage changesd",weightage);
        setWeightage(weightage.value);
    }

    
    return(
        <div style={{width:"800px"}}>
                <div style={{display:"flex",alignItems:"center",marginLeft:"10px"}}>
                <CustomAutoComplete styles={{width:250}} options={attributes} variant={"outlined"} updateSelected={handleValueChange}   disabled={false} readOnly={false}/>
                    <Typography variant='body1' sx={{margin:"10px",fontFamily:"Nunito Sans"}}>is weighed</Typography>
                    <CustomAutoComplete styles={{width:250}} options={options} variant={"outlined"} updateSelected={handleWeightageChange}  disabled={false} readOnly={false}/>
                    <div style={{display:"flex",marginLeft:"20px"}}>
                    <IconButton color="blueGrey" onClick={(e)=>HandleSave()}>
                        <CheckIcon/>
                    </IconButton>
                    <IconButton color="blueGrey" onClick={(e)=>props.setIsNewAttributeClicked(false)}>
                        <CloseIcon/>
                    </IconButton>
                    </div>
                </div>
                <hr style={{color:"#E0E0E0",marginTop:'30px',marginBottom:"20px",width:"100vw"}}></hr>    
            </div>
    )
}