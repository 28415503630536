import { Typography } from '@mui/material';
import { CustomAutoComplete } from './CustomAutoComplete';
import {CustomAutoCompleteMultiple} from './Autocomplte/CustomAutoCompleteMultiple';
import { DeleteButton } from './DeleteButton';
import { useState } from 'react';

export const NewFilterValues=({obj,options,valueOptions,selectedValue,selectedWeightage,updateObj,handleDelete})=>{
    const [dataObj,setDataObj]=useState(obj);

    const handleValueChange=(input)=>{
        let newValue="";
        input.map(obj=>{
            if(newValue===""){
                newValue+=obj.value;
            }else{
                newValue=newValue+","+obj.value;
            }
        })
        setDataObj((latestobj)=>{
            const newObj={...latestobj}
            newObj.value=newValue
            updateObj(newObj);
            return newObj;
        })
    }

    const handleWeightageChange=(input)=>{
        setDataObj((latestobj)=>{
            const newObj={...latestobj}
            newObj.importance=input?.value;
            updateObj(newObj);
            return newObj;
        })
    }

    const handleDeleteRow=()=>{
        handleDelete(dataObj.rowid);
    }
    return(
        <div style={{display:"flex",alignItems:"baseline",marginBottom:"10px"}}>
            <div style={{display:"flex",flexDirection:"column",marginLeft:"10px"}}>
                {/* <Typography variant='overline' style={{fontFamily:"Nunito Sans"}}>Value</Typography> */}
                <CustomAutoCompleteMultiple styles={{width:250}}  variant={"outlined"} disabled={false} options={valueOptions} selected={selectedValue} updateSelected={handleValueChange} multiple={true}/>
            </div>
            <div style={{display:'flex',alignItems:"center"}}>
            <div style={{display:"flex",flexDirection:"column",marginLeft:"10px"}}>
                {/* <Typography variant='overline' style={{fontFamily:"Nunito Sans"}}>Weightage</Typography> */}
                <CustomAutoComplete styles={{width:250}} variant={"outlined"} disabled={false} options={options} selected={selectedWeightage} updateSelected={handleWeightageChange} multiple={false}/>
            </div>
            <div ><DeleteButton handleDelete={handleDeleteRow}/></div>
            </div>
        </div>
    )
}