import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import _uniqueId from 'lodash/uniqueId';
import axios from "axios";
import { useAuthState } from "@bamboobox/b2logincheck"
import { REACT_APP_API_URL3 } from "./../Constant";

const useStyles = makeStyles((theme) => ({
    root: {
        
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));


const MultiSelectComboBox = (props) => {
    console.log(props);
    const classes = useStyles();
    const [MultiSelectComboBoxValues, setMultiSelectComboBoxValues] = React.useState([])
    const [multiComboBox, setMultiComboBox] = React.useState([]);
    const [id] = React.useState(_uniqueId('multiCombo-box-demo-'))
    const userData = useAuthState()
    const [parentchildRelation, setParentchildRelation] = React.useState(false)

    const setDefaultValue = () => {
        if (props.currentValue !== '') {
            let arr = props.currentValue.split(',')
            console.log(arr)
            let def = []
            arr.forEach(item => {
                let obj = { value: "" }
                obj.value = item
                def.push(obj)
            })
            console.log(def)
            return def
        } else {
            return []
        }
        // let arr = props.currentValue.split(',')
        // console.log(arr)
        // let def = []
        // arr.forEach(item => {
        //     let obj = { value: "" }
        //     obj.value = item
        //     def.push(obj)
        // })
        // console.log(def)
        // return def
    }
    const [defaultState, setDefaultState] = React.useState(setDefaultValue())

    // React.useEffect(() => {
    //     setDefaultValue()
    // }, [])

    const getAttributeData = async (placeholder) => {
        //get request for the categorical or hierarchichal data from Lohita's service
        console.log("This is running: "+"placeholder")
        await axios.get(REACT_APP_API_URL3 + '/' + userData.user.tenantId + '/describe.json', { withCredentials: true }).then((result) => {
            let categories = [];
            if (result.status == 200) {
                var json = JSON.parse(JSON.stringify(result.data));
                console.log(json);
                for (let i = 0; i < json.length; i++) {
                    if (json[i].name == placeholder && json[i].ICP == "yes") {
                        if (json[i].position == 1 || json[i].name === 'country') {
                            console.log("inposition 1")
                            setParentchildRelation(false)
                            categories = json[i]?.values?.slice() || [];
                        }
                        else {
                            setParentchildRelation(true)
                            console.log("inposition 2")
                            let res =json[i].values.map(obj => {
                                let parent = obj.parentvalue
                                return obj.childvalues.map(chi=>{
                                    return {
                                        parentValue:parent,
                                        value:chi.childvalue
                                    }
                                })
                            })
                            categories = [].concat.apply([], res);
                           

                            // json[i].values.forEach((val) => {
                            //     val.childvalues.forEach((valChild) => {
                            //         let parentChildObject = {
                            //             parentValue: '',
                            //             value: ''
                            //         }
                            //         parentChildObject.parentValue = val.parentvalue
                            //         parentChildObject.value = valChild.childvalue
                            //         categories.push(parentChildObject)
                            //     })
                            // })
                        }
                    }
                }
            }
            console.log('categories',categories);
            
            setMultiSelectComboBoxValues(categories)

        });

    }

    React.useEffect(() => {
        console.log(props.placeholder)
        getAttributeData(props.placeholder)
    }, [])

    const chosedValue = (arrayList) => {
        //extract value as an array from an array of objects
        let extractedValue = arrayList.map(item => item.value);
        console.log(extractedValue.tostring())
        let extractedValueString = extractedValue?.tostring()
        props.funcForUpdatingValue(extractedValueString)
        //Here props.someFunction() to send the data to the icpCondition page so that data can be send to the backend
    }

    return (
        <div className={classes.root}>
            {parentchildRelation ?
                <Autocomplete
                    multiple
                    id={id}
                    size="small"
                    style={props.style || {width:180}}
                    disableClearable
                    disabled={props.disable}
                    options={MultiSelectComboBoxValues}
                    groupBy={(option) => option.parentValue}
                    getOptionLabel={(option) => option.value}
                    defaultValue={defaultState}
                    onChange={(event, newValue) => {
                        console.log(newValue)
                        //arrayList.push(newValue);
                        setMultiComboBox(newValue)
                        chosedValue(newValue)
                    }}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                    )}
                /> :
                <Autocomplete
                    multiple
                    id={id}
                    size="small"
                    style={props.style || {width:180}}
                    disableClearable
                    disabled={props.disable}
                    options={MultiSelectComboBoxValues}
                    getOptionLabel={(option) => option.value}
                    defaultValue={defaultState}
                    onChange={(event, newValue) => {
                        console.log(newValue)
                        //arrayList.push(newValue);
                        setMultiComboBox(newValue)
                        chosedValue(newValue)
                    }}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                    )}
                />
            }



        </div>
    )
}

export default MultiSelectComboBox