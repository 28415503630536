import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from "axios";
import { REACT_APP_API_URL,TENANTCONFIG_API } from "./../Constant";
import { useParams } from 'react-router';
import ComboBoxImportance from "./ComboBoxImportance";
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import TextField from "@material-ui/core/TextField";
import Bar from "./Bar";
import MultiSelectComboBox from "./MultiSelectComboBox";
import { useAuthState, AuthProvider } from "@bamboobox/b2logincheck";
import { FormControl, Typography } from '@material-ui/core';
import { PinDropSharp } from '@material-ui/icons';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Alert from '@mui/material/Alert';
import MultiSelectStringComboBox from './MultiSelectStringComboBox';
import {

    useLocation
  } from 'react-router-dom';
import "./icpconditions.css";


var newValueMin;
var newValueMax;
var newImportance;
var newComments;
var newRealValue;
var importanceScore = new Number();
var currentComboBoxValue;
var k;
var keys;
var changedParameter;
var isDisabled = true;
var noICP = 'ICPcondition';
const testMultiSelectComboBoxValue = 'industry,Retail'


const importanceLevel = {
    "level": [
        {
            "levelName": "Very High",
            "levelRating": 4
        },
        {
            "levelName": "High",
            "levelRating": 3
        },
        {
            "levelName": "Medium",
            "levelRating": 2
        },
        {
            "levelName": "Low",
            "levelRating": 1
        },
        {
            "levelName": "Very Low",
            "levelRating": 0
        }
    ]
};

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing(1),
        width: 170,
        height: 35,
        textTransform: 'none',
        backgroundColor: "#21DA8C" , 
        borderRadius: "18px", 
        color:"white",
        textTransform:'uppercase'
    },
    containedPrimary: {
        color: "#FFFFFF",
        opacity: "90%"
    },
    colorPrimary: {
        color: "#1035e8"
    },
    root: {
        padding: "0.3%",
        borderBottom: 'none',
        
    },
    head: {
        padding: "10px",
        borderRight: "1px",
    },
    headtext:{
        padding :"2.5%",
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '13px',
        lineHeight: '16px',
        /* identical to box height */
        letterSpacing: '0.06em',
        textTransform: 'uppercase',
        color: '#595959'
    },

}));

const ICPconditions = (props) => {
    //let icpname = new URLSearchParams(useLocation().search).get('icp');
    console.log("icp-props", props);
    let icpname = props.icpName;
    // let { filterid } = useParams();
    // let { filtername } = useParams();
    let filterid = props?.filterProps?.filterId;
    let filtername = props?.filterProps?.filterName;
    const userData = useAuthState();
    console.log("props in icpconditions",props)
    console.log(filtername);
    const [data, setData] = useState(undefined);
    const [label, setLabel] = useState(icpname + ' - ' + filtername);
    const regexp = new RegExp(`^(0|[1-9]+[0-9]*)`);
    const [valueMaxError, setValueMaxError] = useState(false)
    const [valueMinError, setValueMinError] = useState(false)
    const [minErrorMsg, setMinErrorMsg] = useState(" ")
    const [maxErrorMsg, setMaxErrorMsg] = useState(" ")
    const [commentError, setCommentError] = useState(false)
    const [input, setInput] = useState("")
    const [configMapper,setConfigMapper] = useState({});

    const icpconditions = async () => {
        // if (data === undefined) {
            try {
                await axios.get(REACT_APP_API_URL + '/scoredefinition/' + userData.user.tenantId + '/' + icpname?.toLowerCase(), { withCredentials: true }).then((result) => {
                    setData(result.data)
                })
            } catch (err) {

                console.error(err.message);
            }
        // }
        // else {
        //     console.log("Data is not defined");
        // }
    }
    const getConfigMapper=async () => {
        try{
            fetch(`${TENANTCONFIG_API}/tenant/field/mapper/get/${userData.user.tenantId}/score-config-ui`,{ headers: { "Content-Type": "application/json" },credentials: "include"})
        .then((result) => {
             
              return result.json()
        })
        .then((data) =>{
            setConfigMapper(data);
        })
    }catch (error) {
        console.log("error",error )
    }  
        }
    useEffect(() => {
        icpconditions()
        getConfigMapper()
    }, [props.icpname,props.filterProps])

    const [inEditMode, setInEditMode] = useState({
        status: false,
        rowKey: null,
        recordId: null
    })

    const onEdit = (rowid, recordid) => {
        setValueMaxError(false)
        setMaxErrorMsg(" ")
        setValueMinError(false)
        setMinErrorMsg(" ")
        setInEditMode({
            status: true,
            rowKey: rowid,
            recordId: recordid
        })
    }




    const onDelete = (item, rowid, filterId) => {
       
        let val = item.parameters.findIndex(function (i) {
            return i.rowid === rowid;
        })
        if (val > 0){
            item.parameters.splice(val, 1);
        }
        
        try {
            axios.delete(REACT_APP_API_URL + '/definition/' + userData.user.tenantId + '/' + icpname?.toLowerCase() + '/' + filterId, { data: item, withCredentials: true}).then((response) => {

                axios.get(REACT_APP_API_URL + '/scoredefinition/' + userData.user.tenantId + '/' + icpname?.toLowerCase(), { withCredentials: true }).then((result) => {
                    setData(result.data);
                })
            });

        } catch (error) {
            console.log(error)
        }
       
        
    }

    const onCancel = () => {
        // reset the inEditMode state value
        setInEditMode({
            status: false,
            rowKey: null,
            recordId: null
        })
    }


    // const onBackClick = () => {
    //     props.history.push('/ShowFilter/' + (icpname||'').toLowerCase()+ '?icp='+(icpname||'').toLowerCase())
    // };
    const isEmptyCheck=(rowData)=>{
        console.log("inside",rowData,rowData.type,rowData.parameters);
        let i=0
        if(rowData.type!=='Numerical'){
            console.log("came inside");
            for(i=0;i<rowData.parameters.length;i++){
                // debugger;
                let row = rowData?.parameters[i]
                if(row.value.trim().length===0)
            {
                console.log("row data for each row",row.value)
               
                  return false
                }  
            
        }
        }
        
    return true
}

    const validateData = (rowData, id) => {
        console.log("rowData in validateData ",rowData)
        console.log("range newValueMin",newValueMin,"newValueMax",newValueMax)
        
        setValueMaxError(false)
        setMaxErrorMsg(" ")
        setValueMinError(false)
        setMinErrorMsg(" ")

        if(newValueMin<0){
            setValueMinError(true)
            setMinErrorMsg("negative value")
            return false
        }
        if(newValueMax<0){
            setValueMaxError(true)
            setMaxErrorMsg("negative value")
            return false
        }
        var oldMin;
        var oldMax;
        
        rowData.forEach((row)=>{
            if(row.rowid===id){
                oldMin=row.valueMin;
                oldMax=row.valueMax;
            }
        })
        
        let i=0;
        for(i=0;i<rowData.length;i++){
                // debugger;
                let row = rowData[i]
                 console.log("row.value of trim",row.value)
               

                if(row.value!=="default" && row.rowid!==id){
                    console.log("range row verify",row)
                    console.log("range min",row.valueMin,newValueMin,row.valueMax)
                    
           
                    if(newValueMin===undefined){
                        console.log("range newValMin is undefined")
                        if(oldMin>=newValueMax){
                            setValueMaxError(true)
                            setMaxErrorMsg("lesser than minimum")
                            return false
                        }
                    }
                    else if(row.valueMin<=newValueMin && newValueMin<=row.valueMax){
                        console.log("range here")
                        setValueMinError(true)
                        setMinErrorMsg("value included in other ranges")
                        return false
                        
                    }
                    else{
                        if(newValueMin<row.valueMin){
                            if(newValueMax){
                                if(newValueMax>row.valueMin){
                                setValueMinError(true)
                                setMinErrorMsg("value included in other ranges")
                                return false
                                }
                            }
                            else if(oldMax>row.valueMin){
                                setValueMinError(true)
                                setMinErrorMsg("value included in other ranges")
                                return false
                            }
                        }
                        if(newValueMin>row.valueMax){
                            if(newValueMax){
                                if(newValueMax<row.valueMax){
                                setValueMinError(true)
                                setMinErrorMsg("value included in other ranges")
                                return false
                                }
                            }
                            else if(oldMax<row.valueMax){
                                setValueMinError(true)
                                setMinErrorMsg("value included in other ranges")
                                return false
                            }
                        }
                    }
                    if(newValueMax===undefined){
                        console.log("range newValMax is undefined")
                        if(oldMax<=newValueMin){
                            setValueMinError(true)
                            setMinErrorMsg("greater than minimum")
                            return false
                        }
                    }
                    else if(row.valueMin<=newValueMax && newValueMax<=row.valueMax){
                        setValueMaxError(true)
                        setMaxErrorMsg("value included in other ranges")
                        return false
                    }
                    else{
                        if(newValueMax<row.valueMin){
                            if(newValueMin){
                                if(newValueMin>row.valueMin){
                                    setValueMaxError(true)
                                    setMaxErrorMsg("value included in other ranges")
                                    return false
                                }
                            }
                            else if(oldMin>row.valueMin){
                                setValueMaxError(true)
                                setMaxErrorMsg("value included in other ranges")
                                return false
                            }
                        }
                        if(newValueMax>row.valueMax){
                            if(newValueMin){
                                if(newValueMin<row.valueMax){
                                setValueMaxError(true)
                                setMaxErrorMsg("value included in other ranges")
                                return false
                                }
                            }
                            else if(oldMin<row.valueMax){
                                setValueMaxError(true)
                                setMaxErrorMsg("value included in other ranges")
                                return false
                            }
                        }
                    }
                }
        }
                
        return true
    }
    const onSave = async (item, currentRowId, currentValueMin, currentValueMax, currentValue, currentImportance, currentComments) => {
        
        if(!validateData(item?.parameters,currentRowId)){
            return
        }
        console.log("currentValue after validate data",item)
        
        let k;
        for (k = 0; k < item.parameters.length; k++) {
            if (item.parameters[k].rowid == currentRowId) {
                changedParameter = k;
                break;
            }
        }
        console.log(changedParameter);

        if (newValueMin == undefined) { newValueMin = currentValueMin; }
        if (newValueMax == undefined) { newValueMax = currentValueMax; }
        if (newImportance == undefined) { newImportance = currentImportance; }
        if (newComments == undefined) { newComments = currentComments; }
        if (newRealValue == undefined) { newRealValue = currentValue; }

        item.parameters[changedParameter].valueMin = newValueMin;
        item.parameters[changedParameter].valueMax = newValueMax;
        item.parameters[changedParameter].importance = importanceScore;
        item.parameters[changedParameter].comments = newComments;
        item.parameters[changedParameter].value = newRealValue;
        if(!isEmptyCheck(item))
        {
          toast.warning(<Alert severity="warning">Value attribute should not be blank</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
            return
        }
        try {
            await axios.put(REACT_APP_API_URL + '/definition/edit/' + userData.user.tenantId + '/' + icpname?.toLowerCase() + '/' + filterid, item, { withCredentials: true }).then((result) => {
                onCancel();
                newValueMin = undefined;
                newValueMax = undefined;
                newImportance = undefined;
                newComments = undefined;
                newRealValue = undefined;
                importanceScore = new Number()

                axios.get(REACT_APP_API_URL + '/scoredefinition/' + userData.user.tenantId + '/' + icpname?.toLowerCase(), { withCredentials: true }).then((result) => {
                    setData(result.data);
                })

            })
        } catch (error) {
            console.log(error)
        }
    }

    const currentImportance = (valueFromComboBox) => {
        currentComboBoxValue = valueFromComboBox;
    }


    const onUpdateImportance = (importanceFromCombo) => {
        console.log(importanceFromCombo);
        importanceScore = importanceFromCombo
    }

    // const errorStatePass = (newValue, key) => {
    //     console.log(newValue)
    //     setError(false)
    //     if (key === 'valueMin') { newValueMin = newValue; }
    //     if (key === 'valueMax') { newValueMax = newValue; }
    //     setError(true)
    // }

    // const errorStateFail = () => {
    //     setError(true)
    // }

    // const onChangeSetData = (newValue, key) => {
    //     if (key === 'valueMin' || key === 'valueMax')
    //         regexp.test(newValue) ? errorStatePass(newValue, key) : errorStateFail()
    //     else
    //         newComments = newValue
    //     //if (key === 'value') { newRealValue = newValue; }

    // }
    const onChangeSetData = (newValue, key, rowData) => {
        if (key === 'valueMin') {
            newValueMin = parseInt(newValue)
        }
        if (key === 'valueMax') {
            newValueMax = parseInt(newValue)
        }
        if (key === 'comments') { newComments = newValue; }
    }

    const onAddNewCondition = async (filterForPostRequest, forAddingNewCondition) => {
        console.log(filterForPostRequest);
        var newRowIdArray = [];
        var newRowId;
        var j;
        let recordId = forAddingNewCondition.recordId;
        if (forAddingNewCondition.parameters.length !== 0) {
            for (j = 0; j < forAddingNewCondition.parameters.length; j++) {
                newRowIdArray.push(forAddingNewCondition.parameters[j].rowid);
            }
            newRowIdArray.sort(function (a, b) { return b - a });
            newRowId = (newRowIdArray[0] + 1);
        }
        else { newRowId = 1; }

        //Calculating the new rowid 
        const newParameter = {
            rowid: newRowId,
            valueMin: Number(''),
            valueMax: Number(''),
            importance: 0,
            value: '',
            comments: 'Comments'
        }
        forAddingNewCondition.parameters.push(newParameter);
            await axios.put(REACT_APP_API_URL + '/definition/edit/' + userData.user.tenantId + '/' + icpname?.toLowerCase() + '/' + filterForPostRequest, forAddingNewCondition, { withCredentials: true }).then((result) => {
                axios.get(REACT_APP_API_URL + '/scoredefinition/' + userData.user.tenantId + '/' + icpname?.toLowerCase(), { withCredentials: true }).then((result) => {
                    setData(result.data)
                    onEdit(newRowId, recordId)
                })
            })
    }

    const getAttributeWeightage = (attributeWeightageValue) => {
        console.log(attributeWeightageValue);
        var indexOfAttributeWeightageValue = importanceLevel.level.findIndex(function (i) {
            console.log('inside func returning indexOf')
            return i.levelRating == attributeWeightageValue;
        });
        console.log('value of indexOf ' + indexOfAttributeWeightageValue)
        let attributeWeightageText = importanceLevel.level[indexOfAttributeWeightageValue].levelName;
        return attributeWeightageText;

    }

    const getHeaderKeysForNonCategorical = () => {
        return Object.keys({ "Minimum Value": "", "Maximum Value": "", "Weightage": "" });
    }

    const getHeaderKeysForCategorical = () => {
        return Object.keys({ "Value": "", "Weightage": "" });
    }

    const getKeysForNonCategorical = () => {
        // return Object.keys({ "importance": "", "subImportance": "", "valueMin": "", "valueMax": "", "comments": "" });
        return Object.keys({ "valueMin": "", "valueMax": "", "importance": "" });
    }

    const getKeysForCategorical = () => {
        return Object.keys({ "value": "", "importance": "" });
    }

    const funcForUpdatingValue = (extractedValueString) => {
        console.log("Function For Updating Value");
        console.log(extractedValueString)
        //if (key === 'value') { newRealValue = newValue; }
        newRealValue = extractedValueString
    }

    const getHeader = (icpCondition) => {
        var headerContent = [];
        keys = [];
        console.log(icpCondition.type)
        if (icpCondition.type === "Numerical" && !(Array.isArray(icpCondition.parameters) && (!icpCondition.parameters.length))) {
            console.log("getHeaderkeysFornoncategorical");
            keys = getHeaderKeysForNonCategorical();
        }
        else if (icpCondition.type !== "Numerical" && !(Array.isArray(icpCondition.parameters) && (!icpCondition.parameters.length))) {
            console.log("getHeaderkeysForcategorical");
            keys = getHeaderKeysForCategorical();
        }

        if(keys.length === 2){
            headerContent.push(keys.map((key, index) => {
                return <TableCell  classes={{ root: classes.root, head: classes.head }} style={{width:'200px',paddingLeft:'2%', }} key={key}> {key.charAt(0).toUpperCase() + key.slice(1)} </TableCell>
            }))

        }else if(keys.length === 3){
            headerContent.push(keys.map((key, index) => {
                return <TableCell  classes={{ root: classes.root, head: classes.head }} style={{width:'290px',paddingLeft:'2%', }} key={key}> {key.charAt(0).toUpperCase() + key.slice(1)} </TableCell>
            }))

        }


        
        // if (!(Array.isArray(icpCondition.parameters) && (!icpCondition.parameters.length))) {
        //     headerContent.push(<TableCell classes={{ root: classes.root, head: classes.head }} align="right"> Actions </TableCell>)
        // }
        return headerContent
    }


    const RenderRow = (props) => {
        var rowContent = []
        if(props.rowData["rowid"] === 0 && props.item.type === 'Numerical'){
            rowContent.push(
            <>
            <TableCell classes={{ root: classes.root }} style={{ paddingLeft: '2%' ,marginRight:'385px'}}  key={props.rowData['rowid']}>
                                <TextField
                                    color="primary"
                                    style={{backgroundColor:"rgba(0, 0, 0, 0.12)", width:"200px"}}
                                    margin="dense"
                                    variant="outlined"
                                    defaultValue={props.rowData["value"]}
                                    disabled={true}
                                />
                                </TableCell>
                                {
                inEditMode.status && inEditMode.rowKey === props.rowData.rowid && inEditMode.recordId === props.item.recordId ? 
                <TableCell classes={{ root: classes.root }} style={{ paddingLeft: '2%',  }}  key={props.rowData["importance"]}>
                    <ComboBoxImportance imp={props.rowData["importance"]} onUpdateImportance={onUpdateImportance} currentImportance={currentImportance} /> 
                </TableCell>
                :
                <TableCell classes={{ root: classes.root }} style={{ paddingLeft: '12px',  }}  key={props.rowData["importance"]}>
                <ComboBoxImportance imp={props.rowData["importance"]} disable={isDisabled} currentImportance={currentImportance} />
                </TableCell>

                                }
                </>)
        }else if(props.rowData["rowid"] === 0 && props.item.type !== 'Numerical'){
            rowContent.push( 
            <>
            <TableCell classes={{ root: classes.root }} style={{ paddingLeft: '2%' }}  key={props.rowData['rowid']}>
                                <TextField
                                    color="primary"
                                    style={{backgroundColor:"rgba(0, 0, 0, 0.12)",width:'25ch'}}
                                    variant="outlined"
                                    margin="dense"
                                    defaultValue={props.rowData["value"]}
                                    disabled
                                />
                            </TableCell>
                          {
                inEditMode.status && inEditMode.rowKey === props.rowData.rowid && inEditMode.recordId === props.item.recordId ? 
                <TableCell classes={{ root: classes.root }} style={{ paddingLeft: '2%',marginLeft:'0px' }}  key={props.rowData["importance"]}>
                    <ComboBoxImportance imp={props.rowData["importance"]} onUpdateImportance={onUpdateImportance} currentImportance={currentImportance} /> 
                </TableCell>
                :
                <TableCell classes={{ root: classes.root }} style={{ paddingLeft: '2%',marginLeft:'0px' }}  key={props.rowData["importance"]}>
                <ComboBoxImportance imp={props.rowData["importance"]} disable={isDisabled} currentImportance={currentImportance} />
                </TableCell>
                                       } 
                                       </>
                                       ) 
                
        }else{
            rowContent.push(props.keys.map((key, index) => {
          
                return <TableCell classes={{ root: classes.root }} style={{display:'flex', alignItems:'center', paddingLeft: '20px' ,textAlign:'center', }}  key={props.rowData[key]}>
                    {
                        inEditMode.status && inEditMode.rowKey === props.rowData.rowid && inEditMode.recordId === props.item.recordId ? (
                            key !== "importance" ?
                                key !== "value" ?
                                    key === "valueMin" ?
                                    <>
                                        <TextField
                                            color="primary"
                                            style={{width:'200px',marginRight:'54px'}}
                                            margin="dense"
                                            variant="outlined"
                                            error={valueMinError}
                                            type="number"
                                            defaultValue={props.rowData[key]}
                                            onChange={(event) => { onChangeSetData(event.target.value, key, props.rowData) }}
                                            InputProps={{ inputProps: { min: 0 } }}
                                            helperText={valueMinError?minErrorMsg:" "}
                                        />
                                        {
                                          <span style={{ textAlign:'center', marginRight:'20px', fontWeight:500}}> - </span> 
                                        }
                                    </>
                                        :
                                        key === "valueMax" ?
                                            <TextField
                                                color="primary"
                                                style={{width:'200px',marginRight:'81px'}}
                                                margin="dense"
                                                variant="outlined"
                                                error={valueMaxError}
                                                type="number"
                                                defaultValue={props.rowData[key]}
                                                onChange={(event) => { onChangeSetData(event.target.value, key) }}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                helperText={valueMaxError?maxErrorMsg:" "}
                                            /> :
                                            <TextField
                                                color="primary"
                                                width="10px"
                                                margin="dense"
                                                style={{marginLeft: "10px"}}
                                                variant="outlined"
                                                error={commentError}
                                                defaultValue={props.rowData[key]}
                                                onChange={(event) => onChangeSetData(event.target.value, key)}
                                            />
                                    :
                                   // <MultiSelectStringComboBox placeholder={props.item.name} funcForUpdatingValue={funcForUpdatingValue} currentValue={props.rowData.value} isDisabled={false} />
                                      <MultiSelectComboBox style={{width:'25ch',marginRight:'8px'}} placeholder={props.item.name} funcForUpdatingValue={funcForUpdatingValue} currentValue={props.rowData.value} />
                                :
                                <ComboBoxImportance style={{marginLeft: "10px"}} imp={props.rowData["importance"]} onUpdateImportance={onUpdateImportance} currentImportance={currentImportance} />
    
                        )
                            :
                            key !== "importance" ?
                                key !== "value" ?
                                    // props.rowData[key]
                                    <>
                                    <TextField
                                    style={key==='valueMin'?{width:'200px',marginRight:'54px'}:{width:'200px',marginRight:'80px', marginLeft: "6px"}}
                                    color="primary"
                                    margin="dense"
                                    variant="outlined"
                                    error={commentError}
                                    disabled={isDisabled}
                                    defaultValue={props.rowData[key]}
                                    helperText={" "}
                                />
                                {
                                    key === 'valueMin' ? <span style={{ textAlign:'center', marginRight:'20px',  fontWeight:500}}> - </span>: <></>
                                }
                                </>
                                     :
                                   //  <MultiSelectStringComboBox  placeholder={props.item.name} funcForUpdatingValue={funcForUpdatingValue} currentValue={props.rowData.value} disable={true} />
                                     <MultiSelectComboBox style={{width:'25ch',marginRight:'20%'}} placeholder={props.item.name} funcForUpdatingValue={funcForUpdatingValue} currentValue={props.rowData.value} disable={isDisabled} /> 
                                    :
                                <ComboBoxImportance imp={props.rowData["importance"]} disable={isDisabled} currentImportance={currentImportance} />
    
    
                    }
    
                </TableCell>
            }
                )) 

        }
        
        
       
        rowContent.push(<TableCell classes={{ root: classes.root }} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            {
                inEditMode.status && inEditMode.rowKey === props.rowData.rowid && inEditMode.recordId === props.item.recordId ? (
                    <React.Fragment>
                        <Tooltip title="Save" arrow>
                            <IconButton aria-label="save" className={classes.margin} onClick={() => onSave(props.item, props.rowData.rowid, props.rowData.valueMin, props.rowData.valueMax, props.rowData.value, props.rowData.importance, props.rowData.comments)}>
                                <SaveIcon style={{ color:"#939393" }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel" arrow>
                            <IconButton aria-label="cancel" className={classes.margin} onClick={() => onCancel()}>
                                <CancelIcon style={{ color: "#939393" }} />
                            </IconButton>
                        </Tooltip>

                    </React.Fragment>) : (
                    <Tooltip title="Edit" arrow>
                        <IconButton aria-label="edit" className={classes.margin} onClick={() => onEdit(props.rowData.rowid, props.item.recordId)}>
                            <EditIcon style={{ color: "#939393" }} />
                        </IconButton>
                    </Tooltip>
                )
            }
            {props.rowData.rowid !== 0 ?
                <Tooltip title="Delete" arrow>
                <IconButton aria-label="delete" className={classes.margin} onClick={() => onDelete(props.item, props.rowData.rowid, props.filterId)}>
                    <DeleteIcon style={{ color: "#939393" }} />
                </IconButton>
            </Tooltip>
            : <></>}
            
        </TableCell>)
        // rowContent.push(<TableCell  classes={{ root: classes.root }} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        //     <MultipleSelectStringComboBox></MultipleSelectStringComboBox>
        // </TableCell>)

        return rowContent
    }


    const getRowsData = (icpCondition, filterId) => {
        var getRowsDataContent = [];
        var items = icpCondition.parameters;
        console.log(items)
        var item = icpCondition;
        //Get keys for populating the data in the parameter table of a partcular IcpCondition
        if (icpCondition.type === "Numerical") {
            keys = getKeysForNonCategorical();
        }
        else {
            keys = getKeysForCategorical();
        }
        getRowsDataContent.push(items.map((row, index) => {
          return <TableRow style={{display:'flex', justifyContent:'left', alignItems:'center'}} key={index}>
                    <RenderRow key={index} rowData={row} keys={keys} item={item} filterId={filterId} />
                </TableRow>
        }))
        return getRowsDataContent
    }


    const renderTable = () => {
        //sorting the filters so that they all come in a sorted order according to filterId
        data.filterCondition.sort((a, b) => {
            return a.filterId - b.filterId;
        });
        console.log('in render table length of data..', data.filterCondition.length );

        //To get the filter with respect to which icpCondition is being configured 
        var indexOfSelectedFilter = data.filterCondition.findIndex(function (i) {
            console.log('in render table length of data..i.filterId ', i.filterId);
            return i.filterId == filterid;
            
        })

        data.filterCondition[indexOfSelectedFilter]?.icpCondition.sort((a, b) => {
            console.log('in render table length of data..icpcondition ', data.filterCondition[indexOfSelectedFilter].icpCondition.length);
            return a.recordId - b.recordId;
        });

        var filterId = data.filterCondition[indexOfSelectedFilter]?.filterId;
        var renderComponents = data.filterCondition[indexOfSelectedFilter]?.icpCondition.map(function (icpCondition) {
            //To sort the Parameters according to rowid
            icpCondition.parameters.sort((a, b) => {
                return a.rowid - b.rowid;
            });
            return <div style={{ marginBottom: '1%', marginLeft: '2%', marginRight: '2%' }}>
                <div>
                    <div>
                        <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center',textAlign:'center', paddingRight: '1%', paddingLeft: '2%', height: '20%' }}>
                            <div style={{display:'flex'}}>
                            <h4 style={{ fontWeight: 600,textAlign:'left',padding:'0px 4px 0px 0px' }}>{configMapper[icpCondition?.name]?.label}</h4>
                            <h4 style={{ fontWeight: 200}}>is weighed <span style={{fontWeight:400,padding:'0px 2px'}}>{getAttributeWeightage(icpCondition.attributeWeightage)}</span></h4>
                            </div>
                            <Button
                                // variant="outlined"
                                // color="primary"
                                // classes={{ containedPrimary: classes.containedPrimary }}
                                className={classes.button}
                                style={{ backgroundColor: '#21DA9C' }}
                                startIcon={<Icon>add</Icon>}
                                onClick={() => onAddNewCondition(filterId, icpCondition)}
                            >
                                New Condition
                            </Button>
                        </div>
                    </div>



                    <Table className={classes.table} aria-label="simple table" >
                        <TableHead style={{width:'100%'}}>
                            <TableRow style={{display:'flex'}}>
                                {data === undefined ? <div></div> :
                                    getHeader(icpCondition)
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data === undefined ?
                                (<React.Fragment>data</React.Fragment>) : (

                                    getRowsData(icpCondition, filterId)
                                )}
                        </TableBody>
                    </Table>
                </div>

                {/* <hr style={{ backgroundColor: "#FFFFFF", opacity: '0.3', marginLeft: '2%', marginRight: '2%' }}></hr> */}
            </div>

        });

        return renderComponents;
    }

    const classes = useStyles();

    return (
        <div>
            {data === undefined ?
                <div style={{ position: "absolute", top: "40%", left: "50%", display: "flex", justifyContent: "center" }}>
                    <div className={classes.root}><CircularProgress size="3rem" style={{color:'#21DA8C'}}/></div>
                </div> :
                <React.Fragment>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {/* <Bar label={label} previousICP={noICP} onBackClick={onBackClick} /> */}
                        {/* <Paper elevation={5} style={{ display: 'flex', justifyContent: 'flex-start',marginRight: '2%', alignItems: 'center', marginLeft: '2%', marginBottom:'30px'}}>
                            <Paper elevation={2} style={{width:'90%', height:'40px'}}>
                                <Typography style={{ fontFamily: 'Roboto', fontSize: "16px", padding: '1%', fontStyle: 'normal', fontWeight: 350, lineHeight: '22px',}}>
                                    <span style={{fontWeight:'500',paddingLeft:'15px'}}>Description: </span>{data.comments}</Typography>
                            </Paper>
                        </Paper> */}
                        {/* <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft: '2%', marginBottom:'30px'}}>
                            
                        </div> */}
                        <div style={{ display :"flex" ,height :"80px",flexDirection :"row" , justifyContent: "space-between",background : 'linear-gradient(0deg, rgba(55, 234, 158, 0.08), rgba(55, 234, 158, 0.08)), #FFFFFF',height:'48px',alignItems: 'center',margin:'10px 0px'}} >
                        {filtername=='default' ?<div style={{padding :"2.8%"}} className={classes.headtext}>Weightages</div>:<div style={{padding :"2.8%"}} className={classes.headtext}>Weightages (Step 2 of 2)</div>}
                        </div>
                    </div>
                    {/* <hr style={{ backgroundColor: "#FFFFFF", opacity: '0.3', marginLeft: '2%', marginRight: '2%' }}></hr> */}
                    <TableContainer>
                        <React.Fragment>{renderTable()}</React.Fragment>
                    </TableContainer>
                </React.Fragment>
            }</div>
    );
}

export default ICPconditions