import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import _uniqueId from 'lodash/uniqueId';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '18vw',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    inputRoot: {
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: '#016B40',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#016B40',
        },
      },
    }
}));

const EngagementComboBox = (props) => {
    const [comboBoxData, setComboBoxData] = React.useState(props.comboBoxData);
    const [personaDepartmentValue, setpersonaDepartmentValue] = React.useState(props.inputValue);
    const [id] = React.useState(_uniqueId('combo-box-demo-'));
    const classes = useStyles();
    console.log(props.comboBoxData)
    const disabled = props.disable

    return (
        <div className={classes.root}>
            <Autocomplete
                id={id}
                size="small"
                options={props.comboBoxData}
                getOptionLabel={(option) => (option === null) ? "" : option.name}
                //getOptionLabel={(option) => option.name}
                autoSelect
                disableClearable
                popupIcon={<KeyboardArrowDownIcon/>}
                // disabled={props.disable}
                disabled={disabled}
                onChange={(event, newValue) => {
                    console.log(newValue);
                    newValue === null ? setpersonaDepartmentValue("") : setpersonaDepartmentValue(newValue.name)
                    props.valueFunc(newValue.name, props.label, props.rowId)
                }}

                onInputChange={(event, newValue) => {
                    setpersonaDepartmentValue(newValue);
                }}
                renderInput={(params) => ( <TextField {...params} classes={{ root: classes.inputRoot }} variant="outlined" value={props.inputValue} label={props.inputValue} InputLabelProps={{ style: { color: disabled ? "#888888" : "#262626", marginTop: '2.5px', marginLeft: '3px' }}} /> )}
            />
        </div>
    );
}

export default EngagementComboBox