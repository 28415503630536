import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from '@mui/icons-material/Check';
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { REACT_APP_API_URL2, REACT_APP_API_URL3 } from "./../Constant";
import { useAuthState } from "@bamboobox/b2logincheck";
import Icon from "@material-ui/core/Icon";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MultiSelectBox from './MultiSelectBox';
import MultiSelectBoxForNumeric from './MultiSelectBoxForNumeric';
import MulitSelectBoxForDefault from './MultiSelectBoxForDefault';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Alert from '@mui/material/Alert';
import "./../toast.css"

const TENANTCONFIG_API = window._env_.TENANTCONFIG_API;

toast.configure({
  autoClose: 4000,
  draggable: true,
  hideProgressBar: false,
  position: toast.POSITION.BOTTOM_LEFT,
  icon: false,
});

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    width: '18vw',
    border: "none",
    fontFamily:'Nunito Sans'
  },
  contained: {
    backgroundColor: "primary",
  },
  TouchRipple: {
    backGroundColor: "#82B440",
  },
  header: {
    width: '100%',
    display: 'flex',
    background: 'linear-gradient(0deg, rgba(55, 234, 158, 0.08), rgba(55, 234, 158, 0.08)), #FFFFFF !important',
    height: '63px',
    borderRadius: '20px 20px 0px 0px ',
    justifyContent: 'right',
    alignItems: 'center',
  },
  button: {
    background: '#21DA8C',
    borderRadius: '18px',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '24px',
    /* identical to box height, or 171% */

    letterSpacing: '0.4px',
    textTransform: 'uppercase',

    /* primary / contrastText */

    color: '#FFFFFF',
    marginRight: '32px'

  },
  selected: {
    borderRadius: "16px !important",
    background: "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #21DA8C !important"

    // background:"#d2f8d2 !important" 
  }
});

const groupSizeLabel = "Group Size";

const EngagementTypeTable = (props) => {
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const userData = useAuthState();
  const [categories, setCategories] = useState([]);
  const [attributeselected, setAttributeselected] = React.useState(null);
  const [attributeType, setAttributeType] = React.useState("");
  const [values, setValues] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [dataInDB, setDataInDB] = useState([]);
  const [states, setStates] = useState([]);
  const [statesToSubmit, setStatesToSubmit] = useState([]);
  const [statesInDB, setStatesInDB] = useState([]);
  const [attributeInDB, setAttributeInDB] = useState('');
  const [optionsList, setOptionsList] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(true); //Conditionally enable and disable button
  const [statesToSubmitValidity, setStatesToSubmitValidity] = useState([])
  const [fetched, setFetched] = useState(false);
  const [resultLabel, setResultLabel] = useState();

  const getAttributeData = async () => {
    //get request for the categorical or hierarchichal data from Lohita's service
    console.log("This is running: " + "placeholder")
    await axios.get(REACT_APP_API_URL3 + '/' + userData.user.tenantId + '/describe.json', { withCredentials: true }).then((result) => {
      //TODO: add ID object to category List
      let categoriesList = [];
      if (result.status == 200) {
        var json = JSON.parse(JSON.stringify(result.data));
        let rowid = 1;
        for (let i = 0; i < json.length; i++) {
          if ((json[i].type === "categorical" || json[i].type === "hierarchical" || json[i].type === "Numerical")&&(json[i]["ICP"]==='yes')) {

            json[i].id = rowid;
            //json[1].selectedValues=[];
            rowid++;
            categoriesList.push(json[i]);
          
            //TODO: will need to create one object for values
          }
        }
      }
      setCategories(categoriesList);
    });

  }

  const getAttributeLabel = async () => {
    await axios.get(`${TENANTCONFIG_API}/tenant/field/mapper/get/${userData.user.tenantId}/score-config-ui`, { withCredentials: true }).then((result) => setResultLabel(result.data))
} 

  const fetchData = async () => {
    console.log("buyer grp fetched",fetched)
    // make a call to company segment and fetch the data
    try {
      // if categories is not loaded.. return
      if (categories.length === 0) {
        return;

      }
      await axios.get(REACT_APP_API_URL2 + '/companySegment/' + userData.user.tenantId + '/' + props?.icpname, { withCredentials: true }).then((result) => {
        setDataInDB(result?.data);
        //get Values from Attribure
        let statesVal = []
        let option = []
        setAttributeInDB(result.data.buyerGroupAttribute);
        categories.map((category) => {
          if (category.name === result.data.buyerGroupAttribute && (category.position == 1 || category.type==="Numerical")) {
            option = category.values;
            setAttributeselected(category.name);
            console.log("line no 150",category)
            setAttributeType(category.type);
            setOptionsList(option);
            return;
          }
        });
        for (let i = 0; i < result.data.companySegment.length; i++) {
          // convert comma seperated segment to array    
          let valuesSel = result.data.companySegment[i].gtmSegmentId.segment.split(',');
          const selectval = [];
          valuesSel.map((val) => {
            selectval.push({ value: val });
          });

          let defaultVal = false;
          if (result.data.companySegment[i].gtmSegmentId.segment === 'default') {
            defaultVal = true;
          }
          const state = {
            name: result.data.buyerGroupAttribute,
            minVal: result.data.companySegment[i].minVal,
            maxVal: result.data.companySegment[i].maxVal,
            type: result.data.type,
            options: option,
            selectedValues: selectval,
            rowId: i,
            defaultVal: defaultVal,
            groupSize: result.data.companySegment[i].groupSize
          };
          statesVal.push(state);
        }
        // setStates(statesVal);
        // setStatesToSubmit(statesVal);
        // setStatesInDB(statesVal);
        addDefaultRow(statesVal);
        setFetched(true);
      });
    } catch (err) {
      console.error(err)
    }
  };

  const validMinRange = (stateRecord,currMinVal) => {
    if(stateRecord.maxVal!==0 && stateRecord.maxVal<currMinVal){
      return false
    }
    let ranges = [];
    let i = 0;
    statesToSubmit.map((state) => {
      if (state.rowId !== stateRecord.rowId) {
        ranges.push([state.minVal,state.maxVal]);
      }
    });
    for(i=1;i<ranges.length;i++){
      if(ranges[i][0]<=currMinVal && currMinVal<=ranges[i][1]){
        return false;
      }
      else if(currMinVal<ranges[i][0]){
        if(stateRecord.maxVal!==0 && stateRecord.maxVal>ranges[i][0]){
          return false
        }
      }
      else if(currMinVal>ranges[i][1]){
        if(stateRecord.maxVal!==0 && stateRecord.maxVal<ranges[i][1]){
          return false
        }
      }
    }
    return true;
  }

  const validMaxRange = (stateRecord,currMaxVal) => {
    if(stateRecord.minVal!==0 && currMaxVal<stateRecord.minVal){
      return false
    }
    let ranges = [];
    let i = 0;
    statesToSubmit.map((state) => {
      if (state.rowId !== stateRecord.rowId) {
        ranges.push([state.minVal,state.maxVal]);
      }
    });
    for(i=1;i<ranges.length;i++){
      if(ranges[i][0]<=currMaxVal && currMaxVal<=ranges[i][1]){
        return false;
      }
      else if(currMaxVal<ranges[i][0]){
        if(stateRecord.minVal!==0 && stateRecord.minVal>ranges[i][0]){
          return false
        }
      }
      else if(currMaxVal>ranges[i][1]){
        if(stateRecord.minVal!==0 && stateRecord.minVal<ranges[i][1]){
          return false
        }
      }
    }
    return true;
  }

  const validateStateRange = (stateRecord) => {
      if(stateRecord.groupSize===0){
        return false
      }
      if(stateRecord.minVal>stateRecord.maxVal){
        return false
      }
      if(validMinRange(stateRecord,stateRecord.minVal) && validMaxRange(stateRecord,stateRecord.maxVal)){
        return true
      }
      else{
        return false
      }
    };
  
  const addDefaultRow = (statesVal) => {
    const defaultStates = [];
    const tempStates = [];
    let option = [];
    statesVal.map((state) => {
      tempStates.push(state)
      if (state.defaultVal) {
        defaultStates.push(state);
      }
    });
    //if default state is empty, add default state
    if (defaultStates.length === 0) {
      const state = {
        name: "default",
        options: option,
        selectedValues: [{ value: 'default' }],
        rowId: 0,
        defaultVal: true,
        groupSize: 1,
        minVal: 0,
        maxVal: 0,
        type: attributeType,
      };
      tempStates.push(state);
    }
    console.log("buyer grp - defaultStates",defaultStates)
    console.log("buyer grp - tempStates",tempStates)
    setStatesToSubmit(tempStates);
    setStates(tempStates);
    setStatesInDB(tempStates);
  }
  useEffect(() => {
    getAttributeData();
  }, [props.icpname]);
  useEffect(() => {
    setFetched(false)
    fetchData();
  }, [categories, props.icpname]);

  useEffect(() => {
    getAttributeLabel();
  }, [])

  useEffect(() => {
    let localStatesToSubmitValidity = [];
    statesToSubmit.map((state1)=>{
      let flag = 1;
      statesToSubmitValidity?.map((state2) => {
        if(state1.rowId === state2.rowId){
          flag = 0;
          const newState = {
            rowId: state1.rowId,
            validity: state2.validity
          };
          localStatesToSubmitValidity.push(newState)
        }
      })
      if(flag === 1){
        let newState = {}
        if(state1.defaultVal===false){
          newState = {
            rowId: state1.rowId,
            validity: true
          };
        }
        else{
          let value = validateStateRange(state1)
          newState = {
            rowId: state1.rowId,
            validity: value
          };
        }
        localStatesToSubmitValidity.push(newState)
      }
    })
    console.log("valid range - localStatesToSubmitValidity",localStatesToSubmitValidity)
    setStatesToSubmitValidity(localStatesToSubmitValidity)
  },[statesToSubmit])


  const onAttributeChange = (event) => {
    console.log("line no 201 - attribut change")
    setAttributeselected(event.target.value);
    let option = [];
    //get values from category and update values object
    categories.map((category) => {
      if (category.name === event.target.value && (category.position == 1 || category.type=="Numerical")) {
        //set selected Attribute state
        option = category?.values;
        setOptionsList(option);
        setAttributeType(category?.type)
        console.log("line no 211",category?.type,attributeType)
      }
    });
    //If attribute is same as attribute in DB, set DB state
    if (event.target.value == attributeInDB) {
      setStates(statesInDB);
      setStatesToSubmit(statesInDB);
      return;
    }
    setButtonDisable(false);
    // clear child values

    const defaultStates = [];
    states.map((state) => {
      if (state.defaultVal) {
        state.name = event.target.value;
        state.type = attributeType;
        console.log("line no 226", attributeType)
        defaultStates.push(state);
      }
    });
    //if default state is empty, add default state
    if (defaultStates.length === 0) {
      const state = {
        name: event.target.value,
        options: option,
        selectedValues: [{ value: 'default' }],
        rowId: 0,
        defaultVal: true,
        groupSize: 1,
        minVal: 0,
        maxVal: 0,
        type: attributeType,
      };
      defaultStates.push(state);
    }
    setStatesToSubmit(defaultStates);
    setStates(defaultStates);
    
  };
  console.log("line no 251",attributeType)
  const renderAttributeOptions = () => {
    let distinctCategories = categories.filter(item => (item.position == 1 || item.type=="Numerical") && item.name !== '')

    return distinctCategories.map((row) => {
        return (
          <MenuItem classes={{ selected: classes.selected }} value={row.name}>{resultLabel?.[row.name]?.label}</MenuItem>
        );
    });
  }




  const onRemoved = (stateRecord, selList) => {

    let localStatesToSubmit = []
    statesToSubmit.map((state) => {
      if (state.rowId === stateRecord.rowId) {
        state.selectedValues = selList;
        console.log(state.selectedValues);
      }
      localStatesToSubmit.push(state);

    });
    setStatesToSubmit(localStatesToSubmit);
    setStates(localStatesToSubmit);
    setButtonDisable(false);
  }

  const onSelected = (stateRecord, selList) => {

    let localStatesToSubmit = []
    statesToSubmit.map((state) => {
      if (state.rowId === stateRecord.rowId) {
        state.selectedValues = selList;
        console.log(state.selectedValues);
      }
      localStatesToSubmit.push(state);

    });
    setStatesToSubmit(localStatesToSubmit);
    setStates(localStatesToSubmit);
    setButtonDisable(false);
  }

  const onDeleted = (stateRecord) => {
    let localStatesToSubmit = []
    statesToSubmit.map((state) => {
      if (state.rowId !== stateRecord.rowId) {
        localStatesToSubmit.push(state);
      }

    });
    let localStatesToSubmitValidity = []
    statesToSubmitValidity.map((state) => {
      if (state.rowId !== stateRecord.rowId) {
        localStatesToSubmitValidity.push(state);
      }

    });
    setStatesToSubmit(localStatesToSubmit);
    setStatesToSubmitValidity(localStatesToSubmitValidity);
    setStates(localStatesToSubmit)
    setButtonDisable(false);
  }

  const onGroupSizeUpdated = (stateRecord) => {
    let localStatesToSubmit = []
    statesToSubmit.map((state) => {
      if (state.rowId === stateRecord.rowId) {
        state.groupSize = stateRecord.groupSize;
      }
      localStatesToSubmit.push(state);
    });
    setStatesToSubmit(localStatesToSubmit);
    setStates(localStatesToSubmit);
    setButtonDisable(false)
  }

  const onMinValueUpdated = (stateRecord) => {
    let localStatesToSubmit = []
    statesToSubmit.map((state) => {
      if (state.rowId === stateRecord.rowId) {
        state.minVal = stateRecord.minVal;
      }
      localStatesToSubmit.push(state);
    });
    console.log("line no 327",localStatesToSubmit)
    setStatesToSubmit(localStatesToSubmit);
    setStates(localStatesToSubmit);
    setButtonDisable(false)
  }

  const onMaxValueUpdated = (stateRecord) => {
    let localStatesToSubmit = []
    statesToSubmit.map((state) => {
      if (state.rowId === stateRecord.rowId) {
        state.maxVal = stateRecord.maxVal;
      }
      localStatesToSubmit.push(state);
    });
    setStatesToSubmit(localStatesToSubmit);
    setStates(localStatesToSubmit);
    setButtonDisable(false)
  }



  const onNewCondition = () => {
    console.log("line no 314", attributeselected, attributeType, optionsList)
    const newState = {
      name: attributeselected,
      options: optionsList,
      selectedValues: [],
      minVal: 0,
      maxVal: 1,
      type: attributeType,
      rowId: states.length,
      defaultVal: false,
      groupSize: 1
    };
    const newStates = [...states, newState];
    setStatesToSubmit(newStates);
    setStates(newStates);

  }


  const checkRangeValidity = () => {
    console.log("valid range - 488",statesToSubmitValidity)
    let validStateCount = 0;
    statesToSubmitValidity.map((validityState)=>{
      if(validityState.validity){
        validStateCount+=1
      }
    })
    if(validStateCount === statesToSubmit.length){
      console.log("valid range - 500")
      return true
    }
    return false
  }
  const onUpdateGTM = () => {
    let rangeValidity = false
    setTimeout(checkRangeValidity()===true?rangeValidity=true:"", 3000); 
    // if(checkRangeValidity()){
      
    // }
    // else{
      
    // }
    console.log("valid range - boolean",rangeValidity)
    if(rangeValidity === false){
      toast.warning(<Alert severity="warning">Invalid Range</Alert>,{
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true
      });
      return
    }
    let gtmSegmentPostBody = [];
    statesToSubmit.map((state) => {
      const commaSep = state.selectedValues.map(item => item.value).join(', ');
      console.log("line no 379",state)
      let segmentRow = {
        "attribute": state.name,
        "segment": commaSep || state.minVal?.tostring().concat("-").concat(state.maxVal?.tostring()).concat(","),
        "minVal": state.minVal,
        "maxVal": state.maxVal,
        "type": attributeType,
        "groupSize": state.groupSize,
        "rowId": state.rowId
      }
      gtmSegmentPostBody.push(segmentRow)
    });
    try {
      axios.post(REACT_APP_API_URL2 + '/companySegment/' + userData.user.tenantId + '/' + props.icpname, gtmSegmentPostBody, { withCredentials: true }).then((result) => {
        props.setBackdropStateFromChildComponent(false)
        // props.snackbarCallbackToTab({
        //   open: true,
        //   severity: "success",
        //   message: "Successfully updated"
        // })
        toast.success(<Alert severity="success">Successfully updated</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
        setButtonDisable(true)
      });
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
          <div className={classes.header}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              // classes={{ containedPrimary: classes.containedPrimary }}
              // className={classes.button}
              style={{background:'#21DA8C',cursor: 'pointer'}}
              startIcon={<Icon>add</Icon>}
              onClick={onNewCondition}
            >
              New Condition
            </Button>
          </div>
          <TableContainer style={{ height: "55vh", border: "none" }}>
          {!fetched ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    left:"50%",
                    top:"50%",
                  }}
                >
                  <CircularProgress size="5rem" style={{ color: '#21DA8C' }} />
                </div>
              )
              :
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.root }} align="left">
                    <FormControl  >
                      {!attributeselected&&<InputLabel id="demo-simple-select-label">Select Attribute</InputLabel>}
                      <Select
                        labelId="demo-simple-select-label"
                        //id="demo-simple-select"
                        // label="Attribute"
                        variant="outlined"
                        style={{ width: '40vw', maxHeight:"40px" }}
                        IconComponent={KeyboardArrowDownIcon}
                        value={attributeselected}
                        defaultValue={attributeselected}
                        onChange={onAttributeChange}
                        disableUnderline
                        MenuProps={

                          {
                            PaperProps: {
                              style: {
                                borderRadius: "16px"
                              }
                            },
                            getContentAnchorEl: () => null,
                            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                            transformOrigin: { vertical: 'top', horizontal: 'center' },

                          }

                        }
                      >
                        {renderAttributeOptions()}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell classes={{ root: classes.root }} align="left" >
                    Buyer Group Size
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {states.map((state) => (
                    state.defaultVal===true?
                  <MulitSelectBoxForDefault state={state} onGroupSizeUpdated={onGroupSizeUpdated} statesToSubmitValidity={statesToSubmitValidity} />
                    :""
                    ))}
              {states.map((state) => (
                state.defaultVal===false?
                  (
                    state.type==="Numerical"?
                  <MultiSelectBoxForNumeric state={state} onDeleted={onDeleted} onGroupSizeUpdated={onGroupSizeUpdated} 
                  statesToSubmitValidity={statesToSubmitValidity} statesToSubmit={statesToSubmit} onMinValueUpdated={onMinValueUpdated} onMaxValueUpdated={onMaxValueUpdated}
                  />
                  :
                  <MultiSelectBox state={state} onRemoved={onRemoved} onSelected={onSelected} onDeleted={onDeleted} 
                  onGroupSizeUpdated={onGroupSizeUpdated} statesToSubmitValidity={statesToSubmitValidity}
                  />
                  )
                :""
                ))}
              </TableBody>              
            </Table>
          }
          </TableContainer>
          <Button
            variant="contained"
            classes={{ contained: classes.contained }}
            // disabled={buttonDisable}
            size="middle"
            startIcon={<CheckIcon />}
            style={{
              position: 'relative',
              // marginTop: "4%",
              // marginBottom: "6%",
              
              margin:'auto',
              marginLeft: "83%",
              // right:'3px',
              textTransform: "none",
            }}
            className={classes.button}
            onClick={() => onUpdateGTM()}
          >
            UPDATE
          </Button>
          
        </React.Fragment>
  );
};

export default EngagementTypeTable;
