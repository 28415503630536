import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { styled } from "@mui/system";

const Dialog1 = styled(Dialog)(({ theme }) => ({

  [`.MuiPaper-rounded`]:{
    borderRadius: '18px',
  },
}))

const AlertDialog = (props) => {
  //console.log(props)
  //const [open, setOpen] = React.useState(props.alertDialogStatus);

  const handleClickOpen = () => {
    //setOpen(true);
    props.onDelete(props.currentIcp)
  };

  const handleClose = () => {
    props.closeAlert()
    //setOpen(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog1
        open={props.alertDialogStatus}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete ICP?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure, you want to delete this ICP.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{display:'flex',justifyContent: 'space-evenly',marginBottom: '20px'}}>
          <Button onClick={handleClose} color="primary"
          
          style={{ 
            color: '#21DA8C',
            border: '1px solid rgba(33, 218, 140, 0.5)'
          }}
          >
            NO
          </Button>
          <Button onClick={handleClickOpen} color="primary" autoFocus
          style={{
            color: '#ffffff',
            background: '#21DA8C'
          }}
          >
            YES
          </Button>
        </DialogActions>
      </Dialog1>
    </div>
  );
}


export default AlertDialog