import { Typography } from '@mui/material';
import {NewAttributeButton} from './NewAttributeButton'

export const FilterHeader=({setIsNewAttributeClicked,text})=>{ 
    const handleClick=()=>{
        setIsNewAttributeClicked(true);
    }
    return(
        <div style={{display:"flex",background: 'linear-gradient(0deg, rgba(55, 234, 158, 0.08), rgba(55, 234, 158, 0.08)), #FFFFFF',padding:"5px 10px",alignItems:'center',justifyContent:"space-between",marginBottom:"10px"}}>
        <Typography variant='subtitle1'>{text}</Typography>
        <NewAttributeButton handleClick={handleClick}/>
        </div>
    )
}