import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import _uniqueId from 'lodash/uniqueId';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import SimpleTextField from "./SimpleTextField";
import { TextField } from '@mui/material';
import "./MulitSelectBoxForDefault.css";



const useStyles = makeStyles({
    table: {
      minWidth: 650,
    }, 
    root: {
      border: "none",
      maxHeight:"40px",
    },
    default:{
      minWidth:"40vw",
    },
    contained: {
      backgroundColor: "primary",
    },
    TouchRipple: {
      backGroundColor: "#82B440",
    },
    chips:{
      background:'#000000'
    }
  });
  
const groupSizeLabel = "Group Size";


const MulitSelectBoxForDefault = (props) => {
    const classes = useStyles();
    const state = props?.state;
    const [newGrpSizeVal, setNewGrpSizeVal] = useState(state?.groupSize || 1)
    const [grpSizeError,setGrpSizeError] = useState(false)
    const [grpSizeHelpMsg, setGrpSizeHelpMsg] = useState("")

    useEffect(()=>{
      if(newGrpSizeVal===0){
        setGrpSizeHelpMsg("greater than zero")
        setGrpSizeError(true)
      }
    },[])
    const validityChange = (value) => {
      props.statesToSubmitValidity.map((validityState) => {
        if(validityState.rowId === state.rowId){
          validityState.validity = value
        }
      })
      console.log("valid range - props.statesToSubmitValidity 57",props.statesToSubmitValidity)
    }
    const onValChange = (val,key) =>{
      let grpSizeVal=parseInt(val)
      if(key=="grpSize"){
        setNewGrpSizeVal(grpSizeVal)
      }
    }

    const onGrpSizeChange = () => {
      if(newGrpSizeVal>0){
        state.groupSize = newGrpSizeVal;
        props.onGroupSizeUpdated(state);
        setGrpSizeHelpMsg(" ")
        setGrpSizeError(false)
        validityChange(true)
      }
      else{
        setGrpSizeHelpMsg("greater than zero")
        setGrpSizeError(true)
        validityChange(false)
      }
    }

    return (
        <TableRow>
            <TableCell classes={{ root: classes.root }} align="left" style={{maxHeight:"40px"}} >
                <TextField
                  size="small"
                  label="Default"
                  style={{
                    backgroundColor:"rgba(0, 0, 0, 0.06)",
                    width:"40vw",
                  }}
                  disabled={true}
                >

                </TextField>
            </TableCell>
                <TableCell classes={{ root: classes.root }} align="left" style={{width:"20%",padding:'8px 16px 16px 16px !important', marginTop:"8px !important"}}>
                    {/* <SimpleTextField rowId={state?.rowId} label={groupSizeLabel}
                      inputValue={state?.groupSize} valueFunc={gtmValueFunc} /> */}
                    <TextField
                        rowId={state?.rowId}
                        margin="dense"
                        variant="outlined"
                        value={newGrpSizeVal}
                        placeholder={newGrpSizeVal}
                        error={grpSizeError}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1}}      
                        type="number"
                        onChange={(event) => { onValChange(event.target.value,"grpSize") }}
                        onBlur={onGrpSizeChange}
                        helperText={grpSizeError?grpSizeHelpMsg:" "}
                        style={{padding:"8px 16px !important"}}
                    /> 
                </TableCell>
                <TableCell
                      classes={{ root: classes.root }}
                      align="left"
                      margin="-50%"
                    >
                      {state.defaultVal===false?
                      ""
                      :""}
                    </TableCell>
        </TableRow>
    )
}

export default MulitSelectBoxForDefault