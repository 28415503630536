import { Typography } from '@mui/material';
import { CustomAutoComplete } from './CustomAutoComplete';
import { DeleteButton } from './DeleteButton';
import { useState } from 'react';

export const DefaultFilterValues=({options,selectedWeightage,obj,updateObj})=>{
    const [dataObj,setDataObj]=useState(obj);
    const handleChange=(input)=>{
        // console.log("v",input)
        setDataObj((latestobj)=>{
            const newObj={...latestobj}
            newObj.importance=input?.value;
            updateObj(newObj);
            return newObj;
        })
    }
    return (
        <div style={{display:"flex",marginBottom:"10px"}}>
            <div style={{display:"flex",flexDirection:"column",marginLeft:"10px"}}>
                <Typography variant='overline' style={{fontFamily:"Nunito Sans"}}>Value</Typography>
                <CustomAutoComplete styles={{width:250}} variant={"outlined"} disabled={true} options={options} selected={"default"} readOnly={true} multiple={false}/>
            </div>
            <div style={{display:"flex",flexDirection:"column",marginLeft:"10px"}}>
                <Typography variant='overline' style={{fontFamily:"Nunito Sans"}}>Weightage</Typography>
                <CustomAutoComplete styles={{width:250}} variant={"outlined"} disabled={false} options={options} selected={selectedWeightage} updateSelected={handleChange} multiple={true}/>
            </div>
        </div>)
    
}

