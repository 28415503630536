import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
//import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';//backArrow
import { useParams, useLocation } from 'react-router';//For sending icpname through routing
import EngagementComboBox from "./EngagementComboBox";
import EngagementTypeTable from "./EngagementTypeTable";
import PersonaDepartmentTable from "./PersonaDepartmentTable";
import GTMsegmentTable from "./GTMsegmentTable";
import ThresholdScoreTable from "./ThresholdScoreTable";
import IntentData from "./IntentData";
import Paper from '@mui/material/Paper';
import SimpleBackdrop from "./SimpleBackdrop";
import SimpleSnackbar from "./SimpleSnackbar";
import axios from "axios"
import { useAuthState, AuthProvider } from "@bamboobox/b2logincheck";
import { REACT_APP_API_URL, REACT_APP_API_URL2, REACT_APP_API_URL3, TENANTCONFIG_API } from "./../Constant";
import ShowFilter from './ShowFilter';
import { Button } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import SnackbarComponentWithContent from './SnackbarComponentWithContent';
import ProductTab from './ProductTab';
//import { Button, Dialog, DialogActions, DialogContent, DialogContentText, InputAdornment, TextField } from '@material-ui/core'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
             style={{ minHeight: '75vh !important' }}
        >
            {value === index && (
                 <Box style={{ marginBottom:'20px' }} >
                    <div style={{maxHeight:'400px'}}>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    // root: {
    //     flexGrow: 1,
    //     backgroundColor: theme.palette.background.paper,
    //   },
    
    
    root: {
        flexGrow:1,
        backgroundColor: theme.palette.background.paper,
        
    },
    colorDefault: {
        // backgroundColor: "#ffffff",
        // height: "48px",
        
        
        // boxShadow: 'none'
    },
    indicator: {
        background: "#016B40",
         borderRadius: "3px",  
        // backgroundColor: "#82B440",
        height: '4px',
        width:'10%'
    },
    button: {
        background: '#21DA8C',
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.4px',
      }
}));
//.MuiTab

const useStylesTabs = makeStyles((theme) => ({
    root: {
        maxWidth: "550px",
        minHeight: "31px",
        height: '100px'
    },
    wrapped: {
        fontSize: "0.875rem",
    }
}));

const icpLabel = "icpNameLabel"
// const icp = [
//     {
//         "name": "icp1"
//     },
//     {
//         "name": "icp2"
//     },
//     {
//         "name": "icp3"
//     },
//     {
//         "name": "icp4"
//     }
// ];

const EngagementTab = (props) => {
    console.log("rendering engagement page");
    const userData = useAuthState();
    const classes = useStyles();
    const classesTabs = useStylesTabs();
    const [rowId, setRowId] = useState(0);
    const [enable, setEnable] = useState(false);
    let icpname = props?.icpName; 
    const [isPublished, setIsPublished] = useState(props?.isPublished || false);
    //var icpname = "icp2";
    const [inputValue, setInputValue] = useState(icpname);
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [icp, setIcp] = useState([])
    const [backdropState, setBackdropState] = useState(false)
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        severity: "",
        message: ""
    })
    const [configMapper,setConfigMapper] = useState({});
    const [publishedIcpTypography , setPublishedIcpTypography] = useState(false);


    const getConfigMapper=async () => {
        try{
            fetch(`${TENANTCONFIG_API}/tenant/field/mapper/get/${userData.user.tenantId}/score-config-ui`,{ headers: { "Content-Type": "application/json" },credentials: "include"})
            .then((result) => {
                return result.json()
            })
            .then((data) =>{
                console.log("mapper Data",data)
                setConfigMapper(data);
            })
        }catch (error) {
            console.log("error",error )
        }  
    }

    useEffect(() => {
        getConfigMapper()
    }, [])

    // let isDirty = false;
    // const makeDirty = (flag) => {
    //     isDirty = flag;
    // }

    // let tempTabValue = 2;
    // const changeTab = () => {
    //     setValue(tempTabValue);
    // }

    const [isDirty,setIsDirty] = useState(false);
    const [tempTab,setTempTab] = useState(2);
    const [enableDialog,setEnableDialog] = useState(false);
    const [openDialog,setOpenDialog] = useState(false);
    const [snackbarWithContent,setSnackbarWithContent] = useState({
        open: false,
        severity: "",
        message: "",
        "content":""
    });
    // let enableDialog = false;
    // const dialogOnOff = (flag) => {
    //     enableDialog = flag;
    // }
    // let tempTabValue;
    // let tempTabValue = 0;
    // const changeTempTabValue = (val) => {
    //     tempTabValue = val;
    // }
    
    const handleTabChange = () => {
        setValue(tempTab);
    }


    const setIcpComboBox = async () => {
        try {
            await axios.get(REACT_APP_API_URL + '/scoredefinition/' + userData.user.tenantId, { withCredentials: true }).then((result) => {
                let icpListValues = []
                let pubIcp = [];

                result.data.forEach(icp => {
                    let icpListValuesObject = { "name": "" }
                    icpListValuesObject.name = icp.id.icpName
                    icpListValues.push(icpListValuesObject)
                    if(icp?.isPublished){
                        pubIcp.push(icp);
                    }


                });
                console.log('all pub icp ',pubIcp);
                if(pubIcp.length > 1){
                    setPublishedIcpTypography(false);
                }else {
                    setPublishedIcpTypography(true);
                }
                console.log(icpListValues)
                setIcp(icpListValues)
            })    
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setIcpComboBox()
    }, [])

    useEffect(()=>{
        console.log('rendering Engagement Tab');
    })

    const handleChange = (event, newValue) => {
        console.log(newValue);
        if(!isDirty){
            setValue(newValue);
        }else{
            setTempTab(newValue);
            setEnableDialog(true);
        }
        
    };

   

   

    // const handleChangeIndex = (index) => {
    //     console.log(index)
    //     setValue(index);
    // };

    const onBackClick = () => {
        props.history.push('/')
    };
    const icpNameValueFunc = (icpNameValue, type, rowId) => {
        console.log(icpNameValue);
        //setInputValue(icpNameValue)
        props.history.push('/EngagementTab/' + icpNameValue+'?icp='+icpNameValue)
    }

    const setSnackbarStateFalse = (snackbarStateFromBar) => {
        setSnackbarState(snackbarStateFromBar)
    }

    const snackbarCallbackToTab = (snackbarStateFromEngagementTabs) => {
        console.log(snackbarStateFromEngagementTabs)
        setSnackbarState(snackbarStateFromEngagementTabs)
    }

    const setBackdropStateFromChildComponent = (backdropStateFromChildComponent) => {
        console.log(backdropStateFromChildComponent)
        setBackdropState(backdropStateFromChildComponent)
    }

    const changeStatusOfIcp = async () => {
        try{
            if(props?.isPublished){
                setSnackbarWithContent({
                    open: true,
                    severity: "success",
                    message: `${icpname} Profile will be moved to draft shortly`,
                    "content": "Scoring will be paused for this profile"
                })
            }else{
                setSnackbarWithContent({
                    open: true,
                    severity: "success",
                    message: `${icpname} Profile will be Published Shortly`,
                    "content": "Scoring will be updated at end of day"
                })
            }
            await axios.put(REACT_APP_API_URL + '/definition/publishIcp/' + userData.user.tenantId + `/${icpname}/${!(props?.isPublished||false)}`, {},{ withCredentials: true }).then((result) => {
                if(result?.data === 'success'){
                    props.setIsPublished(!(props?.isPublished || false))
                }
            })
        }catch(e){
            console.log('error in icp unpub',e);
            if(props?.isPublished){
                setSnackbarWithContent({
                    open: true,
                    severity: "error",
                    message: `Error while ${icpname} Profile is being moved to draft`,
                    "content": "Please try again later"
                })
            }else{
                setSnackbarWithContent({
                    open: true,
                    severity: "error",
                    message: `Error while ${icpname} Profile is being Published`,
                    "content": "Try again later"
                })
            }
        }
            
    }

    const handleDialogClose = () => {
        setOpenDialog(false);
    }

    const handlePositiveFeed = () => {
            setOpenDialog(false);
            changeStatusOfIcp();
    }

    const onTogglePublish = async () => {
        if(props?.isPublished){
            setOpenDialog(true)
        }else{
            changeStatusOfIcp()            
        }
    }
    return (
        <>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',width: '100%'}}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center',width:'100%', padding:'16px 32px 24px' }}>
                <div>
                    <Typography variant="h5" style={{ fontWeight:'bolder',color: "#000",fontFamily:'Nunito Sans' }}>
                        {icpname}
                    </Typography>
                    <Typography variant="h6" style={{ color: "#000  ",fontFamily:'Nunito Sans' }}>
                        {props?.description || ""}
                    </Typography>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                    <Button
                        variant="outlined"
                        // color="primary"
                        className={classes.button}
                        style={{color:'#FF9393', borderRadius:'8px',border: "1px solid #FF9393"}}
                        onClick={onTogglePublish}
                        >
                        <Typography style={{fontFamily:'Nunito Sans'}}>{props?.isPublished?'MOVE TO DRAFT':'PUBLISH'}</Typography>
                    </Button>
                </div>
                

            </div>
        </div>
        <div className={classes.root} style={{height:'81vh', padding:'16px 32px 24px 32px', width: "98%",overflow:"scroll"}}>
            <SimpleBackdrop backdropState={backdropState} />
            <SimpleSnackbar setSnackbarStateFalse={setSnackbarStateFalse} snackbarState={snackbarState} />
            <SnackbarComponentWithContent setSnackbarStateFalse={setSnackbarWithContent} snackbarState={snackbarWithContent} />

           
                {/* <div style={{ marginLeft: "25px", marginBottom: "20px" }}><EngagementComboBox rowId={rowId} comboBoxData={icp} disable={enable} inputValue={inputValue} label={icpLabel} valueFunc={icpNameValueFunc} /></div> */}
            
            <div style={{width:'98%'}}>
                <AppBar  classes={{ root: classes.root, colorDefault: classes.colorDefault }} position="static" color="default" style={{boxShadow:'none'}}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        style={{ minHeight: "48px",paddingTop:'0px',boxShadow:'none',borderBottom:'1px solid #D3D3D3' }}
                        classesTabs={{ root: classesTabs.root }}
                        classes={{ indicator: classes.indicator }}
                        aria-label="simple tabs"
                    >
                        <Tab style={{ textTransform: "none",  minHeight: "48px", maxHeight: "48px" }} label='CONFIGURATION' {...a11yProps(0)} />
                        <Tab style={{ textTransform: "none",  minHeight: "48px", maxHeight: "48px" }} label="ENGAGEMENT" {...a11yProps(1)} />
                        <Tab style={{ textTransform: "none",  minHeight: "48px", maxHeight: "48px" }} label="PRODUCT" {...a11yProps(2)} />
                        <Tab style={{ textTransform: "none",  minHeight: "48px", maxHeight: "48px" }} label="INTENT" {...a11yProps(3)} />
                        <Tab style={{ textTransform: "none",  minHeight: "48px", maxHeight: "48px" }} label={`${configMapper.personalevel == undefined?"PERSONA LEVEL":configMapper.personalevel.label.toUpperCase()} X ${configMapper.department == undefined?"DEPARTMENT":configMapper.department.label.toUpperCase()}`} {...a11yProps(4)} />
                        <Tab style={{ textTransform: "none",  minHeight: "48px", maxHeight: "48px" }} label="BUYER GROUP" {...a11yProps(5)} />
                    </Tabs>
                </AppBar>
                </div>
                <div style={{ width:'98%', margin:'0px 25px 25px 0px',  paddingTop: '22px', height:'73vh', background: '#FFFFFF', borderRadius: '18px' }}>
                    <div>
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <ShowFilter icpName={icpname} description ={props?.description || ''} snackbarCallbackToTab={snackbarCallbackToTab} setBackdropStateFromChildComponent={setBackdropStateFromChildComponent} />
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <EngagementTypeTable setIsDirty={setIsDirty} enableDialog={enableDialog} setEnableDialog={setEnableDialog} changeTab={handleTabChange} icpname={icpname} snackbarCallbackToTab={snackbarCallbackToTab} setBackdropStateFromChildComponent={setBackdropStateFromChildComponent} />
                        </TabPanel>
                        <TabPanel value={value} index={2} dir={theme.direction} >
                            <ProductTab userData = {userData} icpname={icpname} publishedIcpTypography = {publishedIcpTypography}></ProductTab>
                        </TabPanel>
                        <TabPanel value={value} index={3} dir={theme.direction}>
                            <IntentData icpname={icpname} snackbarCallbackToTab={snackbarCallbackToTab} setBackdropStateFromChildComponent={setBackdropStateFromChildComponent} />
                        </TabPanel>
                        <TabPanel value={value} index={4} dir={theme.direction}>
                            <PersonaDepartmentTable icpname={icpname} snackbarCallbackToTab={snackbarCallbackToTab} setBackdropStateFromChildComponent={setBackdropStateFromChildComponent} configMapper={configMapper} />
                        </TabPanel>
                        <TabPanel value={value} index={5} dir={theme.direction}>
                            <GTMsegmentTable icpname={icpname} snackbarCallbackToTab={snackbarCallbackToTab} setBackdropStateFromChildComponent={setBackdropStateFromChildComponent} />
                        </TabPanel>
                    </div>
                </div>
                {/* </SwipeableViews> */}
                </div>
                    <Dialog
                        open={openDialog}
                        onClose={handleDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                               <Typography style={{fontFamily:'Nunito Sans',fontWeight:'800'}}>Are you sure you want to move "{`${icpname}`}" profile to draft?</Typography> 
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography style={{fontFamily:'Nunito Sans'}}>The profile will be paused until, it is published again.</Typography>
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button
                            className={classes.button}
                            onClick={handleDialogClose}
                            color="primary"
                            variant="text"
                        >
                            <Typography style={{fontFamily:'Nunito Sans', color:'#21DA8C'}}>CANCEL</Typography> 
                        </Button>
                        <Button
                            onClick={handlePositiveFeed}
                            variant="contained"
                            style={{backgroundColor:'#21DA8C'}}
                        >
                             <Typography style={{fontFamily:'Nunito Sans',color:'#ffffff'}}>MOVE TO DRAFT</Typography>
                        </Button>
                        
                        </DialogActions>
                    </Dialog>
            
          </>
    );
}

export default EngagementTab
