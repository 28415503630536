import React from "react";
import "./IntentData.css";
import IntentDataLevel from "./IntentDataLevel";
import IntentFilters from "./IntentFilters";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import axios from "axios";
import { useAuthState } from "@bamboobox/b2logincheck";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import "./../toast.css"
import Paper from '@mui/material/Paper';
// import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';

const Accordion1 = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius:`30px 30px 0px 0px`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const Accordion2 = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius:`0px 0px 30px 30px`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const high = "HIGH";
const medium = "MEDIUM";
const low = "LOW";
const intentProvider = "BOMBORA";

const IntentData = (props) => {
  
  toast.configure({
    autoClose: 6000,
    draggable: true,
    hideProgressBar: false,
    position: toast.POSITION.BOTTOM_LEFT,
    icon: false,
  });
  const userData = useAuthState();
  const tenantId = userData.user.tenantId;
  const [intentSelected, setintentSelected] = React.useState([]);
  const [highTopic, setHighTopic] = React.useState(undefined);
  const [mediumTopic, setMediumTopic] = React.useState(undefined);
  const [lowTopic, setLowTopic] = React.useState(undefined);

  const [selectedCountries,setSelectedCountries]=React.useState([]);
  const [selectedIndustries,setSelectedIndustries]=React.useState([]);
  const [selectedCompanySize,setSelectedCompanySize]=React.useState([]);

  //const [topicsChips, setTopicsChips] = React.useState(undefined)
  const [integrationStatus, setIntegrationStatus] = React.useState(undefined);
  const [showDropdownHigh, setShowDropdownHigh] = React.useState(false);
  const [showDropdownMed, setShowDropdownMed] = React.useState(false);
  const [showDropdownLow, setShowDropdownLow] = React.useState(false);
  const [showWeightages, setShowWeightages] = React.useState(true);
  const [showFilters, setShowFilters] = React.useState(true);
  const [expanded, setExpanded] = React.useState('weightages');
  const [expandedFilters, setExpandedFilters] = React.useState('filters');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangeFilters = (panel) => (event, newExpanded) => {
    setExpandedFilters(newExpanded ? panel : false);
  };

  React.useEffect(() => {
    if (showDropdownHigh) {
      if (showDropdownMed) {
        setShowDropdownMed(false);
      }

      if (showDropdownLow) {
        setShowDropdownLow(false);
      }
    }
  }, [showDropdownHigh]);

  React.useEffect(() => {
    if (showDropdownMed) {
      if (showDropdownHigh) {
        setShowDropdownHigh(false);
      }

      if (showDropdownLow) {
        setShowDropdownLow(false);
      }
    }
  }, [showDropdownMed]);

  React.useEffect(() => {
    if (showDropdownLow) {
      if (showDropdownMed) {
        setShowDropdownMed(false);
      }

      if (showDropdownHigh) {
        setShowDropdownHigh(false);
      }
    }
  }, [showDropdownLow]);

  const getIntentData = async () => {
    //get the data which is selected
    // try {
    //     await axios.get(REACT_APP_API_URL + '/scoredefinition/' + userData.user.tenantId, { withCredentials: true }).then((result) => {
    //     })
    // } catch (error) {
    //     console.log(error)
    // }
  };

  const getIntentlist = async () => {
    //get the data for the dropdown
    // try {
    //     await axios.get(REACT_APP_API_URL + '/scoredefinition/' + userData.user.tenantId, { withCredentials: true }).then((result) => {
    //         console.log(result.data)
    //     })
    // } catch (error) {
    //     console.log(error)
    // }
  };

  const getModifiedList = () => {
    //Here we have to change the high array, medium array and low array everytime so that will
    //affect the array which is going to each level and accordingly we can populate the data in drop down
  };

  const getIntegrationStatus = () => {
    try {
      axios
        .get(
          `${window._env_.B2_INTENT_BACKEND}/intent/test/tenant/integration/${tenantId}/${intentProvider}`,
          { withCredentials: true }
        )
        .then((result) => {
          console.log("status", result.data["success"]);
          setIntegrationStatus(result.data.success);
        });
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getIntegrationStatus();
    //This will run only once to know wheather the integration is already done or not
  }, [props.icpname]);

  // have to run on intial load and when any of the list changes
  React.useEffect(() => {
    try {
      axios
        .get(
          `${window._env_.B2_INTENT_BACKEND}/intent/config/get/${tenantId}/${props.icpname}`,
          { withCredentials: true }
        )
        .then((result) => {
          console.log("highMidLow", result.data.configs[high]);
          // setTopicsChips(result.data)
          setHighTopic(result.data.configs[high]);
          setMediumTopic(result.data.configs[medium]);
          setLowTopic(result.data.configs[low]);
        });
    } catch (error) {
      console.log(error);
    }
  }, [props.icpname]);

  React.useEffect(() => {
    if (!(Array.isArray(intentSelected) && !intentSelected.length))
      getIntentData();
    //This will run when we will update the selected item
  }, [intentSelected]);

  React.useEffect(() => {
    getIntentlist();
  }, [props.icpname]);

  const fetchFilters = () => {
    fetch(`${window._env_.GOLDENCOPY_BOMBORA}/intent/get-filters?icp=${props.icpname}&tenantId=${tenantId}`,{
      method: 'GET',
      credentials: 'include'
    })
    .then((response) => response.json())
    .then((result)=>{
      console.log("filtersResult",result);
      if(result.success===true){
        if(result?.data?.filters){
          const filters=result?.data?.filters
          console.log("filterslist",filters);
          filters.map((obj)=>{
            if(obj?.Id==='country' && obj?.Value && obj?.Value?.length>0){
              console.log("countrieslist",obj?.Value)
              // const countryValues=obj?.Value.map((obj)=>{
              //   return {label:obj}
              // })
              setSelectedCountries(obj?.Value)
            }
            if(obj?.Id==='ccm_company_size' && obj?.Value && obj?.Value?.length>0){
              setSelectedCompanySize(obj?.Value)
            }
            if(obj?.Id==='ccm_industry' && obj?.Value && obj?.Value?.length>0){
              setSelectedIndustries(obj?.Value)
            }
          })
        }
      }
    })
    .catch((error)=>{
      toast.error(<Alert severity="error">Failed to fetch Filters data</Alert>,{
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true
      });
      console.log("error", error);
    })
  }
  
  React.useEffect(()=>{
    if(props.icpname){
      fetchFilters()
    }  
  },[props.icpname])

  

  function updateConfigIcp() {
    let raw = {
      high: highTopic,
      low: lowTopic,
      medium: mediumTopic,
    };

    let requestOptions = {
      method: "POST",
      body: JSON.stringify(raw),
      headers: { "Content-Type": "application/json" },
      credentials: "include"
    };

    console.log("updateConfigIcp", requestOptions);
    // let bothUpdates=false;

    fetch(
      `${window._env_.B2_INTENT_BACKEND}/intent/config/save/${tenantId}/${props.icpname}`,requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result["success"] === true) {
          toast.success(<Alert severity="success">Topics Updated successfully</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
          // bothUpdates=true
        } else {
            toast.error(<Alert severity="error">Topics Update Unsuccessful</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
        }
      })
      .catch((error) => {
        toast.error(<Alert severity="error">Topics Update Unsuccessful</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
        console.log("error", error);
      });
    console.log("selectedvalues",selectedCountries,selectedIndustries,selectedCompanySize)  
    fetch(`${window._env_.GOLDENCOPY_BOMBORA}/intent/save-filters?icp=${props.icpname}&tenantId=${tenantId}`,{
      method: "POST",
      body: JSON.stringify({"filters": [
        {
            "Id": "ccm_industry",
            "Value": selectedIndustries
        },
        {
            "Id": "country",
            "Value": selectedCountries
        },
        {
            "Id": "ccm_company_size",
            "Value": selectedCompanySize
        }
    ]}),
      headers: { "Content-Type": "application/json" },
      credentials: "include"
    })
    .then((response) => response.json())
    .then((result)=>{
      if (result["success"] === true) {
        toast.success(<Alert severity="success">Filters Updated successfully</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
        
      } else {
          toast.error(<Alert severity="error">Filters Update Unsuccessful</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
      }
      console.log("save Filters response",result);
    })
    .catch((error) => {
      toast.error(<Alert severity="error">Filters Update Unsuccessful</Alert>,{
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true
      });
      console.log("error", error);
    });

  }
  const handleClickAway = () => {
    console.log("clicked away of the components");
    setShowDropdownHigh(false);
    setShowDropdownMed(false);
    setShowDropdownLow(false);
  };

  const [rotateChevron1, setRotateChevron1] = React.useState(false);
  const [rotateChevron2, setRotateChevron2] = React.useState(false);

    const handleRotate1 = () => {
      setRotateChevron1(!rotateChevron1);
      console.log("rotated")
    }

    const handleRotate2 = () => {
      setRotateChevron2(!rotateChevron2);
      console.log("rotated")
    }

    const rotate1 = rotateChevron1 ? "rotate(-90deg)" : "rotate(0)"
    const rotate2 = rotateChevron2 ? "rotate(-90deg)" : "rotate(0)"



  return highTopic === undefined &&
    mediumTopic === undefined &&
    lowTopic === undefined ? (
    <React.Fragment></React.Fragment>
  ) : (
    <Paper className="intent" elevation={3} sx={{borderRadius:"30px",marginBottom:"50px"}}>
    <Accordion1 expanded={expanded === 'weightages'} onChange={handleChange('weightages')}>
      <AccordionSummary>
        <div style={{display:"flex",justifyContent:"flex-start",flexDirection:"row",alignItems:"center",marginLeft: "20px"}}>
        <ExpandMoreIcon style={{ transform: rotate1, transition: "all 0.2s linear" }} onClick={handleRotate1} />
        <div className="columnHeader0">WEIGHTAGES</div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
      <div style={{width:"100%"}}>
      <div className="header">
        <div className="weightageTopic">
          <div className="columnHeader" style={{marginLeft:'20px'}}>Weightage</div>
          <div className="columnHeader"  style={{paddingLeft:'30%'}}>Topics</div>
        </div>
        <div className="notIntegrated">
          {integrationStatus || integrationStatus === undefined ? (
            <React.Fragment></React.Fragment>
          ) : (
            <React.Fragment>
              <IconButton aria-label="reportProblem">
                <ReportProblemIcon style={{ color: "#FF9800" }} />
              </IconButton>
              <u style={{ color: "#FF9800" }}>Intent provider is missing</u>
            </React.Fragment>
          )}
        </div>
      </div>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div style={{  height: "auto",paddingBottom:"30px" }}>
          <IntentDataLevel
            showDropdown={showDropdownHigh}
            setShowDropdown={setShowDropdownHigh}
            levelName={high}
            icpname={props.icpname}
            topicsChips={highTopic}
            otherSelectedTopics={[lowTopic, mediumTopic]}
            setTopicsChips={setHighTopic}
            intentProvider={intentProvider}
            integrationStatus={integrationStatus}
          />
          <IntentDataLevel
            showDropdown={showDropdownMed}
            setShowDropdown={setShowDropdownMed}
            levelName={medium}
            icpname={props.icpname}
            topicsChips={mediumTopic}
            otherSelectedTopics={[highTopic, lowTopic]}
            setTopicsChips={setMediumTopic}
            intentProvider={intentProvider}
            integrationStatus={integrationStatus}
          />
          <IntentDataLevel
            showDropdown={showDropdownLow}
            setShowDropdown={setShowDropdownLow}
            levelName={low}
            icpname={props.icpname}
            topicsChips={lowTopic}
            otherSelectedTopics={[highTopic, mediumTopic]}
            setTopicsChips={setLowTopic}
            intentProvider={intentProvider}
            integrationStatus={integrationStatus}
          />
        </div>
      </ClickAwayListener>
      </div>
      </AccordionDetails>
    </Accordion1>
    <Accordion2 expanded={expandedFilters === 'filters'} onChange={handleChangeFilters('filters')} >
      <AccordionSummary>
      <div style={{display:"flex",justifyContent:"flex-start",flexDirection:"row",alignItems:"center",marginLeft: "20px"}}>
      <ExpandMoreIcon style={{ transform: rotate2, transition: "all 0.2s linear" }} onClick={handleRotate2} />
        <div className="columnHeader0">FIRMOGRAPHIC INTENT FILTERS</div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
      <div style={{width:"100%",marginBottom:"60px"}}>
      <IntentFilters
          selectedCountries={selectedCountries}
          setSelectedCountries={setSelectedCountries}
          selectedIndustries={selectedIndustries}
          setSelectedIndustries={setSelectedIndustries}
          selectedCompanySize={selectedCompanySize}
          setSelectedCompanySize={setSelectedCompanySize}
          />
      </div>
     
      </AccordionDetails>
    </Accordion2>
      {/* <ToastContainer /> */}
      <div>
      <Button
            variant="contained"
            //classes={{ contained: classes.contained }}
            //disabled={buttonDisable}
            size="middle"
            // style={{
            //   float: "right",
            //   marginTop: "4%",
            //   marginBottom: "1%",
            //   marginRight: "4%",
            //   textTransform: "none",
            //   borderRadius: "4px",
            // }}
            startIcon={<CheckIcon />}
            style={{
              float: "right",
              margin:'1% 65px',
              marginTop:'2%',
              borderRadius: "4px",
              background: '#21DA8C',
              borderRadius: '18px',
              fontWeight: '700',
              fontSize: '14px',
              lineHeight: '24px',
              position:"fixed",

              right:0,
              bottom:'20px',

              /* identical to box height, or 171% */
          
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
          
              /* primary / contrastText */
          
              color: '#FFFFFF'
            }}
            // className="button"
            onClick={() => updateConfigIcp()}
          >
            UPDATE
          </Button>
          </div>  
    </Paper>
  );
};

export default IntentData;
