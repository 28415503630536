import React , {useState , useEffect } from "react"
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Icon from '@material-ui/core/Icon';
import './FilterCriteria.css'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    textField:{
        height:'38px',
    },
    head:{
        width:'36ch',
        marginRight:'60px',
        fontWeight: '700',
        fontSize: '11px',
        lineHeight: '6px',
        letterSpacing: '0.06em',
        textTransform: 'uppercase',
        color: '#595959'
        
    }
}));

const FilterCriteriaComp = (props)=>{
    console.log("12345-filter",props.filterProps?.icpFilter)
    console.log("123-filter", props.filterProps?.icpFilter[0]);
    const filterData = props.filterProps?.icpFilter[0];
    const [ attribute , setAttribute] = useState();
    const [relation , setRelation] = useState();
    const [value , setValue] = useState();

    useEffect(()=>{
        setAttribute(filterData?.name)
        setRelation(filterData?.relation)
        setValue(filterData?.value)
    },[props?.filterProps?.filterName])

    const handleClickOpen = () => {
        props.setOpenCondition(true);
    };

    

    const classes = useStyles();

    return(
        <React.Fragment>
            <div>
                <div style={{ display :"flex" ,flexDirection :"row" , justifyContent: "space-between",background : 'linear-gradient(0deg, rgba(55, 234, 158, 0.08), rgba(55, 234, 158, 0.08)), #FFFFFF',height:'48px',alignItems: 'center',margin:'10px 0px'}}>
                <div style=
                {{
                    padding :"2.5%",
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '13px',
                    lineHeight: '16px',
                    /* identical to box height */
                    letterSpacing: '0.06em',
                    textTransform: 'uppercase',
                    color: '#595959'
                
                }}
                >Filter Criteria (Step 1 of 2)</div>
                    {props?.filterProps?.filterName!=='default'&&<div style={{padding :"2%"}}><Button startIcon={<Icon>add</Icon>} style={{backgroundColor: "#21DA8C" , borderRadius: "18px", color:"white",width: "170px", height:'32px',marginRight:'17px'}}  onClick={handleClickOpen}>New Condition</Button></div>}
                </div>
                {props?.filterProps?.filterName!=='default'&&<div style={{display :"flex" , flexDirection :"row",margin:'20px 0px' }}>
                    <div className={classes.head} style={{marginLeft :'40px'}}>Attribute</div>
                    <div className={classes.head} >Relation</div>
                    <div className={classes.head} >Value</div>
                </div>}
                <div style={{ display :"flex" , flexDirection :"column"}}>
                    {props?.filterProps?.icpFilter.map((filter)=>{
                        return(
                       <div>
                       <Box
                           component="form"
                           sx={{
                               '& > :not(style)': { m: '8px 30px 8px 30px',width:'25ch'},
                           }}
                           noValidate
                           autoComplete="off"
                           >
                           <TextField className={classes.textField} className='textField' value={filter?.name}/>
                           <TextField className={classes.textField} className='textField' value={filter?.relation}/>
                           <TextField className={classes.textField} className='textField' value={filter?.value}/> 
                       </Box>
                       </div>)

                    })
                   }
                
                </div>
            </div>
        </React.Fragment>
    )
}

export default FilterCriteriaComp