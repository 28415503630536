import {  IconButton, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import "./ExportFilterCriteriaSectionField.css";
import EngagementTabValuesField from "./EngagementTabValuesField";
//import getTenantConfigJSON from "./getTenantConfigJson";
import { makeStyles } from "@material-ui/styles";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Button from "@material-ui/core/Button";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from "@material-ui/core";




const useStyles = makeStyles({
  customTextField: {
    "& input::label": {
      fontStyle: "italic"
    }
  },
  root:{
    '& .MuiInputBase-root': {
        borderRadius:"8px",
        height:"36px",
        padding: "1px 0px !important"
      }
},
button:{
  width: "70px",
  height: "42px",
  borderRadius: "8px",
  boxShadow: "0px 4px 4px 0px #0000001F",
  background: "#00A5A7",
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "19px",
  letterSpacing: "0em",
  textTransform:"none",
  '&:hover': {
    background: "#00A5A7",
    color: "#FFFFFF", // Disabling the hover effect
  },
  color: "#FFFFFF",
  } 
});






const EngagementTabAddFieldComponent = (props) => {
  
  console.log("props in section field", props);
 
  const classes = useStyles();  
  const [relationArray,setRelationsArray] = useState([])
  const [attributeArray,setAttributeArray] = useState([])
  const [attributeType,setAttributeType] = useState("")
  const [isOpen, setIsOpen] = useState(false);


  const setDynamicAttributes=() => {

    let activityAttributesConfig = props?.attributeConfig[props?.activityType?.toLowerCase()]
    console.log("activityAttributesConfig44",activityAttributesConfig)
    const filteredAttributes = activityAttributesConfig ?  Object.keys(activityAttributesConfig) : []

    setAttributeArray(filteredAttributes)
  }
  
  const deepEqual=(obj1, obj2) =>{
    if (obj1 === obj2) return true;
  
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;
  
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
  
    if (keys1.length !== keys2.length) return false;
  
    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
    }
  
    return true;
  }

  const  nullCheck = (obj) =>{
      console.log("nullCheck69",obj)
      debugger
      if(obj&&obj?.relation?.toLowerCase()!=='between')
      {
        return !(obj?.attribute?.trim()?.length&&obj?.relation?.trim()?.length&&obj?.value?.trim()?.length&&obj?.multiplier?.trim()?.length)
      }
      else{
        return !(obj?.attribute?.trim()?.length&&obj?.relation?.trim()?.length&&obj?.value[0]?.trim()?.length&&obj?.value[1]?.trim()?.length&&obj?.multiplier?.trim()?.length)
      }
  }
   
  const ifChangedInScoringAttributes = (needNullCheck) => {
       try{
      
          let initialActivityRule = props?.initialData[props?.index]?.activityRule[props?.ruleIndex]
          let newActivityRule = props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]
          let isNull = nullCheck(newActivityRule)
          let equalOrNot
          if(!initialActivityRule&&!newActivityRule&&props?.initialData[props?.index]?.activityRule?.length!==props?.rows?.engagementConfig[props?.index]?.activityRule?.length)
          {

            props.setButtonDisable(false);

          }
          else{
          if(needNullCheck){
          equalOrNot = deepEqual(initialActivityRule,newActivityRule)
        }
        else{
          equalOrNot = deepEqual(initialActivityRule,newActivityRule) || isNull
        }

          props.setButtonDisable(equalOrNot);
       }
      }
       catch(e){
        console.log("Error in ifChangedInScoringAttributes",e);
        return true;
       }
        
  } 

  const onChangeAttribute = (newValue) => {
    console.log("value180",newValue)

   // set new value in particular index and get relation array based on that and then set the relation array
      let rowConfig = {...props.rows}
      setAttributeType(props?.attributeConfig[props?.activityType?.toLowerCase()][newValue]?.type)
      setRelationsArray(props?.relationConfig[props?.attributeConfig[props?.activityType?.toLowerCase()][newValue]?.type]?.operator)
      rowConfig.engagementConfig[props?.index].activityRule[props?.ruleIndex] = {}
      rowConfig.engagementConfig[props?.index].activityRule[props?.ruleIndex].attribute = newValue
      props.setRows(rowConfig)
      ifChangedInScoringAttributes()
  };

  const onChangeRelation = (newValue) => {
    // set current index value to null first 
    let rowConfig = {...props.rows}
      let attribute = rowConfig.engagementConfig[props?.index].activityRule[props?.ruleIndex]?.attribute
      rowConfig.engagementConfig[props?.index].activityRule[props?.ruleIndex] = {}
      rowConfig.engagementConfig[props?.index].activityRule[props?.ruleIndex].attribute = attribute
      rowConfig.engagementConfig[props?.index].activityRule[props?.ruleIndex].relation = newValue
      props.setRows(rowConfig)
      ifChangedInScoringAttributes()
      // check if enable 
  };

 

  const isDisabledClose = () => {

     };


  const onClickClose = () => {
      let rowConfig = {...props?.rows};
      if(rowConfig.engagementConfig[props.index].activityRule.length<=1)
         {
            rowConfig.engagementConfig[props.index].activityRule.splice(props.ruleIndex,1)
            let newObj = {attribute:'',relation:'',value:'',multiplier:''}
            if(rowConfig.engagementConfig[props.index].activityRule[props?.ruleIndex]?.relation?.toLowerCase() === 'between'){
              newObj.value = ['',''];
            }
            rowConfig.engagementConfig[props.index].activityRule?.push(newObj)
          }
       else{
        rowConfig.engagementConfig[props.index].activityRule?.splice(props.ruleIndex,1)
       }
      props.setRows(rowConfig)
      ifChangedInScoringAttributes(true)
      // check if enable 
  };

  console.log("OnClickClose232",props.rows)

  useEffect(()=>{
   // fetchRelationType(props.filterAttribute.attribute);
   // set attribute array for particular activityType
   setDynamicAttributes()
   let currentAttribute  = props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]?.attribute || null
  
  // console.log("currentAttribute", currentAttribute,props?.attributeConfig[props?.activityType?.toLowerCase()][currentAttribute]) 
  console.log("currentAttribute141",props)
  if(currentAttribute && props?.attributeConfig[props?.activityType?.toLowerCase()] && props?.attributeConfig[props?.activityType?.toLowerCase()][currentAttribute])
  setRelationsArray(props?.relationConfig[props?.attributeConfig[props?.activityType?.toLowerCase()][currentAttribute]?.type]?.operator)
},[])


const getFilterAttribute = () => {   
  return props?.rows?.engagementConfig[props.index]?.activityRule[props?.ruleIndex]?.attribute || null;
}

const getFilterRelation = () => {
   // if any this already selected
   return props?.rows?.engagementConfig[props.index]?.activityRule[props?.ruleIndex]?.relation || null
  
  
}

const openDialog = () =>{
  setIsOpen(true)

}
const handleSave = () => {
  onClickClose();
  setIsOpen(false)
};

const handleClose = () => {
  setIsOpen(false)
};




  return (
    <div className="export-filter-crieria-section-and-field">
      <div className="export-filter-crieria-section-and-field-elements">
        <Autocomplete
          disableClearable
          className={classes.root}
            //value={getAttributeValue(props.filterAttribute?.attribute)}
            // value={props?.filterAttribute?.attribute === undefined? null:( props?.filterAttribute?.attribute? props?.filterAttribute?.attribute: attribute)}
            value = {
                getFilterAttribute()}
            onChange={(event, newValue) => {
              onChangeAttribute(newValue);
            }}
            autoSelect
            style={{ minWidth: "175px", marginRight: "32px" , borderRadius:"16px"}}
  
            options={attributeArray || []}//{title,labdl} 
        
            
            popupIcon={<KeyboardArrowDownRoundedIcon style={{ color: "rgba(3, 3, 3, 0.6)" }} />}
         renderInput={(params) => (
            <TextField {...params} placeholder="Attribute" variant="outlined" />
          )}
        />
      
            {props?.rows?.engagementConfig[props.index]?.activityRule[props?.ruleIndex]?.attribute?.trim() &&<Autocomplete
            disableClearable
            className={classes.root}
              value = {
                getFilterRelation()}
              onChange={(event, newValue) => {
                onChangeRelation(newValue);
              }}
              autoSelect
              style={{ minWidth: "175px", marginRight: "32px" , borderRadius:"16px"}}
    
              options={relationArray || []}
              
              
              popupIcon={<KeyboardArrowDownRoundedIcon style={{ color: "rgba(3, 3, 3, 0.6)" }} />}
             renderInput={(params) => (
                <TextField {...params} placeholder="Relation" variant="outlined" />
              )}
            />}
           {
            props?.rows?.engagementConfig[props.index]?.activityRule[props?.ruleIndex]?.relation?.trim() && 
            <EngagementTabValuesField
              key = {props.ruleIndex}
              rows = {props.rows}
              setRows = {props.setRows}
              index = {props.index}
              ruleIndex = {props.ruleIndex}
              ifChangedInScoringAttributes = {ifChangedInScoringAttributes}
              attributeType = {attributeType}
              setErrorDisable = {props.setErrorDisable}
              setButtonDisable = {props.setButtonDisable}
              
            />
            }

      </div>
      <IconButton
        onClick={() => {
          openDialog();
        }}
        style={{
        backgroundColor: "white",color: "#939393",
        }}
        disabled={isDisabledClose()}
        >
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5V0.5C18.6274 0.5 24 5.87258 24 12.5V12.5C24 19.1274 18.6274 24.5 12 24.5V24.5C5.37258 24.5 0 19.1274 0 12.5V12.5Z" fill="#ffffff"/>
<path d="M19 6.91L17.59 5.5L12 11.09L6.41 5.5L5 6.91L10.59 12.5L5 18.09L6.41 19.5L12 13.91L17.59 19.5L19 18.09L13.41 12.5L19 6.91Z" fill="#939393"/>
</svg>

      </IconButton>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{borderRadius:"8px"}}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you want to remove this rule?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{justifyContent:"space-evenly"}}>
        <Button
            onClick={handleClose}
            style={{ 
              color: '#ffffff',
              background: '#004B6B'
            }}
            autoFocus
          >
            No
          </Button>
          <Button
            onClick={handleSave}
           
            style={{ 
              color: '#004B6B',
              border: '1px solid #004B6B'
            }}
           
          >
            Yes
          </Button>
          
        </DialogActions>
      </Dialog>
    </div>
  );
  
};

export default EngagementTabAddFieldComponent;
