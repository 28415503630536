import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import _uniqueId from 'lodash/uniqueId';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import SimpleTextField from "./SimpleTextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import "./MulitSelectBoxForNumeric.css";

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    }, 
    root: {
      border: "none",
    },
    contained: {
      backgroundColor: "primary",
    },
    TouchRipple: {
      backGroundColor: "#82B440",
    },
    chips:{
      background:'#000000'
    }
  });
  
const groupSizeLabel = "Group Size";
const closeonSel = true;

const MultiSelectBox = (props) => {
    const classes = useStyles();
    const state = props?.state;
    const [newMinVal, setNewMinVal] = useState(state?.minVal || 0)
    const [newMaxVal, setNewMaxVal] = useState(state?.maxVal || 1)
    const [newGrpSizeVal, setNewGrpSizeVal] = useState(state?.groupSize || 1)
    const [minError,setMinError] = useState(false)
    const [maxError,setMaxError] = useState(false)
    const [grpSizeError,setGrpSizeError] = useState(false)
    const [minErrorHelpMsg, setMinErrorHelpMsg] = useState("")
    const [maxErrorHelpMsg, setMaxErrorHelpMsg] = useState("")
    const [grpSizeHelpMsg, setGrpSizeHelpMsg] = useState("")

    if(state.type==="" || state.type==="Numerical"){
      props.state.selectedValues = []
    };
    console.log("line no 48", state)


    const validMinRange = (stateRecord,currMinVal) => {
      if(currMinVal<0){
        setMinErrorHelpMsg("negative value")
        return false
      }
      console.log("valid range check - ",currMinVal,stateRecord.maxVal)
      if(stateRecord.maxVal!==0 && stateRecord.maxVal<=currMinVal){
        setMinErrorHelpMsg("greater than Maximum")
        console.log("valid range - max lesser than min")
        return false
      }
      let ranges = [];
      let i = 0;
      props.statesToSubmit.map((state) => {
        if (state.rowId !== stateRecord.rowId) {
          ranges.push([state.minVal,state.maxVal]);
        }
      });
      for(i=1;i<ranges.length;i++){
        if((ranges[i][0]<currMinVal && currMinVal<ranges[i][1]) || (currMinVal==ranges[i][0] || currMinVal==ranges[i][1])){
          setMinErrorHelpMsg("value included in other ranges")
          console.log("valid range",ranges[i][0],ranges[i][1],"checking for",currMinVal)
          return false;
        }
        else if(currMinVal<ranges[i][0]){
          if(stateRecord.maxVal!==0 && stateRecord.maxVal>ranges[i][0]){
            setMinErrorHelpMsg("value included in other ranges")
            return false
          }
        }
        else if(currMinVal>ranges[i][1]){
          if(stateRecord.maxVal!==0 && stateRecord.maxVal<ranges[i][1]){
            setMinErrorHelpMsg("value included in other ranges")
            return false
          }
        }
        else{
          console.log("valid range in else")
        }
      }
      setMinErrorHelpMsg("")
      return true;
    }

    const validMaxRange = (stateRecord,currMaxVal) => {
      if(currMaxVal==0){
        setMaxErrorHelpMsg("greater than zero")
        return false
      }
      if(currMaxVal<0){
        setMaxErrorHelpMsg("negative value")
        return false
      }
      console.log("valid range check")
      if(stateRecord.minVal!==0 && currMaxVal<=stateRecord.minVal){
        console.log("testingg",currMaxVal,stateRecord.minVal)
        setMaxErrorHelpMsg("lesser than Minimum")
        console.log("valid range - max lesser than min")
        return false
      }
      let ranges = [];
      let i = 0;
      props.statesToSubmit.map((state) => {
        if (state.rowId !== stateRecord.rowId) {
          ranges.push([state.minVal,state.maxVal]);
        }
      });
      console.log("valid range - ranges",ranges)
      for(i=1;i<ranges.length;i++){
        if((ranges[i][0]<currMaxVal && currMaxVal<ranges[i][1]) || (currMaxVal==ranges[i][0] || currMaxVal==ranges[i][1])){
          setMaxErrorHelpMsg("value included in other ranges")
          console.log("valid range",ranges[i][0],ranges[i][1],"checking for",stateRecord.minVal,currMaxVal)
          return false;
        }
        else if(currMaxVal<ranges[i][0]){
          if(stateRecord.minVal!==0 && stateRecord.minVal>ranges[i][0]){
            setMaxErrorHelpMsg("value included in other ranges")
            return false
          }
        }
        else if(currMaxVal>ranges[i][1]){
          if(stateRecord.minVal!==0 && stateRecord.minVal<ranges[i][1]){
            setMaxErrorHelpMsg("value included in other ranges")
            return false
          }
        }
        else{
          console.log("valid range in else")
        }
      }
      setMaxErrorHelpMsg("")
      return true;
    }

    const validityChange = (value) => {
      props.statesToSubmitValidity.map((validityState) => {
        if(validityState.rowId === state.rowId){
          validityState.validity = value
        }
      })
      console.log("valid range - props.statesToSubmitValidity",props.statesToSubmitValidity)
    }
    const onValChange = (val,key) =>{
      if(key=="min"){
        setNewMinVal(val)
      }
      else if(key=="max"){
        setNewMaxVal(val)
      }
      else{
        setNewGrpSizeVal(val)
      }
    }
    const onMinChange = () => {
      let max=parseInt(newMaxVal)
      let min=parseInt(newMinVal)
      let maxFlag = true
      let minFlag = true
      if(maxError && minFlag && maxFlag){
        if(newMinVal<newMaxVal){
          state.maxVal=newMaxVal
          validityChange(true)
          setMaxError(false)
        }
      }
      if(validMinRange(state,min)){
        state.minVal = min;
        validityChange(true)
        setMinError(false)
        console.log("valid range - line no 60",state.minVal,minError)
      }
      else{
        validityChange(false)
        setMinError(true)
      }
      props.onMinValueUpdated(state);
    };
    const onMaxChange = () => {
      let max=parseInt(newMaxVal)
      let min=parseInt(newMinVal)
      let maxFlag = true
      let minFlag = true
      maxFlag = validMaxRange(state,max)
      minFlag = validMinRange(state,min)
      if(minError && minFlag && maxFlag){
        if(min<max){
          state.minVal=newMinVal
          validityChange(true)
          setMinError(false)
        }
      }

      if(maxFlag){
        state.maxVal = max;
        validityChange(true)
        setMaxError(false)
        console.log("valid range - line no 71",state.maxVal,maxError)
      }
      else{
        validityChange(false)
        setMaxError(true)
      }
      props.onMaxValueUpdated(state);
    };
    const onGrpSizeChange = () => {
      if(newGrpSizeVal>0){
        state.groupSize = newGrpSizeVal;
        props.onGroupSizeUpdated(state);
        setGrpSizeHelpMsg(" ")
        setGrpSizeError(false)
        validityChange(true)
      }
      else{
        setGrpSizeHelpMsg("greater than zero")
        setGrpSizeError(true)
        validityChange(false)
      }
    }

  const onDelete =() =>{
      let boolIsDefault = false;
      state?.selectedValues.map((vals) => {
        if(vals.value === 'default')
        {
            boolIsDefault = true;
        }
      });
      if(!boolIsDefault){          
        props.onDeleted(state);
      }
  } 
    return (
        <TableRow>
            <TableCell classes={{ root: classes.root }} align="left" >
                <div className="range" style={{display:"flex",justifyContent:"space-between"}}>
                <TextField
                    rowId={state?.rowId}
                    label="Minimum"
                    margin="dense"
                    variant="outlined"
                    value={newMinVal}
                    placeholder={newMinVal?.toString()}
                    error={minError}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}      
                    type="number"
                    onChange={(event) => { onValChange(event.target.value,"min") }}
                    onBlur={onMinChange}
                    helperText={minError?minErrorHelpMsg:" "}
                    style={{width:"45%"}}

                /> 
                <div style={{paddingTop:"8px"}}>-</div>
                <TextField
                    rowId={state?.rowId}
                    label="Maximum"
                    margin="dense"
                    variant="outlined"
                    error={maxError}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    type="number"
                    value={newMaxVal}
                    placeholder={newMaxVal?.toString()}
                    onChange={(event) => { onValChange(event.target.value,"max") }}
                    onBlur={onMaxChange}
                    helperText={maxError?maxErrorHelpMsg:" "}
                    style={{width:"45%"}}
                 />
                                
                </div>
                
                </TableCell>
                <TableCell classes={{ root: classes.root }} align="left" style={{width:"20%",padding:'5px 16px 16px 16px !important'}}>
                    {/* <SimpleTextField rowId={state?.rowId} label={groupSizeLabel}
                      inputValue={state?.groupSize} valueFunc={gtmValueFunc} /> */}
                      <TextField
                        rowId={state?.rowId}
                        margin="dense"
                        variant="outlined"
                        value={newGrpSizeVal}
                        placeholder={newGrpSizeVal}
                        error={grpSizeError}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }}      
                        type="number"
                        onChange={(event) => { onValChange(event.target.value,"grpSize") }}
                        onBlur={onGrpSizeChange}
                        helperText={grpSizeError?grpSizeHelpMsg:" "}

                    /> 
                </TableCell>
                <TableCell
                      classes={{ root: classes.root }}
                      align="left"
                      margin="-50%"
                    >
                      <IconButton
                        aria-label="delete"
                        className={classes.margin}
                        onClick={onDelete}
                        disable = {state.defaultVal}
                      >
                        <DeleteIcon style={{ color: "#939393" }} />
                      </IconButton>
                    </TableCell>
        </TableRow>
    )
}

export default MultiSelectBox