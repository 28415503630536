import React, { useEffect } from 'react'
import Checkbox from '@material-ui/core/Checkbox';
import RatingLevelComboBox from "./RatingLevelComboBox";
import _uniqueId from 'lodash/uniqueId';
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import { useAuthState } from "@bamboobox/b2logincheck"
import { REACT_APP_API_URL3,TENANTCONFIG_API } from "./../Constant";

const defaultRating = 0;
const importanceLabel = "Importance"
const importanceLevel = {
    "level": [
        {
            "levelName": "Very High",
            "levelRating": 4
        },
        {
            "levelName": "High",
            "levelRating": 3
        },
        {
            "levelName": "Medium",
            "levelRating": 2
        },
        {
            "levelName": "Low",
            "levelRating": 1
        },
        {
            "levelName": "Very Low",
            "levelRating": 0
        }
    ]
};

const SimpleCheckbox = (props) => {
    const [selectedAttributes, setSelectedAttributes] = React.useState([]);
    const [resultLabel, setResultLabel] = React.useState({});
    const [attributeData, setAttributeData] = React.useState([]);//set initial state to []
    const [selected, setSelected] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [id] = React.useState(_uniqueId('check-box-demo-'));
    const [divId] = React.useState(_uniqueId('div-box-demo-'));
    const weightage = 0;
    const userData = useAuthState()
    const tenantId = userData.user.tenantId;
    // const CheckboxWithGreenCheck = withStyles({
    //     root: {
    //       "&$checked": {
    //         "& .MuiIconButton-label": {
    //           position: "relative",
    //           zIndex: 0
    //         },
    //         "& .MuiIconButton-label:after": {
    //           content: '""',
    //           left: 4,
    //           top: 4,
    //           height: 15,
    //           width: 15,
    //           position: "absolute",
    //           backgroundColor: "black",
    //           borderColor: "pink",
    //           color:"white",
    //           zIndex: -1
    //         }
    //       }
    //     },
    //     checked: {}
    //   })(Checkbox);
      
    const getAttributeData = async () => {
        try {
            //get request for the attribute data from nitin
            await axios.get(REACT_APP_API_URL3 + '/' + userData.user.tenantId + '/describe.json', { withCredentials: true }).then((result) => {

                var list = [];

                if (result.status === 200) {
                    console.log('inside if condition')
                    var json = JSON.parse(JSON.stringify(result.data));

                    //checking the size of json
                    console.log(json.length);

                    for (let i = 0; i < json.length; i++) {

                        var obj =
                        {
                            name: "",
                            type: "",
                            position: "",
                            hierarchy: ""
                        };

                        if (json[i].ICP === 'yes' && json[i].name && (json[i]?.position < 2 || json[i]?.position === undefined)) {
                            //Here assign the object value into new object.

                            obj.name = json[i].name;
                            obj.type = json[i].type

                            if (json[i].type === 'hierarchical') {
                                obj.position = json[i].position;
                                obj.hierarchy = json[i].hierarchy;
                            }

                            list.push(obj);

                        }

                    }
                }
                console.log("list",list);
                let len = list.length;
                console.log(list);
                for (let i = 0; i < len; i++) {
                    list[i] = Object.assign(list[i], {
                        status: true
                    });
                }
                console.log(list);
                //setAttributeData([...rowsData]);

                console.log("hello set", list);
                setAttributeData(list);
                setTotal(list.length)
            });

        } catch (err) {
            console.error(err.message);
        }
    }

    const getAttributeLabel = async () => {
        await axios.get(`${TENANTCONFIG_API}/tenant/field/mapper/get/${tenantId}/score-config-ui`, { withCredentials: true }).then((result) => setResultLabel(result.data))
    } 
    useEffect(() => {
        getAttributeData();
        getAttributeLabel();
    }, []);

    const setStatusTrue = (name) => {
        let rowsData = [...attributeData];
        var indexOfSelectedAttribute = rowsData.findIndex(function (i) {
            return i.name === name;
        });
        console.log(indexOfSelectedAttribute);
        rowsData[indexOfSelectedAttribute].status = true;
        setAttributeData([...rowsData]);
    }

    const setStatusFalse = (name) => {
        let rowsData = [...attributeData];
        var indexOfSelectedAttribute = rowsData.findIndex(function (i) {
            return i.name === name;
        });
        console.log(indexOfSelectedAttribute);
        rowsData[indexOfSelectedAttribute].status = false;
        setAttributeData([...rowsData]);
    }


    const checkboxValueFunc = (levelRating, label, rowId) => {
        console.log(levelRating)
        let rowsData = [...attributeData];
        var indexOfSelectedAttribute = rowsData.findIndex(function (i) {
            return i.name === rowId;
        });
        let attibuteToBeAdded = {
            "recordId": "",
            "type": rowsData[indexOfSelectedAttribute].type,
            "name": rowId,
            "subtype": "",
            "comments": "",
            "hierarchy": rowsData[indexOfSelectedAttribute].hierarchy,
            "position": rowsData[indexOfSelectedAttribute].position,
            "attributeWeightage": levelRating,
            "parameters": []
        }

        props.updateAttribute(attibuteToBeAdded, rowId);
    }

    const unCheckBox = (name) => {
        props.updateUncheckAttribute(name)
    }

    const increaseSelectedAttributes = () => {
        setSelected(selected + 1);
        
    }

    const decreaseSelectedAttributes = () => {
        setSelected(selected - 1);
    }

    return (
        <React.Fragment>
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '85%', alignItems: 'center', paddingLeft: '2%' }} >
                    <h4 style={{ width: '110px' , fontSize: '14px', lineHeight: '22px', fontWeight: 'bold' }}>Attribute Name</h4>
                    <h4 style={{ width: '140px', fontSize: '14px', lineHeight: '22px', fontWeight: 'bold', marginRight: "10px" }}>Attribute Weightage</h4>
                </div>
               {/* <p style={{ width: '110px', paddingLeft: '40px' }}>selected {selected} of {total}</p> */}
            </div>
            <div style={{ height: "300px", overflow: "auto", width: '560px' }}>{
                attributeData.map((element, index) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                            color="primary"
                            key={index}
                            id={id}
                            checked={selectedAttributes.includes(element?.name)}
                            onChange={e => {
                                if (e.target.checked) {
                                    setSelectedAttributes([...selectedAttributes, element?.name]);
                                    increaseSelectedAttributes();
                                    setStatusFalse(element.name);
                                    checkboxValueFunc(defaultRating, importanceLabel, element.name)

                                } else {
                                    const indexOfAttr = selectedAttributes.indexOf(element?.name);
                                    const newAttrs = [...selectedAttributes];
                                    newAttrs.splice(indexOfAttr, 1);
                                    setSelectedAttributes(newAttrs);
                                    decreaseSelectedAttributes();
                                    setStatusTrue(element.name);
                                    unCheckBox(element.name)

                                }
                            }}
                            value="checked"
                            inputProps={{
                                'aria-label': 'primary checkbox',
                            }}
                            label="Include Child Attribute"
                        />

                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '85%', alignItems: 'center' }} key={divId}>
                            <p>{resultLabel[element.name].label}</p>
                            <RatingLevelComboBox rowId={element.name} disable={element.status} comboBoxData={importanceLevel.level} label={importanceLabel} inputValue={weightage} valueFunc={checkboxValueFunc} />
                        </div>

                    </div>
                ))
            }</div>
            {/* <p style={{font:"Montserrat",fontSize:"14px"}}>Selected {selected} of {total} Attributes</p> */}
            
        </React.Fragment>
    )
}

export default SimpleCheckbox

// const [selectedAttributes, setSelectedAttributes] = useState([]);







// {
//                        attributeData.map((element, index) => (
//                            <div style={{ display: 'flex', alignItems: 'center' }}>
//                              <Checkbox
//                                checked={selectedAttributes.includes(element?.id)}
//                                onChange={e=> {
//                                    if(e.target.checked) {
//                                        setSelectedAttributes([...selectedAttributes, element?.id]);
//                                    } else {
//                                        const indexOfAttr = selectedAttributes.indexOf(element?.id);
//                                        const newAttrs = [...selectedAttributes];
//                                        newAttrs.splice(indexOfAttr, 1);
//                                        setSelectedAttributes(newAttrs);
//                                    }
//                                }}
//                                value="checked"
//                                inputProps={{
//                                    'aria-label': 'primary checkbox',
//                                }}
//                                label="Include Child Attribute"
//                            />
//                             <p>{element.parent_value}</p>
//                            </div> 
//                        ))
//                    }