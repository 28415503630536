import React, { useState } from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import _uniqueId from 'lodash/uniqueId';
import axios from "axios";
import { useAuthState } from "@bamboobox/b2logincheck"
import { REACT_APP_API_URL3 } from "./../Constant";
import Multiselect from 'multiselect-react-dropdown';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import SimpleTextField from "./SimpleTextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import "./MulitSelectBox.css";



const useStyles = makeStyles({
    table: {
      minWidth: 650,
    }, 
    root: {
      border: "none",
    },
    contained: {
      backgroundColor: "primary",
    },
    TouchRipple: {
      backGroundColor: "#82B440",
    },
    chips:{
      background:'#000000'
    }
  });
  
const groupSizeLabel = "Group Size";
const closeonSel = true;

const MultiSelectBox = (props) => {
    const classes = useStyles();
    const state = props?.state;

    
    if(state.type===""){
      props.state.selectedValues = []
    };

    const [newGrpSizeVal, setNewGrpSizeVal] = useState(state?.groupSize || 1)
    const [grpSizeError,setGrpSizeError] = useState(false)
    const [grpSizeHelpMsg, setGrpSizeHelpMsg] = useState("")

    const validityChange = (value) => {
      props.statesToSubmitValidity.map((validityState) => {
        if(validityState.rowId === state.rowId){
          validityState.validity = value
        }
      })
      console.log("valid range - props.statesToSubmitValidity",props.statesToSubmitValidity)
    }
    const onValChange = (val,key) =>{
      if(key=="grpSize"){
        setNewGrpSizeVal(val)
      }
    }

    const onGrpSizeChange = () => {
      if(newGrpSizeVal>0){
        state.groupSize = newGrpSizeVal;
        props.onGroupSizeUpdated(state);
        setGrpSizeHelpMsg(" ")
        setGrpSizeError(false)
        validityChange(true)
      }
      else{
        setGrpSizeHelpMsg("greater than zero")
        setGrpSizeError(true)
        validityChange(false)
      }
    }
    const onSelect = (event) => {
      props.onSelected(state, event);
    };

  const onDelete =() =>{
      let boolIsDefault = false;
      state?.selectedValues.map((vals) => {
        if(vals.value === 'default')
        {
            boolIsDefault = true;
        }
      });
      if(!boolIsDefault){          
        props.onDeleted(state);
      }
  } 
  const onRemove = (event) => {
      props.onRemoved(state, event);
  };
    return (
        <TableRow>
            <TableCell classes={{ root: classes.root }} align="left" >
                <Multiselect
                options={state?.options} // Options to display in the dropdown
                selectedValues={state?.selectedValues} // Preselected value to persist in dropdown
                onSelect= {onSelect} // Function will trigger on select event
                onRemove={onRemove} // Function will trigger on remove event
                displayValue="value" // Property name to display in the dropdown options
                placeholder=''
                showArrow
                 customArrow={<KeyboardArrowDownIcon style={{margin:'-2px',color:'#BDBDBD'}}/>}
                id = {state?.rowId}
                style={{
                  chips: {
                    background: '#8A8A8A',
                   borderRadius: '6px',
                   margin:'2px 5px'
                  }
                }}
                disable ={state?.defaultVal}
                closeOnSelect = {closeonSel}
                />
            </TableCell>
                <TableCell classes={{ root: classes.root }} align="left" style={{width:"20%",padding:'5px 16px 16px 16px !important'}}>
                    {/* <SimpleTextField rowId={state?.rowId} label={groupSizeLabel}
                      inputValue={state?.groupSize} valueFunc={gtmValueFunc} /> */}
                    <TextField
                        rowId={state?.rowId}
                        margin="dense"
                        variant="outlined"
                        value={newGrpSizeVal}
                        minimum="1"
                        placeholder={newGrpSizeVal}
                        error={grpSizeError}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }}      
                        type="number"
                        onChange={(event) => { onValChange(event.target.value,"grpSize") }}
                        onBlur={onGrpSizeChange}
                        helperText={grpSizeError?grpSizeHelpMsg:" "}

                    /> 
                
                </TableCell>
                <TableCell
                      classes={{ root: classes.root }}
                      align="left"
                      margin="-50%"
                    >
                      <IconButton
                        aria-label="delete"
                        className={classes.margin}
                        onClick={onDelete}
                        disable = {state.defaultVal}
                      >
                        <DeleteIcon style={{ color: "#939393" }} />
                      </IconButton>
                    </TableCell>
        </TableRow>
    )
}

export default MultiSelectBox