import * as React from 'react';
import ExportFilterCriteriaSection from "./ExportFilterCriteriaSection";
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from "react";
import { Radio, RadioGroup, TextField, Tooltip, Typography } from "@material-ui/core";
import "./ExportFilterCriteria.css"
import { useAuthState } from "@bamboobox/b2logincheck";
import { HashRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogContentText, Button } from '@material-ui/core'
import { padding } from '@mui/system';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    root: {
      border: "none"
    },
    contained: {
      backgroundColor: "primary"
    }
  })

const ExportFilterCriteria = (props) => {
console.log('Porps in export filter creitera ',props);

    const [error, setError] = useState("");
    const [taggingBasedOn, setTaggingBasedOn] = useState([]);
    const [filterIndex , setFilterIndex] = useState(0)
    const [groupNameKey , setGroupNameKey] = useState('');
    console.log('Porps in export filter creitera - groupnamekey ',groupNameKey);
    console.log('Porps in export filter creitera - array ',props.filterAttributesArray);
    console.log('Porps in export filter creitera - array ',(props.filterAttributesArray)[groupNameKey]);


    ;
    const classes = useStyles()
    console.log('props are',props)
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    let params_icp = query.get("icp") || "";

    /////////////////////
    const tenantId = (useAuthState()).user.tenantId;
    const userUuid = (useAuthState()).user.userUuid;
    const userEmail = (useAuthState()).user.email;
    const userName = (useAuthState()).user.name;
    console.log("filterAttributesArray");
    console.log(props.filterAttributesArray);





    function getKeyByValue(object, value) {
        return Object.entries(object).find(([key, val]) => val === value)[0];
    }
    const findAttributes = () => {
        let attributes = []
        let groupnamekey = getKeyByValue(props?.groupnamemap, props?.groupName)
        let tagging = props?.configJSON[0]?.configVal[groupnamekey]?.taggingBasedOn;
        console.log('tagging ', tagging);
        setTaggingBasedOn(tagging);


    }
   

    useEffect(() => {
        findAttributes();
    }, [])

    function getKeyByValue(object, value) {
        return Object.entries(object).find(([key, val]) => val === value)[0];
    }

    useEffect(() => {
        let groupnamearr = [];
        let key = getKeyByValue(props?.groupnamemap,props?.groupName);
        setGroupNameKey(key);
    },[props?.groupName])
    
    return (
        <div id="export-filter-criteria">
         
            {
                (props?.filterAttributesArray)[0]?.[groupNameKey]?.map((filterAttributes,filterAttributesIndex) => (
                    <ExportFilterCriteriaSection  
                        key={`filterAttributes${filterAttributesIndex}`}
                        filterAttributesArray={props.filterAttributesArray}
                        filterAttributesIndex={filterAttributesIndex}
                        filterAttributes={filterAttributes}
                        setFilterAttributesArray={props.setFilterAttributesArray} 
                        icp={props.icp}
                        groupNameKey = {groupNameKey}
                        mapper={props?.mapper} configJSON={props?.configJSON} relationConfigJSON={props?.relationConfigJSON}
                        numPapers={props?.numPapers} groupname={props?.groupname} groupName={props?.groupName} index={props?.index} 
                        groupnamemap ={props?.groupnamemap} 
                        taggingBasedOn = {taggingBasedOn}
                        
                    />
                ))
            }
   
        </div>
       
    );
}

export default ExportFilterCriteria;