import { TextField } from "@material-ui/core";
import "./ExportFilterValuesField.css";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';



const EngagementTabValuesField = (props) => {
    

    const [v1error,setV1Error]= useState(false)
    const [v2error,setV2Error]= useState(false)
    const [merror,setMError]= useState(false)
    const [validateError,setValidateError] = useState(false)


    const ifNumber = (num)=> {
        if (!isNaN(num)) {
           return true
        } else {
           return  false
        }
    }

       

    const textFieldsOnchange1 = (event,val=null)=>{
       console.log("inside the on changes function");
        let rowConfig = {...props.rows}
        if(["decimal","number"]?.includes(props?.attributeType))
          {
            let checkNumber = ifNumber(event?.target?.value)
            setV1Error(!checkNumber)
        if(checkNumber)
        {
          props.setErrorDisable(1)
        }
        else{
          props.setErrorDisable(2)
        }
            
          }
        if(props?.rows?.engagementConfig[props.index]?.activityRule[props?.ruleIndex]?.relation?.toLowerCase()==='between'){
            rowConfig.engagementConfig[props.index].activityRule[props.ruleIndex].value = ['','']
            console.log("Setting16",rowConfig?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]?.value)

            rowConfig.engagementConfig[props.index].activityRule[props.ruleIndex].value[0] = event?.target?.value
        }
        else {
        rowConfig.engagementConfig[props.index].activityRule[props.ruleIndex].value = event?.target?.value
       }
       rowConfig.engagementConfig[props?.index].activityRule[props?.ruleIndex].multiplier='';
        props.setRows(rowConfig)
        props.ifChangedInScoringAttributes()


    }

    const textFieldsOnchange2 = (event,val=null)=>{
        let rowConfig = {...props.rows}
        if(["decimal","number"]?.includes(props?.attributeType))
          {
            let checkNumber = ifNumber(event?.target?.value)
            setV2Error(!checkNumber)
        if(checkNumber)
        {
          props.setErrorDisable(1)
        }
        else{
          props.setErrorDisable(2)
        }
          }
        if(props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]?.relation?.toLowerCase()?.toLowerCase()==='between'){
            rowConfig.engagementConfig[props?.index].activityRule[props?.ruleIndex].value[1]=event?.target?.value
        }
        else {
        rowConfig.engagementConfig[props?.index].activityRule[props?.ruleIndex].value = event?.target?.value
    }
        rowConfig.engagementConfig[props?.index].activityRule[props?.ruleIndex].multiplier='';
        props.setRows(rowConfig)
        props.ifChangedInScoringAttributes()

      }


      function checkMoreThanTwoDigitsAfterDecimal(number) {
        if (Number.isInteger(number)) {
            return false; // Return false if the number is an integer
        } else {
            const numberString = number.toString();
            const decimalIndex = numberString.indexOf('.');
            if (decimalIndex !== -1 && numberString.length - decimalIndex > 3) {
                return true; // Return true if more than two digits are present after the decimal point
            } else {
                return false; // Return false if two or fewer digits are present after the decimal point
            }
        }
    }
    

    const textMulFieldsOnchange = (event, val = null) => {

        let rowConfig = {...props.rows}
        let checkNumber = ifNumber(event?.target?.value)
        setMError(!checkNumber)
        if(checkNumber)
        {
          // if value is under range then then no need to do anything otherwise 
          let enterNumber = event?.target?.value
          if(enterNumber < 0 || enterNumber >= 100 || checkMoreThanTwoDigitsAfterDecimal(enterNumber) ){
              setValidateError(true)
              props.setErrorDisable(2)

          }
          else{
          setValidateError(false)
          props.setErrorDisable(1)
        }
        }
        else{
          props.setErrorDisable(2)
        }
        
        rowConfig.engagementConfig[props?.index].activityRule[props?.ruleIndex].multiplier = event?.target?.value
        props.setRows(rowConfig)
        props.ifChangedInScoringAttributes()
        };
 

    const getTextFieldValue1 = () => {
        if(props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]?.relation?.toLowerCase()==='between' &&
        props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]?.value?.length>0){
        return  props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]?.value[0] || null;
       }
       return props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]?.value || null;
    };

    const getTextFieldValue2 = () => {
        console.log("setting59",props?.rows)
        if(props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex].relation?.toLowerCase()==='between' && 
        props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]?.value?.length>0)
        {    
        return props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]?.value[1] || null;
       }
       return props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]?.value || null;
    };

    const getMulTextFieldValue = () => {
        return props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]?.multiplier || null;
    };


      const useStyles = makeStyles({
        myTextField: {
          height: "10.6vh",
        },
        root:{
            '& .MuiInputBase-root': {
                borderRadius:"8px",
                minHeight:"36px",
              },
       
        },
        info :{
            display: "flex",
            flexDirection:"row",
            alignItems:"center",
            color:"#487BFD",
            width:"200px"
        },
        customHelperText: {
          marginLeft: "0px", 
          marginRight: "14px", 
        }, 
      });
      const classes = useStyles();






    return (
        <div className="export-filter-values-field">
                    {props?.index!==null&&props?.ruleIndex!=null&&props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]?.relation?.trim() && <TextField
                        variant="outlined"
                        className={classes.root}
                        placeholder="Value"
                        style={{margin:"0px 20px 0px 0px"}}
                        value={getTextFieldValue1() || ""}
                        onChange={textFieldsOnchange1}
                        error={v1error}
                        helperText={v1error?"attribute type is number or decimal":
                        props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]?.relation?.toLowerCase()==="contains"?<div className={classes.info}><InfoOutlinedIcon style={{width:"20px" ,height:"20px"}} /> <div style={{marginLeft:"5px"}}>Use * for separating keywords</div></div>:<></>} 
                        FormHelperTextProps={{ className: classes.customHelperText }}
                        />
                        }
                        {props?.index!==null&&props?.ruleIndex!=null&&props?.rows?.engagementConfig&&props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]&&props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]?.relation?.toLowerCase()==='between' &&
                        <TextField
                        variant="outlined"
                        className={classes.root}
                        placeholder="value"
                        style={{margin:"0px 20px 0px 0px"}}
                        value={getTextFieldValue2() || ""}
                        onChange={textFieldsOnchange2}
                        error={v2error}
                        helperText={v2error?"attribute type is number or decimal":""}
                        />
                        }
                        {
                        Array.isArray(props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]?.value) && 
                        props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]?.relation?.toLowerCase()==='between'?
                        props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]?.value[0]?.trim() &&
                        props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]?.value[1]?.trim() &&
                        <TextField
                        variant="outlined"
                        className={classes.root}
                        placeholder="Multiplier"
                        style={{margin:"0px 20px 0px 0px"}}
                        value={getMulTextFieldValue() || ""}
                        onChange={textMulFieldsOnchange}
                        helperText={merror?"should be number or decimal":""}
                        error={merror}
                        /> : props?.rows?.engagementConfig[props?.index]?.activityRule[props?.ruleIndex]?.value?.trim() &&
                        <TextField
                        variant="outlined"
                        className={classes.root}
                        placeholder="Multiplier"
                        style={{margin:"0px 20px 0px 0px"}}
                        value={getMulTextFieldValue() || ""}
                        onChange={textMulFieldsOnchange}
                        error={merror || validateError}
                        helperText={merror?"should be number or decimal":validateError?"*Maximum Value 100.00(2 decimal)":""}
                        />
                        }
        </div>
    );
};

export default EngagementTabValuesField;
