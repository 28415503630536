import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import FormDialog from "./FormDialog";

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        width: 170,
        height: 35
    },
}));


const AddNewFilter = (props) => {
    const getData = () => {
        //call back function for populating data in table after change in ICP list due to CRUD operation 
        props.getData();
    }

    const onBackClick = () => {
        props.onBackClick();
    }

    const routeToIcpConditionFromBar = (icpname1) => {
        props.routeToIcpConditionFromIcpList(icpname1);
    }

    const snackbarCallbackToBar = (snackbarStateFromFormDialog) => {
        props.snackbarCallbackToIcplist(snackbarStateFromFormDialog)
    }

    return (
        <div>
            <div>
                {
                    props.previousICP === 'noICP' ?
                        // <h2 style={{ color: "#4E0C83", marginLeft: '15%', width: '200px' }}>{props.label}</h2>
                        <Typography variant="h6" style={{ color: "rgb(0, 0, 0, 0.87)" ,flex:"none",order:"0" }}>
                            {props.label}
                        </Typography>
                        : <React.Fragment>
                            {/* <h2 style={{ color: "#4E0C83" }}>{props.label}</h2> */}
                        </React.Fragment>

                }

            </div>
            {
                props.previousICP === 'ICPcondition' ?
                    <React.Fragment></React.Fragment> : <div>
                        <FormDialog icpname={props.icpname} previousICP={props.previousICP} ICPlistData={props.ICPlistData} currentICPFilterlength={props.currentICPFilterlength} content={props.content} getData={getData} routeToIcpConditionFromBar={routeToIcpConditionFromBar} copyOfIcpConditionAttribute={props.copyOfIcpConditionAttribute} snackbarCallbackToBar={snackbarCallbackToBar} setBackdropTrue={props.setBackdropTrue} setBackdropFalse={props.setBackdropFalse} snackbarCallbackToShowFilter={props.snackbarCallbackToShowFilter} />
                    </div>

            }

        </div>
    )
}

export default AddNewFilter
