import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import _uniqueId from 'lodash/uniqueId';
import { Typography } from '@material-ui/core';
import "./simpleTextField.css";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '15ch',
    },
    text:{
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
      /* identical to box height, or 32px */
      textTransform: 'uppercase',

      color: '#323232'
    }
  },
}));

const SimpleTextField = (props) => {
  const classes = useStyles()
  const regexp = new RegExp(`^(0|[1-9]+[0-9]*)`)
  const thresholdRegex=new RegExp(`^(0|[1-9][0-9]*)(\.[0-9]+)?$`);
  const [id] = React.useState(_uniqueId('SimpleTextField-'))
  const [inputValue, setInputValue] = useState(props?.inputValue)
  const [error, seterror] = React.useState(false)


  const errorStatePass = (changedTextFieldValue) => {
    seterror(false);
    setInputValue(changedTextFieldValue)
  }

  const errorStateFail = () => {
    seterror(true)
  }
  const onChange = (e) => {
    let changedTextFieldValue
    // setInputValue(e.target.value)
    if(props.threshold===true){
      e.target.value === "" ? (changedTextFieldValue = 0) : (changedTextFieldValue = parseFloat(e.target.value))
      console.log("changedTextFieldValue",changedTextFieldValue);
      thresholdRegex.test(changedTextFieldValue) ? errorStatePass(changedTextFieldValue) : errorStateFail()
      if (error === false) { props.valueFunc(changedTextFieldValue, props.label); }
    }else{
      regexp.test(changedTextFieldValue) ? errorStatePass(changedTextFieldValue) : errorStateFail()
    if (error === false) { props.valueFunc(changedTextFieldValue, props.label); }
    }
    
  }

  return (
    <React.Fragment>
      {/* <p className={classes.text}
        style={{ 
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '12px',
          /* identical to box height, or 32px /
          textTransform: 'uppercase',
          lineHeight: '266%',
          letterSpacing: '1px',
          color: '#323232'

        }}
      >{props.textfieldLabel}</p> */}
      <form className={classes.root} noValidate autoComplete="off">
        
        <TextField
          size="small"
          style={{width: "200px",background:'#ffffff'}}
          className="default"
          id={id}
          type={props.threshold===true?'number':'text'}
          value={inputValue}
          placeholder={props?.inputValue}
          variant="outlined"
          error={error}
          onChange={onChange}
          // label={props.textfieldLabel}
          helperText={" "}
        />
      </form>
    </React.Fragment>

  );
}



export default SimpleTextField
