import React from 'react'
import { useAuthState } from "@bamboobox/b2logincheck"
import "./IntentData.css"
import TextField from "@mui/material/TextField"
import DropdownSearch from './DropdownSearch'
import Chips from './ChipTopics'
import axios from "axios"
import { groupBy } from './../utility/groupByFunction'
import Paper from '@mui/material/Paper';
import { Typography } from '@material-ui/core'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const IntentDataLevel = (props) => {

    console.log("props", props)
    const userData = useAuthState();
    const tenantId = userData.user.tenantId

    let showDropdown = props.showDropdown;
    let setShowDropdown = props.setShowDropdown;

    const [categories, setCategories] = React.useState([])

    const [topicsList, setTopicsList] = React.useState([])
    const [searchTopicsList, setSearchTopicsList] = React.useState([])



    function handleChange() {
        console.log("change showDropDown", showDropdown)

        if (!showDropdown && topicsList && topicsList !== {}) {
            let removeTopics = [...props.otherSelectedTopics[0], ...props.otherSelectedTopics[1]]
            //console.log("removeTopics", removeTopics)
            let tempTopicsListObject = { ...topicsList }
            // console.log("tempTopicsListObject", tempTopicsListObject)

            removeTopics.forEach(topic => {
                //console.log("tempTopicsListObject", JSON.stringify(tempTopicsListObject[topic.provider_category_id]))
                let newArray = tempTopicsListObject[topic.provider_category_id]?.filter((item) => item.topic_name !== topic.topic_name);
                //console.log('newArray', JSON.stringify(newArray))
                //delete tempTopicsListObject[topic.provider_category_id]
                //console.log('delete', JSON.stringify(tempTopicsListObject))
                tempTopicsListObject[topic.provider_category_id] = newArray
                //console.log('addition', JSON.stringify(tempTopicsListObject))

            });
            //console.log("testing", JSON.stringify(tempTopicsListObject))
            setSearchTopicsList({ ...tempTopicsListObject })
            let categories = [];
            let keyListTopic = Object.keys(tempTopicsListObject)

            for (let i = 0; i < keyListTopic?.length; i++) {
                let categoryArray = tempTopicsListObject[keyListTopic[i]];


                let tempObj = {};
                if(categoryArray) {
                    if (categoryArray[0]) {
                        tempObj.provider_category_id = keyListTopic[i];
                        tempObj.category_name = categoryArray[0].category_name;
                        categories.push({ ...tempObj })
                    }
                }
            }
            setCategories([...categories])
        }

        //setShowDropdown(props.show[props.id])
        setShowDropdown(showDropdown => !showDropdown)

    }


    React.useEffect(() => {
        console.log("Unchecked topicsChips IntentDataLevel", props.topicsChips)
    }, [props.topicsChips])

    const getCategories = async () => {
        try {
            let result = await axios.get(`${window._env_.B2_INTENT_BACKEND}/intent/topics/get/category/${tenantId}/${props.intentProvider}`, { withCredentials: true })

            // localStorage.setItem("topicCategories", JSON.stringify(result.data.topics))
            //console.log("getCategories", result.data.topics)
            setCategories(result.data.topics)

        } catch (error) {
            console.log(error)
        }
    }

    // ,position:'absolute',opacity:'1',width:'900px',backgroundColor:'#FFFFFF'
    const getTopics = async () => {
        try {
            let result = await axios.get(`${window._env_.B2_INTENT_BACKEND}/intent/topics/get/${tenantId}/${props.intentProvider}`, { withCredentials: true })
            let topicsData = result.data.topics;
            // topicsData.sort(function (a, b) {
            //     var textA = a?.topic_name.toUpperCase();
            //     var textB = b?.topic_name.toUpperCase();

            //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            //     //return (textA < textB) ? -1 : (textA > textB) ? 1 : 0; //for ascending.

            // });


            let categoryTopicsData = groupBy(topicsData, "provider_category_id");
            //console.log("intentDataLevel", categoryTopicsData)
            setTopicsList(categoryTopicsData)
            setSearchTopicsList(categoryTopicsData)

        } catch (error) {
            console.log(error)
        }

    }

    React.useEffect(() => {
        getCategories();
        getTopics();


    }, [])

    return (
        <div className="intentLevel">
            <div className="columnHeader1">
                {
                    (props.levelName)[0].toUpperCase() + (props.levelName)?.substring(1)?.toLowerCase()
                }
            </div>
            <div className={props.levelName=='medium'?"level":'level1'}>
                {!(props.integrationStatus && props.integrationStatus !== undefined) ?
                    // <TextField
                    //     fullWidth
                    //     variant="filled"
                    //     multiline
                    //     rows={3}
                    //     disabled
                    //     id="fullWidth"
                    // /> :
                    // <TextField
                    //     InputProps={{
                    //         startAdornment: (props?.topicsChips|| []).map(data => (
                    //             <Chips topic={data} topicsChips={props.topicsChips} setTopicsChips={props.setTopicsChips} />
                    //         )),
                    //         maxLines: null
                    //     }}
                    //     fullWidth
                    //     multiline
                    //     rows={3}
                    //     onClick={handleChange}
                    //     id="fullWidth-filled"
                    //     disabled
                    //     /> 
                    <div className="text"
                    >
                    </div>
                    :
                    <div
                        className="text"
                        onClick={handleChange}
                    >
                        {props?.topicsChips?.length <= 0 ? <Typography className="typography"> No keywords selected yet</Typography> : (props?.topicsChips || []).map(data => (
                            <Chips topic={data} topicsChips={props.topicsChips} setTopicsChips={props.setTopicsChips} />
                        ))}
                    <div style={{
                        position: 'absolute',
                        right: '15px',
                        top: '40%',
                        color:'#BDBDBD'
                        }}>
                    <KeyboardArrowDownIcon />
                    </div>
                    </div>
                }
                {showDropdown ? <div class="dropdown_search" tabindex="1" ><Paper elevation={3} style={{ position: 'absolute', zIndex: 'inherit', padding: 10, width: '98%',borderRadius:'4px', maxHeight: 350, overflow: "auto", float: 'left', margin: 'auto', backgroundColor: '#FFFFFF', boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)" }} ><DropdownSearch categories={categories} setCategories={setCategories} searchTopicsList={searchTopicsList} setSearchTopicsList={setSearchTopicsList} topicsList={topicsList} levelName={props.levelName} topicsChips={props.topicsChips} setTopicsChips={props.setTopicsChips} otherSelectedTopics={props.otherSelectedTopics} handleSearchClose={handleChange}></DropdownSearch></Paper></div> : <React.Fragment></React.Fragment>}
            </div>
        </div>
    )
}

export default IntentDataLevel

{/* <TextField
                        fullWidth
                        label={props.topicsChips.map((data) => {console.log("Unchecked chips", {...data});return(<Chips topic={data}  topicsChips={props.topicsChips} setTopicsChips={props.setTopicsChips} />)})}
                        multiline
                        rows={3}
                        onClick={handleChange}
                        id="fullWidth-filled"
                    /> */}



                    // <textField
                    //     onClick={handleChange}
                    //     id="fullWidth-filled">

                    //     {props.topicsChips.map((data) => {console.log("Unchecked chips", {...data});return(<Chips topic={data}  topicsChips={props.topicsChips} setTopicsChips={props.setTopicsChips} />)})}


                    //     </textField>
