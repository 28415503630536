import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    // root: {
    //     '& > *': {
    //         margin: theme.spacing(1),
    //     }},
        // button:{
        //     background: '#21DA8C',
        //     boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
        //     borderRadius: '4px'
        // },
        button: {
            margin: theme.spacing(1),
            height: 35,
            textTransform: 'none',
            backgroundColor :"#21DA8C",
            // boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',

            borderRadius :"18px",
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '15px',
            lineHeight: '26px',
            letterSpacing: '0.46px',
            color: '#FFFFFF',

        },
        // containedPrimary: {
        //     opacity: "90%",
        // }
    }));

const ContainedButtons = (props) => {
    console.log(props);
    const classes = useStyles();

    const handleClickOpen = () => {
        props.handleClickOpen();
    }
    return (
        <React.Fragment>
            <Button
                variant='outlined'
                // classes={{ containedPrimary: classes.containedPrimary }}
                className={classes.button}
                startIcon={<Icon style={{color:'#21DA8C'}}>add</Icon>} 
                onClick={handleClickOpen}
                style={{ backgroundColor: '#ffffff' }}
                
            >
                <Typography style={{fontFamily:'Nunito Sans', color:'#21DA8C'}}>{props.buttonContentCreateICP}</Typography>
            </Button>
        </React.Fragment>
    )
}

export default ContainedButtons
