import React from "react"
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  useLocation,
  HashRouter
} from 'react-router-dom';
import ICPlist from "./component/ICPlist"
import ICPconditions from "./component/ICPconditions";
// import { ThemeProvider } from "@material-ui/core/styles";
import EngagementTab from "./component/EngagementTab";
import ShowFilter from "./component/ShowFilter";
import { ThemeProvider, createTheme } from '@mui/material/styles'; 

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Nunito Sans, sans-serif !important',
    },
  },
});


function App() {
  // let icpname = new URLSearchParams(useLocation().search).get('icp'); 
  // console.log("query"+ (new URLSearchParams(window.location.search)))


  return (
    <ThemeProvider theme={theme}>
  
        <div>
          <HashRouter>
            <Route exact path='/' component={ICPlist}></Route>
            <Route exact path='/:icpname' component={ICPlist}></Route>
            <Route exact path='/ICPconditions/:icpname/:filterid/:filtername' component={ICPconditions}></Route>
            <Route exact path='/ShowFilter/:icpname' component={ShowFilter}></Route>
            <Route exact path='/EngagementTab/:icpname' component={EngagementTab}></Route>
           
          </HashRouter>
        </div>
     
      </ThemeProvider>
      
  );
}

export default App;
