import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import "./ExportFilterValuesField.css";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowRightAlt } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Fragment } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import moment from "moment";
import { height } from "@mui/system";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';


const getLabelForAutoComplete = (attribute, option) => {
    if (attribute === 'Product Name' || attribute === 'Contact Product Name') {
        return option.productName
    } else if (attribute === 'Event Name' || attribute === 'Contact Event Name') {
        return option.eventName
    } else if (attribute === 'Campaign Name') {
        return option.programName
    } else {
        return option.activityType
    }
}


const ExportFilterValuesField = (props) => {
    console.log('props in value field ', props)
    const PastValues = ['Week', 'Month', 'Quarter', 'Year']
    console.log("testingattribute", props.attributeValues);
    const [inputText, setInputText] = useState('');
    const B2_INTENT_SETTING = window._env_.B2_INTENT_SETTING;
    const getOPtionFilt = (option) => {
        try {

            return props.filterAttribute.attribute === 'Event Name' || props.filterAttribute.attribute === 'Contact Event Name' ? option.eventName : props.filterAttribute.attribute === 'Product Name' || props.filterAttribute.attribute === 'Contact Product Name' ? option.productName : (props.filterAttribute.attribute === 'Campaign Name' || props.filterAttribute.attribute === 'Contact Campaign Name' ? option.programName : (props.filterAttribute.attribute === "Activity Type" || props.filterAttribute.attribute === "Contact Activity Type" ? option.activityType : option));
        }
        catch (e) {
            return ''
        }
    }
    const getValue = () => {
        try {

            // if(attributeData.attribute.includes(objectValue))
            if (inputText === '') return props?.filterAttribute?.values.length === props?.attributeValues.length;
            return props?.filterAttribute?.values.filter(option => getOPtionFilt(option)?.toLowerCase().includes(inputText?.toLowerCase()))?.length === props?.attributeValues?.filter(option => getOPtionFilt(option)?.toLowerCase().includes(inputText?.toLowerCase()))?.length;
            // else return attributeValues.filter(option=>option.toLowerCase().includes(inputText.toLowerCase())) === attributeData?.values.filter(option=>option.toLowerCase().includes(inputText.toLowerCase()));
        } catch (error) {
            return false
        }
    }
    const selectAllValues = getValue();

    const textFieldsOnchange = (event, val = null) => {
        
        const value = event.target.value || val;
        let newFilterAttribute = { ...props.filterAttribute };
        if ((value === null || value === "") && "values" in newFilterAttribute) {
            delete newFilterAttribute["values"];
        } else {
            newFilterAttribute["values"] = [value];
        }
        console.log(newFilterAttribute);
        let valuesArray = []
        let fieldname = props?.filterAttributes?.[props.filterAttributeIndex]?.fieldName;
        let valuesource = props?.filterAttributes?.[props.filterAttributeIndex]?.valueSource;
        if (newFilterAttribute?.values && newFilterAttribute?.values.length > 0) {
            for (let i = 0; i<newFilterAttribute?.values?.length; i++) {
                if(typeof newFilterAttribute?.values[i] == 'object'){
                    valuesArray[i] = newFilterAttribute?.values[i];
                    continue
                }
                console.log(newFilterAttribute)
                console.log(valuesArray)

                    let valueobj = {};
                    valueobj[fieldname] = value;
                    valueobj['source'] = valuesource
                    //newFilterAttribute?.values[i] = valueobj
                    valuesArray[i] = valueobj
                    console.log(valuesArray)

            }
            newFilterAttribute['values'] = valuesArray

        }


        const newFilterAtributes = [...props.filterAttributes];
        newFilterAtributes[props.filterAttributeIndex] = { ...newFilterAttribute };
        const newFilterAttributesArray = [...props.filterAttributesArray];
        newFilterAttributesArray[0][props?.groupNameKey][props.filterAttributesIndex] = [
            ...newFilterAtributes,
        ];
        props.setFilterAttributesArray(newFilterAttributesArray);
    };
 
    const filters = createFilterOptions()
    const handleInput = (value) => {
        try {

            if (value) setInputText(value?.target?.value || '');
            else setInputText('')
        } catch (error) {
            setInputText('')
        }
    }


    const multiSelectOnchange = (event, value) => {
      
        const newFilterAttribute = { ...props.filterAttribute };
        if (
            value === null ||
            value === "" ||
            (value.length === 0 && "values" in newFilterAttribute)
        ) {
            delete newFilterAttribute["values"];
        } else {
            if (value.find(e => e === "Select All")) {
                if (!selectAllValues) {

                    if (inputText === '') newFilterAttribute["values"] = props.attributeValues
                    else newFilterAttribute["values"] = props.attributeValues.filter(option => getOPtionFilt(option)?.toLowerCase().includes(inputText?.toLowerCase()) || value.some(el => (getOPtionFilt(el) == getOPtionFilt(option) && el?.source === option?.source)))
                }
                else {
                    if (inputText === '') newFilterAttribute["values"] = [];
                    else newFilterAttribute["values"] = value.filter(e => e !== "Select All" && !getOPtionFilt(e)?.toLowerCase().includes(inputText?.toLowerCase()));
                }
            }
            else newFilterAttribute["values"] = value;
        }
        console.log(newFilterAttribute);
        let valuesArray = []
        let fieldname = props?.filterAttributes?.[props.filterAttributeIndex]?.fieldName;
        let valuesource = props?.filterAttributes?.[props.filterAttributeIndex]?.valueSource;
        if (newFilterAttribute?.values && newFilterAttribute?.values.length > 0) {
            for (let i = 0; i<newFilterAttribute?.values?.length; i++) {
                if(typeof newFilterAttribute?.values[i] == 'object'){
                    valuesArray[i] = newFilterAttribute?.values[i];
                    continue
                }
                console.log(newFilterAttribute)
                console.log(valuesArray)

                    let valueobj = {};
                    valueobj[fieldname] = value[i];
                    valueobj['source'] = valuesource
                    //newFilterAttribute?.values[i] = valueobj
                    valuesArray[i] = valueobj
                    console.log(valuesArray)

            }
            newFilterAttribute['values'] = valuesArray

        }


        const newFilterAtributes = [...props.filterAttributes];
        newFilterAtributes[props.filterAttributeIndex] = { ...newFilterAttribute };
        const newFilterAttributesArray = [...props.filterAttributesArray];
        newFilterAttributesArray[0][props?.groupNameKey][props.filterAttributesIndex] = [
            ...newFilterAtributes,
        ];
        props.setFilterAttributesArray(newFilterAttributesArray);
        setInputText('');

    };






    const getTextFieldValue = () => {
        if (props.filterAttribute.values === undefined) {
            return null;
        } else {
            return props.filterAttribute.values[0]?.[props?.filterAttribute?.fieldName];
        }
    };

    const getMultiSelectValue = () => {
        console.log("testingProps", props.filterAttribute);
        if (props.filterAttribute.values === undefined) {
            return [];
        } else {
            return props.filterAttribute.values;
        }
    };
    const getLabelForAutoComplete = (attribute, option) => {
        
        if(attribute === 'Product') {
          return  option.products
         }else if(attribute === 'Product Name' ){
          return option.productName
         }else if(attribute === 'Campaign Name'){
          return option.campaignName
         }else if(attribute === 'Event Name'){
          return option.eventName
         }else{
          return option.activityType
         }
      }

      const useStyles = makeStyles({
        myTextField: {
          width: "33vw",
          height: "10.6vh",
        },
        root:{
            '& .MuiInputBase-root': {
                width: "33vw",
                borderRadius:"8px",
                minHeight:"36px",
                
                padding: "1px 12px !important"
              }
        } 
      });
      const classes = useStyles();





    return (
        <div className="export-filter-values-field">
            {props.attributeValues.length !== 0 &&
                ((props?.filterAttribute.relation && ["includes", "excludes"].includes(props?.filterAttribute?.relation)   )
                    || (props?.filterAttribute?.[props?.filterAttributeIndex]?.relation &&
                        ["includes", "excludes"].includes(props?.filterAttribute?.[props?.filterAttributeIndex]?.relation))) &&
                (
                    <Autocomplete
                    multiple
                    size="small"
                    limitTags={2}
                    options={props.attributeValues}
                    className={classes.root}
                    disableCloseOnSelect
                    fullWidth
                     filterSelectedOptions
                    freeSolo={false}
                    inputValue={inputText}
                    getOptionLabel={(option) => {
                        if (option === "Select All") {
                            return "Select All";
                          } else {
                            return getLabelForAutoComplete(props.filterAttribute.attribute, option);
                          }
                        }
                    }
                    getOptionSelected={(option, value) => 
                        
                        (props.filterAttribute.attribute === 'Product' 
                        ) ? 
                        (option.products === value.products) :props.filterAttribute.attribute === 'Campaign Name' ? (option.campaignName === value.programName) :
                        props.filterAttribute.attribute === 'Event Name' ? (option.eventName === value.eventName) :
                        props.filterAttribute.attribute === 'Product Name' ? (option.productName === value.productName) : ''
                    }
                    value={getMultiSelectValue() || ""}
                    onChange={multiSelectOnchange}
                    filterOptions={(options, params) => {
                      const filtered = filters(options, params);
                      return ["Select All", ...filtered];
                    }}
                    popupIcon={<KeyboardArrowDownRoundedIcon style={{ color: "rgba(3, 3, 3, 0.6)" }} />}
                    renderOption={(option, { selected }) => {
                      const selectAllProps = option === "Select All" // To control the state of 'Select All' checkbox
                        ? { checked: selectAllValues } : {};
                      return (
                        <Fragment>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8,color:"#21DA8C"}}
                            //color="primary"
                            checked={selected}
                            {...selectAllProps}
        
                          />
      {option === "Select All" ? "Select All" : props.filterAttribute.fieldName === 'campaignName'? option["campaignName"]: option[props.filterAttribute.fieldName]}
                        </Fragment>
                      )
                    }}
                    style={{ maxWidth: "520px",maxHeight:"300px" }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" placeholder="Values" onInput={handleInput} 
                      style={{borderColor:"#21DA8C"}}/>
                    )}
                  />
                    
                )}
            {props.attributeValues.length === 0 &&
                ["includes", "excludes"].includes(props.filterAttribute.relation) && (
                    <TextField
                        variant="outlined"
                        className={classes.root}
                        placeholder="Value"
                        style={{ width: "520px" }}
                        value={getTextFieldValue() || ""}
                        onChange={multiSelectOnchange}
                        />
                )}
            {
                ["contains","does not contains"].includes(props?.filterAttribute?.relation) && (
                <div style={{ }}>
                     <TextField
                    variant="outlined"
                    placeholder="Value"
                    style={{height:"10.6vh !important"}}
                    className={classes.root}
                    //style={{ width: "500px !important"  , height:"100px" , paddingTop:"2px"}}
                    value={getTextFieldValue() || ""}
                    onChange={textFieldsOnchange}
                    />
                </div>
                   
                ) 
            }

        </div>
    );
};

export default ExportFilterValuesField;
