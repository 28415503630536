import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import RatingLevelComboBoxNew from "./RatingLevelComboBoxNew";
import { useAuthState } from "@bamboobox/b2logincheck";
import axios from "axios";
import CheckIcon from '@mui/icons-material/Check';
import { REACT_APP_API_URL2, REACT_APP_API_URL3, B2_INTENT_SETTING,TENANTCONFIG_API} from "./../Constant";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EngagementTabAddFieldComponent  from "./EngagementTabAddField" 
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {CssBaseline } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';



import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  InputAdornment,
  TextField,
    Typography
} from "@material-ui/core";
import _uniqueId from "lodash/uniqueId";
import SimpTextField from "./SimpTextField";
import SimpleTextField from "./SimpleTextField";
import "./EngagementTypeTable.css";
import Toggler from "./Toggler";
import getTenantConfigJSON from "../utility/getTenantConfigJson";
import getTenantConfig from "../utility/getTenantConfig";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Alert from '@mui/material/Alert';
import "./../toast.css"
import { display } from "@material-ui/system";



import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { left } from "@popperjs/core";

toast.configure({
  autoClose: 6000,
  draggable: true,
  hideProgressBar: false,
  position: toast.POSITION.BOTTOM_LEFT,
  icon: false,
});
// import {debounce} from 'lodash';

const useStyles = makeStyles({
  summary_and:{
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "38px",
    width: "100%",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.4px",
    textTransform: "uppercase",
    color: "#21DA8C",
    flex: "none",
    order: 0,
    flexGrow: 0
},
noRotate: {
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "none"
  },
  // border: "1px solid",
  borderRadius:"8px",
  boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)"
  // borderImageSource: "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),linear-gradient(0deg, #004B6B, #004B6B)"
},
  table: {
    minWidth: 650,
  },
  root: {
    border: "none",
    background:'#ffffff',
    fontFamily:'Nunito Sans',
    width:'188px',
    paddingLeft:"0px !important",
  },
  contained: {
    backgroundColor: "#21DA8C",
  },
  summary: {
    '&.MuiAccordionSummary-root.Mui-focusVisible': {
      backgroundColor: 'transparent !important', // Remove the background color on focus-visible
    },
  },
button:{
width: "125px",
height: "42px",
borderRadius: "8px",
boxShadow: "0px 4px 4px 0px #0000001F",
background: "#00A5A7",
fontSize: "16px",
fontWeight: "600",
lineHeight: "19px",
letterSpacing: "0em",
textTransform:"none",
'&:hover': {
  background: "#00A5A7",
  color: "#FFFFFF", // Disabling the hover effect
},
color: "#FFFFFF",
},

setRule:{
  width: "168px",
height: "42px",
borderRadius: "8px",
border: "2px solid #00A5A7",
color:"#00A5A7",
marginLeft:"16px",
fontWeight:"800"

},
setRuleDisabled:{
  width: "168px",
  height: "42px",
  borderRadius: "8px",
  border: "2px solid #001325",
  color:"#001325",
  marginLeft:"16px", 
  fontWeight:"800 !important", 
},
typo:{
fontSize: "16px",
fontWeight: "400",
lineHeight: "19px",
letterSpacing: "0em",
marginLeft: "16px",
textAlign: "left",
},
boosterVal:{
  position:"relative",
  float:"right",
  right:"11rem"
},
boosterValBet:{
  position:"relative",
  float:"right",
  right:"6rem"
},
typography:{
  width:"150px",
  overflow:"hidden",
  textOverflow:"ellipsis",
  whiteSpace:"nowrap"
}
});

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#595959",
      color: '#FFFFFF',
      boxShadow: theme.shadows[1],
      borderRadius: "18px",
      fontWeight: "600",
      fontSize: "13px",
      lineHeight: "13px"
  },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled(({ ...props }) => (
  <MuiAccordionSummary
    expandIcon={<KeyboardArrowRightIcon sx={{ fontSize: '0.9rem' , transform: 'rotate(90deg)' }} />}
    {...props}
  />
))(({ theme }) => ({
  // flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'none',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));







const thresholdIntervalLabel = "thresholdInterval";
const halfLifeLabel = "halfLife";
const repetitionHalfLifeLabel = "repetitionHalfLife";
const importanceLabel = "Importance";
const importanceLevel = {
  level: [
    {
      levelName: "Very High",
      levelRating: 4,
    },
    {
      levelName: "High",
      levelRating: 3,
    },
    {
      levelName: "Medium",
      levelRating: 2,
    },
    {
      levelName: "Low",
      levelRating: 1,
    },
    {
      levelName: "Very Low",
      levelRating: 0,
    },
    {
      levelName: "N/A",
      levelRating: -1,
    },
  ],
};

const thresholdScoreLabel = "Threshold Score";
const textfieldLabel = "Engagement Threshold";

const EngagementTypeTable = (props) => {
  const classes = useStyles();
  const userData = useAuthState();
  const [rows, setRows] = React.useState();
  const [thresholdScore, setThresholdScore] = React.useState(undefined);
  const [initialData, setInitialData] = useState(); //To Check if any value has been changed
  const [initialThresholdScore, setInitialThresholdScore] = React.useState();
  const [buttonDisable, setButtonDisable] = useState(true); //Conditionally enable and disable button
  const [enable, setEnable] = useState(false);
  const [errorDisabled, setErrorDisabled] = useState(0);
  const [selectedFrequency, setSelectedFrequency] = useState("All");
  const [isContextEnable,setIsContextEnable] = useState(false); //Conditionally enable and disable
  // filter array shoud
  const [attributeArray,setAAttributeArray] = useState([])
  const [attributeConfig,setAttributeConfig] = useState({})
  const [relationConfig,setRelationConfig] = useState({})

  useEffect(() => {
    console.log("rendering Engagementtypetab.");
  });
// function to return the index of the db object 
 const findDataFromDB=(engagement,dbEngagement)=>{
       for(let i=0;i<dbEngagement.length;i++) {
         if(dbEngagement[i].engagementId.engagementType===engagement.engagementId.engagementType && dbEngagement[i].source===engagement.source && dbEngagement[i].engagementId.type===engagement.type)
           return i;    
       }
       return -1;
 }

 const getConfigMapper=async () => {
  try{
      const resp = await fetch(`${TENANTCONFIG_API}/tenant/field/mapper/get/${userData.user.tenantId}/contextual-scoring-ui`,{ headers: { "Content-Type": "application/json" },credentials: "include"})
      const jsonResp = await resp.json()
      return jsonResp;
  }catch (error) {
      console.log("error",error )
  }  
}

 const getAttributeRelation = async()=>{
  try{
    // will call tenantconfig first then mapper and configJson
    getTenantConfig(userData.user.tenantId,"ContextualScoring",["isEnabled"]).then((configRes)=>{
    console.log("configRes: " + JSON.stringify(configRes))
    setIsContextEnable(configRes[0]?.configVal);
    if(configRes[0]?.configVal===true|| configRes[0]?.configVal==="true"){
      getConfigMapper().then((data) => {
      // var attribute = res[0]?.configVal || {};
      setAttributeConfig(data);
      setAAttributeArray(Object.keys(data))
      getTenantConfigJSON(userData.user.tenantId, "ContextualScoring", [
        "scoring_relation",
      ])
      .then((res) =>{
        let relation = res[0]?.configVal || {};
        setRelationConfig(relation);
      });

    })
  }
  })
}
  catch(e){
    console.log("Error getting getAttributeRelation",e)
  }
 }
  const updateRows = async () => {
    console.log("in updaterows of engagement type tab with icp", props.icpname);
    setRows(undefined)
    await axios
      .get(
        REACT_APP_API_URL2 +
          "/engagement/" +
          userData.user.tenantId +
          "/" +
          props.icpname,
        { withCredentials: true }
      )
      .then((result) => {
        result.data.engagementConfig.sort((a, b) => {
          return a.rowId - b.rowId;
        });

        const dbEngagement = result.data.engagementConfig
        console.log("dbEngagement",dbEngagement)
        result.data.engagementConfig=[]
         
        // getTenantConfigJSON(userData.user.tenantId, "Scoring", [
        //   "EngagementType",
        // ]).then((res) => {
        //   console.log("configVal", JSON.stringify(res[0].configVal));
        //   var contact = res[0].configVal[0].contact;
        //   var account = res[0].configVal[1].account;
        // });

        // if (
        //   Array.isArray(result.data.engagementConfig) &&
        //   !result.data.engagementConfig.length
        // ) {
          try {
            getTenantConfigJSON(userData.user.tenantId, "Scoring", [
              "EngagementType",
            ]).then((res) => {
              var contact = res[0].configVal[0].contact;
              var account = res[0].configVal[1].account;
              var list = [];
              for (let i = 0; i < contact.length; i++) {
                list.push(contact[i]);
              }
              for (let i = 0; i < account.length; i++) {
                list.push(account[i]);
              }
              let engagementObject;
              list.forEach((engagement) => {
                if (
                  Array.isArray(result.data.engagementConfig) &&
                  !result.data.engagementConfig.length
                ) {
                  engagementObject = {
                    engagementId: {
                      engagementType: engagement.activityType,
                      icp: props.icpname,
                    },
                    weightage: "",
                    thresholdInterval: "",
                    halfLife: "",
                    repititionHalfLife: "",
                    rowId: 0,
                    type: engagement.type,
                    source: engagement.source,
                    activityRule: [{}]
                  };
                } else {
                 
                  engagementObject = {
                    engagementId: {
                      engagementType: engagement.activityType,
                      icp: props.icpname,
                    },
                    weightage: "",
                    thresholdInterval: "",
                    halfLife: "",
                    repititionHalfLife: "",
                    rowId: result.data.engagementConfig.length,
                    type: engagement.type,
                    source: engagement.source,
                    activityRule:[{}]
                  };
                }
                result.data.engagementConfig.push(engagementObject);
              });
              result.data.engagementMapperId = {
                custid: userData.user.tenantId,
                icpname: props.icpname,
              };
              let initialDataObjectArray = [];
              let initialDataObject;
              console.log("result after matching123",result.data)
              result.data.engagementConfig.forEach((engagement) => {
                 // find index of based on  activity and source
                const index = findDataFromDB(engagement,dbEngagement);
                console.log("result after matching",index)
                if(index!==-1){
                  //result.data.engagementConfig[index]
                  engagement.weightage=dbEngagement[index].weightage
                  engagement.thresholdInterval=dbEngagement[index].thresholdInterval
                  engagement.halfLife=dbEngagement[index].halfLife
                  engagement.repititionHalfLife=dbEngagement[index].repititionHalfLife
                  engagement.type=dbEngagement[index].engagementId.type
                  engagement.source=dbEngagement[index].source
                  engagement.activityRule = ruleEngineFormatToArray(dbEngagement[index]?.activityRule)
                initialDataObject = {
                  weightage: dbEngagement[index].weightage,
                  thresholdInterval: dbEngagement[index].thresholdInterval,
                  halfLife: dbEngagement[index].halfLife,
                  repititionHalfLife: dbEngagement[index].repititionHalfLife,
                  type: dbEngagement[index].engagementId.type,
                  source: dbEngagement[index].source,
                  activityRule:  ruleEngineFormatToArray(dbEngagement[index]?.activityRule)
                };

                initialDataObjectArray.push(initialDataObject);
              }
              else{
                // engagement.activityRule = ruleEngineFormatToArray(engagement?.activityRule)
                initialDataObject = {
                  weightage: engagement.weightage,
                  thresholdInterval: engagement.thresholdInterval,
                  halfLife: engagement.halfLife,
                  repititionHalfLife: engagement.repititionHalfLife,
                  type: engagement.type,
                  source: engagement.source,
                  activityRule:ruleEngineFormatToArray(engagement?.activityRule)
                };
                initialDataObjectArray.push(initialDataObject);
              }
                
              });
              // result.data.engagementConfig=initialDataObjectArray
              console.log("result.data",result.data)
              setRows(result.data);
              console.log("initialDataObjectArray",initialDataObjectArray)
              setInitialData(initialDataObjectArray);
              // axios.get(REACT_APP_API_URL3 + '/' + userData.user.tenantId + '/describe.json', { withCredentials: true }).then((response) => {
              //   var list = []
              //   if (response.status === 200) {
              //     var json = JSON.parse(JSON.stringify(response.data))
              //     for (let i = 0; i < json.length; i++) {
              //       if (json[i].name === 'contacts') {
              //         for (let j = 0; j < json[i].fields.length; j++) {
              //           if (json[i].fields[j].name === 'Engagements') {
              //             for (let k = 0; k < json[i].fields[j].fields.length; k++) {
              //               if (json[i].fields[j].fields[k].name === 'engagementType') {
              //                 for (let l = 0; l < json[i].fields[j].fields[k].values.length; l++) {
              //                   list.push(json[i].fields[j].fields[k].values[l].activityType)
              //                 }
              //               }
              //             }
              //           }
              //         }
              //       }
              //     }

              //     let engagementObject
              //     list.forEach((engagement) => {
              //       if (Array.isArray(result.data.engagementConfig) && !result.data.engagementConfig.length) {
              //         engagementObject = {
              //           "engagementId": {
              //             "engagementType": engagement,
              //             "icp": props.icpname
              //           },
              //           "weightage": "",
              //           "thresholdInterval": "",
              //           "halfLife": "",
              //           "repititionHalfLife": "",
              //           "rowId": 0
              //         }
              //       }
              //       else {
              //         engagementObject = {
              //           "engagementId": {
              //             "engagementType": engagement,
              //             "icp": props.icpname
              //           },
              //           "weightage": "",
              //           "thresholdInterval": "",
              //           "halfLife": "",
              //           "repititionHalfLife": "",
              //           "rowId": result.data.engagementConfig.length
              //         }
              //       }
              //       result.data.engagementConfig.push(engagementObject)
              //     })
              //     result.data.engagementMapperId = {
              //       "custid": userData.user.tenantId,
              //       "icpname": props.icpname
              //     }
              //     let initialDataObjectArray = []
              //     let initialDataObject
              //     result.data.engagementConfig.forEach((engagement) => {
              //       initialDataObject = {
              //         "weightage": engagement.weightage,
              //         "thresholdInterval": engagement.thresholdInterval,
              //         "halfLife": engagement.halfLife,
              //         "repititionHalfLife": engagement.repititionHalfLife
              //       }
              //       initialDataObjectArray.push(initialDataObject)
              //     })
              //     setRows(result.data)
              //     setInitialData(initialDataObjectArray)
              //   }
            });
          } catch (error) {
            console.log(error);
          }
        // } 
        // else {
        //   let initialDataObjectArray = [];
        //   let initialDataObject;
        //   result.data.engagementConfig.forEach((engagement) => {
        //     console.log("to check source", result.data.engagementConfig);
        //     initialDataObject = {
        //       weightage: engagement.weightage,
        //       thresholdInterval: engagement.thresholdInterval,
        //       halfLife: engagement.halfLife,
        //       repititionHalfLife: engagement.repititionHalfLife,
        //       type: engagement.type,
        //       source: engagement.source,
        //     };
        //     initialDataObjectArray.push(initialDataObject);
        //   });
        //   setRows(result.data);
        //   setInitialData(initialDataObjectArray);
        // }
      });
  };

  const updateThreholdScore = async () => {
    try {
      await axios
        .get(
          REACT_APP_API_URL2 +
            "/threshold/" +
            userData.user.tenantId +
            "/" +
            props.icpname,
          { withCredentials: true }
        )
        .then((result) => {
          setThresholdScore(result.data);
          setInitialThresholdScore(
            result.data.individualThreshold?.individualEngagementThresholdScore
          );
        });
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   getAttributeRelation()
  //   updateRows();
  //   updateThreholdScore();
  // }, []);

  useEffect(() => {
    // get mapper and attributes from tenantConfig and tenantConfigjson
    let isMounted = true;
    updateRows();
    getAttributeRelation()
    updateThreholdScore();
    return () => {
      isMounted = false;
    };
  }, [props.icpname]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = (index) => {
    if(index === isOpen)
    setIsOpen(false);   
    else setIsOpen(index);
  };

  const thresholdValueFunc = (buyingGroupValue, type) => {
    const rowsData = { ...thresholdScore };
    rowsData.individualThreshold.individualEngagementThresholdScore =
      buyingGroupValue;
    //If any value changed, then enable update button
    if (
      rowsData.individualThreshold.individualEngagementThresholdScore ===
      initialThresholdScore
    ) {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
    setThresholdScore({
      ...rowsData,
      individualThreshold: { ...rowsData.individualThreshold },
    });
  };

  const handleSave = () => {
    onUpdateEngagement();
    props.setEnableDialog(false);
    props.setIsDirty(false);
    props.changeTab();
  };

  const handleClose = () => {
    props.setEnableDialog(false);
    props.setIsDirty(false);
    props.changeTab();
  };


    const convertToArray = (str, relation) => {
      // if relation is not between and value contacts * then 
      if(relation?.toLowerCase()!=="between"?.toLowerCase() && str?.includes("*")){
      let strArray= str?.split("*");
      const trimmedArr = strArray?.map(item => item?.trim());
      return trimmedArr;
    }
    return str;
    };
  
  // Function to join an array into a string using a specified separator
  const joinArrayToString = (arr, relation) => {
    // if array and relation is not between 
    if(relation?.toLowerCase()!=="between"?.toLowerCase() && Array.isArray(arr))
    {
    return arr?.join("*");
  }
  return arr;
  };

  // function to convert attributes to rule 
  const  extractFloatValue=(str)=> {
    const regex = /(\d+(\.\d{0,2})?)/; // Regex to extract the float value with two digits after the decimal point
    const match = str?.match(regex);

    if (match) {
        return match[0];
    } else {
        return "Invalid input";
    }
}
  const getColumnName = (engagementType,attributeName) =>{
    try{
    console.log("getColumnName functionType",engagementType,attributeName,attributeConfig);
    return attributeConfig[engagementType?.toLowerCase()][attributeName]?.column || ""
  }
  catch(e){
    console.log("Error in getColumnName",getColumnName)
     return ""
  }
  }
  const  arrayToRuleEngineFormat = (arr,engagementType) => {
    console.log("arrayToRuleEngineFormat",arr)
    const ruleArray = arr?.length<=0|| arr[0]==undefined?[]:arr?.map(item => ({
      rule: {
        conditions: {
          all: [
            {
              path: item?.attribute,
              operator: item?.relation,
              value: convertToArray(item?.value,item?.relation),
            },
          ],
        },
      },
      multiplier: extractFloatValue(item?.multiplier),
      column:getColumnName(engagementType,item?.attribute)
    }));
    return JSON.stringify(ruleArray);
  }

  // function to convert rule to attribute value
  const  ruleEngineFormatToArray =  (ruleEngineFormat) => {
    console.log("ruleEngineFormat",ruleEngineFormat)
    let ruleArray = ruleEngineFormat==null || (Array.isArray(ruleEngineFormat)&&ruleEngineFormat?.length<=0) || Object?.keys(ruleEngineFormat[0])?.length<=0 ? [{}] :JSON.parse(ruleEngineFormat)
    ruleArray = ruleArray?.length<=0 ?[{}] : ruleArray;
    let resultArray = ruleArray?.map(rule => {
      const conditions = rule?.rule?.conditions?.all;
      const multiplier = rule?.multiplier;
      const column = rule?.column
      return conditions?.map(condition => ({
        attribute: condition?.path,
        relation: condition?.operator,
        value: joinArrayToString(condition?.value,condition?.operator),
        multiplier: multiplier,
        column:column
      }));
    });
   
    return [].concat(...resultArray); // Flatten the result if needed.
  }






 const isDisabled = (index) =>{
       let rowConfig = {...rows};
       let activityRule = rowConfig.engagementConfig[index]?.activityRule || [];
       for(let object of activityRule)
       {
         let attr = object?.attribute || '';
         let rel = object?.relation || '';
         let mul = object?.multiplier || '';
         let val1 = object?.value || '';
         let val2 = 'test';
         if(rel?.toLowerCase()==='between' && Array.isArray(object?.value))
         {
           val1 = object?.value[0]
           val2 = object?.value[1]
         }
          if(attr?.trim()?.length<=0 || rel?.trim()?.length<=0 || val1?.trim()?.length<=0 || mul?.trim()?.length<=0|| val2?.trim()?.length<=0){
            return true;
          }
       }
       return false;

 }

  const addRuleCriteria = (index) => {
    let  rowConfig = {...rows};
    console.log("rowConfig468",rowConfig)
    rowConfig.engagementConfig[index].activityRule.push({})
    setRows(rowConfig);
  }

  const ratingValueFunc = (engagementValue, type, rowId) => {
    console.log("in ratingvaluefunc");
    const rowsData = { ...rows };
    let importanceValue;
    let thresholdInterval;
    let halfLife;
    let repetitionHalfLife;

    let indexOfSelectedRatingComboBox = rowsData.engagementConfig.findIndex(
      function (i) {
        return i.rowId === rowId;
      }
    );
    if (type === "thresholdInterval") {
      thresholdInterval = engagementValue;
      rowsData.engagementConfig[
        indexOfSelectedRatingComboBox
      ].thresholdInterval = thresholdInterval;
    } else if (type === "halfLife") {
      halfLife = engagementValue;
      rowsData.engagementConfig[indexOfSelectedRatingComboBox].halfLife =
        halfLife;
    } else if (type === "repetitionHalfLife") {
      repetitionHalfLife = engagementValue;
      rowsData.engagementConfig[
        indexOfSelectedRatingComboBox
      ].repititionHalfLife = repetitionHalfLife;
    } else {
      importanceValue = engagementValue;
      rowsData.engagementConfig[indexOfSelectedRatingComboBox].weightage =
        importanceValue;
    }
    let changed; //If any data has been changed

    for (var i = 0; i < rowsData.engagementConfig.length; i++) {
      if (
        rowsData.engagementConfig[i].weightage !== initialData[i].weightage ||
        rowsData.engagementConfig[i].thresholdInterval !==
          initialData[i].thresholdInterval ||
        rowsData.engagementConfig[i].halfLife !== initialData[i].halfLife ||
        rowsData.engagementConfig[i].repititionHalfLife !==
          initialData[i].repititionHalfLife
      ) {
        changed = false;
        break;
      } else {
        changed = true;
      }
    }

    if (changed) {
      setButtonDisable(true);
      props.setIsDirty(false);
    } else {
      setButtonDisable(false);
      props.setIsDirty(true);
    }
    setRows(rowsData);
  };
  console.log("setting780",rows)
  const onUpdateEngagement = () => {
    props.setBackdropStateFromChildComponent(true);
    let engagementPostBody = [];
    let engagementRow;

    props.setBackdropStateFromChildComponent(true);
    let score = { value: "" };
    score.value =
      thresholdScore?.individualThreshold?.individualEngagementThresholdScore;

    rows.engagementConfig.forEach((row) => {
      engagementRow = {
        engagementType: row.engagementId.engagementType,
        weightage: row.weightage,
        thresholdInterval:
          row.thresholdInterval || 0,
        halfLife: row.halfLife,
        repititionHalfLife: row.repititionHalfLife,
        rowId: row.rowId,
        type: row.type,
        source: row.source,
        activityRule:row?.activityRule?.length<=0 || row?.activityRule==null ? "[]" : arrayToRuleEngineFormat(row?.activityRule,row.engagementId.engagementType)
      };
      engagementPostBody.push(engagementRow);
    });
    let noError=true;
    try {
      axios
        .post(
          REACT_APP_API_URL2 +
            "/engagement/" +
            userData.user.tenantId +
            "/" +
            props.icpname,
          engagementPostBody,
          { withCredentials: true }
        )
        .then((engResult) => {

          if(engResult.data.code==="200"){
            noError=false;
          }
          axios
            .post(
              REACT_APP_API_URL2 +
                "/threshold/" +
                userData.user.tenantId +
                "/" +
                props.icpname,
              score,
              { withCredentials: true }
            )
            .then((result) => {
              if(result.data.code==="200"){
                noError=false;
              }
              setInitialThresholdScore(score.value);
              props.setBackdropStateFromChildComponent(false);
              // props.snackbarCallbackToTab({
              //   open: true,
              //   severity: "success",
              //   message: "Successfully updated",
              // });
              if(noError===false){
                toast.success(<Alert severity="success">Successfully updated</Alert>,{
                  closeOnClick: true,
                  autoClose: true,
                  closeButton: true,
                  newestOnTop: true
                });
              }else{
                toast.error(<Alert severity="error">Update Failed</Alert>,{
                  closeOnClick: true,
                  autoClose: true,
                  closeButton: true,
                  newestOnTop: true
                });
              }
              
            });

          let initialDataArray = [];
          let initialDataObject;
          engagementPostBody.forEach((engagement) => {
            initialDataObject = {
              weightage: engagement.weightage,
              thresholdInterval: engagement.thresholdInterval,
              halfLife: engagement.halfLife,
              repititionHalfLife: engagement.repititionHalfLife,
              type: engagement.type,
              source: engagement.source,
              activityRule:ruleEngineFormatToArray(engagement?.activityRule)
            };
            initialDataArray.push(initialDataObject);
          });
          setInitialData(initialDataArray);
          /////////////////
          setButtonDisable(true);
          props.setIsDirty(false);
        });
    } catch (error) {
      console.log(error);
      // props.snackbarCallbackToTab({
      //   open: true,
      //   severity: "error",
      //   message: "Update Failed",
      // });
      toast.error(<Alert severity="error">Update Failed</Alert>,{
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true
      });
    }
  };

  const thvals = {
    WebFormFill: 0,
    EmailForwarded: 0,
    EmailSent: 30,
    EmailOpened: 30,
    EmailClick: 30,
    WebPageVisit: 30,
    AdClick: 30,
    AdImpression: 30,
  };
 console.log("adding the rows data 868",rows)
  return (
    <React.Fragment>
      {/* <CssBaseline /> */}
      {rows === undefined || thresholdScore === undefined ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            top: "40%",
            left: "50%",
          }}
        >
          <CircularProgress size="5rem" style={{color:'#21DA8C'}} />
        </div>
      ) : (
        <React.Fragment>
          <div className="togglebar">
            <div style={{paddingLeft:'15px'}}>
              <Toggler setSelectedFrequency={setSelectedFrequency} />
            </div>
            <div className="thresholdblock">
              <SimpleTextField
                textfieldLabel={textfieldLabel}
                label={thresholdScoreLabel}
                inputValue={
                  thresholdScore?.individualThreshold
                    ?.individualEngagementThresholdScore
                }
                threshold={true}
                valueFunc={thresholdValueFunc}
              />
            </div>
          </div>
          <TableContainer
            component={Paper}
            style={{ height: "57vh",boxShadow:'none'}}
          >
            <Table
              // size="small"
              className={classes.table}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead style={isContextEnable==="true"?{position:"sticky",top:"0px", zIndex:"1",background:"#FFFFFF"}:{}}>
              {isContextEnable==="true"?
              <div style={{display:"flex",flexDirection:'row',marginLeft:"43px",position: "sticky !important",top: "10px",marginTop:'10px',zIndex:1}}>
              <Typography classes={{ root: classes.root }}  >Engagement Type</Typography>
              <Typography  style={{width:"140px",marginRight:"15px"}} classes={{ root: classes.root }} >Weightage</Typography>
              <Typography style={{width:"175px"}} classes={{ root: classes.root }} >Threshold Interval</Typography>
              <Typography  style={{width:"140px",marginRight:"25px"}} classes={{ root: classes.root }}> Half Life{" "}</Typography>
              <Typography classes={{ root: classes.root }}> Repetition Half Life</Typography>
              </div>
:                 <TableRow>
                  <TableCell classes={{ root: classes.root }}  align="left">
                    Engagement Type
                  </TableCell>
                  <TableCell classes={{ root: classes.root }} align="cent">
                    Weightage
                  </TableCell>
                  <TableCell classes={{ root: classes.root }} align="center">
                    Threshold Interval
                  </TableCell>
                  <TableCell classes={{ root: classes.root }} align="center">
                    Half Life{" "}
                  </TableCell>
                  <TableCell classes={{ root: classes.root }} align="center">
                    Repetition Half Life
                  </TableCell>
                </TableRow>}

                
              </TableHead>
              <TableBody>
                {selectedFrequency === "All"
                  ? rows?.engagementConfig?.map((row,index) => (
                    <>
                    {isContextEnable==="true"?
                    <Paper 
                  //   style={{margin:"10px" ,
                  //   border: "1px solid",
                  //   borderImageSource: "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),linear-gradient(0deg, #004B6B, #004B6B)"  
                  // }}
                   style={{ margin: '20px 16px',borderRadius:'8px',border:"1px solid #ABBBD8" }}
                   elevation={0}
                  >  
                    
                    <Accordion  className={classes.noRotate} expanded={isOpen===row.rowId} >
                    
                    <AccordionSummary classes={{ root: classes.summary }} expandIcon={<Button  className={classes.button}  onClick={(e) => {
                    // setIsOpen(index)
                    e.stopPropagation();
                    toggleAccordion(row.rowId);
                  }} style={{backgroundColor:'none',outline:'none'}}>
                    {initialData&&Array.isArray(initialData[row?.rowId]?.activityRule)&&initialData[row?.rowId]?.activityRule?.length>0&&initialData[row?.rowId]?.activityRule[0]?.attribute!==undefined&&initialData[row?.rowId]?.activityRule[0]?.attribute?.trim()?<>Edit Rule</>:<>Add Rule</>}
                  {isOpen===row.rowId?<KeyboardArrowUpIcon />:<KeyboardArrowDownIcon />}
                  </Button>}>
                     
                    <TableRow >
                        <TableCell
                          classes={{ root: classes.root }}
                          component="th"
                          scope="row"
                          // style={{width:"200px",paddingLeft:"0px !important"}}
                        >
                         <div className="imgblock">
                             {initialData&&Array.isArray(initialData[row?.rowId]?.activityRule)&&initialData[row?.rowId]?.activityRule?.length>0&&initialData[row?.rowId]?.activityRule[0]?.attribute!==undefined&&initialData[row?.rowId]?.activityRule[0]?.attribute?.trim()?<CheckCircleIcon  style={{position:"absolute",top:"-7px",left:"-5px",color:"#21DA8C"}}/>:<></>}
                              <div style={{paddingRight:'8px'}}>
                              <img
                                width="20"
                                height="20"
                                src={`${B2_INTENT_SETTING}/logos/${row.source?.toLowerCase()}.png`}
                              ></img>
                              </div>
                              <LightTooltip title= {row.engagementId.engagementType}>
                              <Typography className={classes.typography} >
                              {row.engagementId.engagementType}
                              </Typography>
                              </LightTooltip>
                            </div>
                          {/* {getImage()} */}
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.root }}
                          align="right"
                        >
                          <RatingLevelComboBoxNew
                            rowId={row.rowId}
                            disable={enable}
                            comboBoxData={importanceLevel.level}
                            label={importanceLabel}
                            inputValue={row.weightage}
                            valueFunc={ratingValueFunc}
                          />
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.root }}
                          align="center"
                        >
                          <SimpTextField
                            rowId={row.rowId}
                            placeHolderValue="00"
                            endAdornment={"min"}
                            label={thresholdIntervalLabel}
                            inputValue={
                              row.thresholdInterval ||
                              0
                            }
                            valueFunc={ratingValueFunc}
                          />
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.root }}
                          align="center"
                        >
                          <SimpTextField
                            rowId={row.rowId}
                            placeHolderValue="00"
                            endAdornment={"days"}
                            label={halfLifeLabel}
                            inputValue={row.halfLife}
                            valueFunc={ratingValueFunc}
                          />
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.root }}
                          align="center"
                        >
                          <SimpTextField
                            rowId={row.rowId}
                            placeHolderValue="00"
                            endAdornment={"count"}
                            label={repetitionHalfLifeLabel}
                            inputValue={row.repititionHalfLife}
                            valueFunc={ratingValueFunc}
                          />
                        </TableCell>
                      </TableRow>
                    </AccordionSummary>
                    
                    <AccordionDetails>
                    <Typography className={classes.typo}>Engagement Where</Typography>
                    <Paper style={{ margin: '16px', padding: '16px',borderRadius:'8px',border:"1px solid #ABBBD8" }} elevation={0}>
                    {Array.isArray(row?.activityRule)&&(row?.activityRule[0]?.relation?.toLowerCase()!=="between"&&row?.activityRule[0]?.value!==undefined&&row?.activityRule[0]?.value?.trim() ||
                    row?.activityRule[0]?.relation?.toLowerCase()==="between"&&Array.isArray(row?.activityRule[0]?.value)&&row?.activityRule[0]?.value[0]!==undefined&&row?.activityRule[0]?.value[0]?.trim()&&row?.activityRule[0]?.value[1]!==undefined&&row?.activityRule[0]?.value[1]?.trim()
                    )
                      &&<div className={
                        Array.isArray(row?.activityRule)&&(row?.activityRule[0]?.relation?.toLowerCase()!=="between"&&row?.activityRule[0]?.value!==undefined&&row?.activityRule[0]?.value?.trim())?
                        classes.boosterVal:classes.boosterValBet
                        
                      }>Booster Value</div>}
                    {Array.isArray(row?.activityRule)&&row?.activityRule?.map((rule,ruleIndex)=>(
                    <EngagementTabAddFieldComponent rule = {rule} rows = {rows} setRows = {setRows} index = { row.rowId } ruleIndex = {ruleIndex} 
                     attributeConfig={attributeConfig} relationConfig={relationConfig} initialData = {initialData} setInitialData = {setInitialData} errorDisabled ={errorDisabled}
                    buttonDisable = {buttonDisable} setButtonDisable = {setButtonDisable} setErrorDisable = {setErrorDisabled} isDisabled = {isDisabled(index)} activityType = {row?.engagementId?.engagementType}
                    />
                    ))}
                      </Paper>
                    <div className={classes.summary_and}>
                    
                    <Button 
                        // color="primary"
                        variant="outlined"
                        onClick={() => {addRuleCriteria(index)}}
                       disabled={isDisabled(row.rowId)}
                       className = {isDisabled(row.rowId)?classes.setRuleDisabled:classes.setRule}
                      // style={{ "background-color":"#21DA8C", height:"32px",width:"55px",fontSize:"14px",fontWeight:"500",borderRadius:"18px"}}
                        // style={{ "color":isDisabled(row.rowId)?"#001325":"#00A5A7", height:"42px",width:"168px",fontSize:"14px",fontWeight:"500",borderRadius:"8px"}}
                    >
                        Set New Rule
                    </Button>
                </div>
                    </AccordionDetails>
                    
                    </Accordion></Paper>:<TableRow>
                        <TableCell
                          classes={{ root: classes.root }}
                          component="th"
                          scope="row"
                        >
                         <div className="imgblock">
                              <div style={{paddingRight:'8px'}}>
                              <img
                                width="20"
                                height="20"
                                src={`${B2_INTENT_SETTING}/logos/${row.source?.toLowerCase()}.png`}
                              ></img>
                              </div>
                              {row.engagementId.engagementType}
                            </div>
                          {/* {getImage()} */}
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.root }}
                          align="right"
                        >
                          <RatingLevelComboBoxNew
                            rowId={row.rowId}
                            disable={enable}
                            comboBoxData={importanceLevel.level}
                            label={importanceLabel}
                            inputValue={row.weightage}
                            valueFunc={ratingValueFunc}
                          />
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.root }}
                          align="center"
                        >
                          <SimpTextField
                            rowId={row.rowId}
                            placeHolderValue="00"
                            endAdornment={"min"}
                            label={thresholdIntervalLabel}
                            inputValue={
                              row.thresholdInterval ||
                              0
                            }
                            valueFunc={ratingValueFunc}
                          />
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.root }}
                          align="center"
                        >
                          <SimpTextField
                            rowId={row.rowId}
                            placeHolderValue="00"
                            endAdornment={"days"}
                            label={halfLifeLabel}
                            inputValue={row.halfLife}
                            valueFunc={ratingValueFunc}
                          />
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.root }}
                          align="center"
                        >
                          <SimpTextField
                            rowId={row.rowId}
                            placeHolderValue="00"
                            endAdornment={"count"}
                            label={repetitionHalfLifeLabel}
                            inputValue={row.repititionHalfLife}
                            valueFunc={ratingValueFunc}
                          />
                        </TableCell>
                      </TableRow>}
                    </>
                    ))
                   
                  : selectedFrequency === "Contact"
                  ? rows.engagementConfig

                      .filter((item) => item.type === "contact")
                      .map((row,index) => (
                        <>
                        {isContextEnable==="true"?
                        <Paper  style={{ margin: '20px 16px',borderRadius:'8px',border:"1px solid #ABBBD8" }} elevation={0}><Accordion  className={classes.noRotate} expanded={isOpen===row.rowId} >
                    
                        <AccordionSummary  classes={{ root: classes.summary }} expandIcon={<Button className={classes.button}  onClick={(e) => {
                        // setIsOpen(index)
                        e.stopPropagation();
                        toggleAccordion(row.rowId);
                      }}>
                      {initialData&&Array.isArray(initialData[row?.rowId]?.activityRule)&&initialData[row?.rowId]?.activityRule?.length>0&&initialData[row?.rowId]?.activityRule[0]?.attribute!==undefined&&initialData[row?.rowId]?.activityRule[0]?.attribute?.trim()?<>Edit Rule</>:<>Add Rule</>}

                      {isOpen===row.rowId?<KeyboardArrowUpIcon />:<KeyboardArrowDownIcon />}
                      </Button>}>
                        <TableRow>
                          <TableCell
                            classes={{ root: classes.root }}
                            component="th"
                            scope="row"
                            // style={{width:"200px",paddingLeft:"0px !important"}}
                          >
                            <div className="imgblock">
                            {initialData&&Array.isArray(initialData[row?.rowId]?.activityRule)&&initialData[row?.rowId]?.activityRule?.length>0&&initialData[row?.rowId]?.activityRule[0]?.attribute!==undefined&&initialData[row?.rowId]?.activityRule[0]?.attribute?.trim()?<CheckCircleIcon  style={{position:"absolute",top:"-7px",left:"-5px",color:"#21DA8C"}}/>:<></>}
                              <div style={{paddingRight:'8px'}}>
                              <img
                                width="20"
                                height="20"
                                src={`${B2_INTENT_SETTING}/logos/${row.source?.toLowerCase()}.png`}
                              ></img>
                              </div>
                              <LightTooltip title= {row.engagementId.engagementType}>
                              <Typography className={classes.typography} >
                              {row.engagementId.engagementType}
                              </Typography>
                              </LightTooltip>
                            </div>

                            {/* {getImage()} */}
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.root }}
                            align="right"
                          >
                            <RatingLevelComboBoxNew
                              rowId={row.rowId}
                              disable={enable}
                              comboBoxData={importanceLevel.level}
                              label={importanceLabel}
                              inputValue={row.weightage}
                              valueFunc={ratingValueFunc}
                            />
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.root }}
                            align="center"
                          >
                            <SimpTextField
                              rowId={row.rowId}
                              placeHolderValue="00"
                              endAdornment={"min"}
                              label={thresholdIntervalLabel}
                              inputValue={
                                row.thresholdInterval ||
                                0
                              }
                              valueFunc={ratingValueFunc}
                            />
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.root }}
                            align="center"
                          >
                            <SimpTextField
                              rowId={row.rowId}
                              placeHolderValue="00"
                              endAdornment={"days"}
                              label={halfLifeLabel}
                              inputValue={row.halfLife}
                              valueFunc={ratingValueFunc}
                            />
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.root }}
                            align="center"
                          >
                            <SimpTextField
                              rowId={row.rowId}
                              placeHolderValue="00"
                              endAdornment={"count"}
                              label={repetitionHalfLifeLabel}
                              inputValue={row.repititionHalfLife}
                              valueFunc={ratingValueFunc}
                            />
                          </TableCell>
                        </TableRow>
                       
                       </AccordionSummary>
                    <AccordionDetails>
                    <Typography className={classes.typo}>Engagement Where</Typography>
                    <Paper  style={{ margin: '16px', padding: '16px',borderRadius:'8px' ,border:"1px solid #ABBBD8"}} elevation={0}>
                    {Array.isArray(row?.activityRule)&&(row?.activityRule[0]?.relation?.toLowerCase()!=="between"&&row?.activityRule[0]?.value!==undefined&&row?.activityRule[0]?.value?.trim() ||
                    row?.activityRule[0]?.relation?.toLowerCase()==="between"&&Array.isArray(row?.activityRule[0]?.value)&&row?.activityRule[0]?.value[0]!==undefined&&row?.activityRule[0]?.value[0]?.trim()&&row?.activityRule[0]?.value[1]!==undefined&&row?.activityRule[0]?.value[1]?.trim()
                    )
                      &&<div className={
                        Array.isArray(row?.activityRule)&&(row?.activityRule[0]?.relation?.toLowerCase()!=="between"&&row?.activityRule[0]?.value!==undefined&&row?.activityRule[0]?.value?.trim())?
                        classes.boosterVal:classes.boosterValBet
                      }>Booster Value</div>}
                    {Array.isArray(row?.activityRule)&&row?.activityRule?.map((rule,ruleIndex)=>(
                    <EngagementTabAddFieldComponent  rows = {rows} setRows = {setRows} index = {row.rowId} ruleIndex = {ruleIndex} 
                     attributeConfig={attributeConfig} relationConfig={relationConfig}  initialData = {initialData} setInitialData = {setInitialData} errorDisabled = {errorDisabled}
                    buttonDisable = {buttonDisable} setButtonDisable = {setButtonDisable}  setErrorDisable = {setErrorDisabled}  activityType = {row.engagementId.engagementType}
                    /> ))}
                    </Paper>
                    <div className={classes.summary_and}>
                    <Button 
                        variant="outlined"
                        onClick={() => {addRuleCriteria(index)}}
                        className = {isDisabled(row.rowId)?classes.setRuleDisabled:classes.setRule}

                         disabled={isDisabled(row.rowId)}
                    >
                        Add New Rule
                    </Button>
                </div>
                    </AccordionDetails>
                 
                    </Accordion></Paper>:<TableRow>
                          <TableCell
                            classes={{ root: classes.root }}
                            component="th"
                            scope="row"
                          >
                            <div className="imgblock">
                              <div style={{paddingRight:'8px'}}>
                              <img
                                width="20"
                                height="20"
                                src={`${B2_INTENT_SETTING}/logos/${row.source?.toLowerCase()}.png`}
                              ></img>
                              </div>
                              {row.engagementId.engagementType}
                            </div>

                            {/* {getImage()} */}
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.root }}
                            align="right"
                          >
                            <RatingLevelComboBoxNew
                              rowId={row.rowId}
                              disable={enable}
                              comboBoxData={importanceLevel.level}
                              label={importanceLabel}
                              inputValue={row.weightage}
                              valueFunc={ratingValueFunc}
                            />
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.root }}
                            align="center"
                          >
                            <SimpTextField
                              rowId={row.rowId}
                              placeHolderValue="00"
                              endAdornment={"min"}
                              label={thresholdIntervalLabel}
                              inputValue={
                                row.thresholdInterval ||
                                0
                              }
                              valueFunc={ratingValueFunc}
                            />
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.root }}
                            align="center"
                          >
                            <SimpTextField
                              rowId={row.rowId}
                              placeHolderValue="00"
                              endAdornment={"days"}
                              label={halfLifeLabel}
                              inputValue={row.halfLife}
                              valueFunc={ratingValueFunc}
                            />
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.root }}
                            align="center"
                          >
                            <SimpTextField
                              rowId={row.rowId}
                              placeHolderValue="00"
                              endAdornment={"count"}
                              label={repetitionHalfLifeLabel}
                              inputValue={row.repititionHalfLife}
                              valueFunc={ratingValueFunc}
                            />
                          </TableCell>
                        </TableRow>}
                     </>
                      ))
                  : rows.engagementConfig
                      .filter((item) => item.type === "account")
                      .map((row,index) => (
                        <>
                        {isContextEnable==="true"?
                        <Paper  style={{ margin: '20px 16px',borderRadius:'8px',border:"1px solid #ABBBD8" }} elevation={0}>
                        <Accordion  className={classes.noRotate} expanded={isOpen===row.rowId} >
                    
                    <AccordionSummary classes={{ root: classes.summary }} expandIcon={<Button  className={classes.button}   onClick={(e) => {
                    // setIsOpen(index)
                    e.stopPropagation();
                    toggleAccordion(row.rowId);
                  }}>
                    {initialData&&Array.isArray(initialData[row?.rowId]?.activityRule)&&initialData[row?.rowId]?.activityRule?.length>0&&initialData[row?.rowId]?.activityRule[0]?.attribute!==undefined&&initialData[row?.rowId]?.activityRule[0]?.attribute?.trim()?<>Edit Rule</>:<>Add Rule</>}

                  {isOpen===row.rowId?<KeyboardArrowUpIcon />:<KeyboardArrowDownIcon />}
                  </Button>}>
                        
                        <TableRow>
                          <TableCell
                            classes={{ root: classes.root }}
                            component="th"
                            scope="row"
                            style={{width:"200px"}}
                          >
                            <div className="imgblock">
                            {initialData&&Array.isArray(initialData[row?.rowId]?.activityRule)&&initialData[row?.rowId]?.activityRule?.length>0&&initialData[row?.rowId]?.activityRule[0]?.attribute!==undefined&&initialData[row?.rowId]?.activityRule[0]?.attribute?.trim()?<CheckCircleIcon  style={{position:"absolute",top:"-7px",left:"-5px",color:"#21DA8C"}}/>:<></>}
                              <div style={{paddingRight:'8px'}}>
                              <img
                                width="20"
                                height="20"
                                src={`${B2_INTENT_SETTING}/logos/${row.source?.toLowerCase()}.png`}
                              ></img>
                              </div>
                              <LightTooltip title={row.engagementId.engagementType}>
                              <Typography className={classes.typography}>
                              {row.engagementId.engagementType}
                              </Typography>
                              </LightTooltip>
                            </div>
                            {/* {getImage()} */}
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.root }}
                            align="right"
                          >
                            <RatingLevelComboBoxNew
                              rowId={row.rowId}
                              disable={enable}
                              comboBoxData={importanceLevel.level}
                              label={importanceLabel}
                              inputValue={row.weightage}
                              valueFunc={ratingValueFunc}
                            />
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.root }}
                            align="center"
                          >
                            <SimpTextField
                              rowId={row.rowId}
                              placeHolderValue="00"
                              endAdornment={"min"}
                              label={thresholdIntervalLabel}
                              inputValue={
                                row.thresholdInterval ||
                                0
                              }
                              valueFunc={ratingValueFunc}
                            />
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.root }}
                            align="center"
                          >
                            <SimpTextField
                              rowId={row.rowId}
                              placeHolderValue="00"
                              endAdornment={"days"}
                              label={halfLifeLabel}
                              inputValue={row.halfLife}
                              valueFunc={ratingValueFunc}
                            />
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.root }}
                            align="center"
                          >
                            <SimpTextField
                              rowId={row.rowId}
                              placeHolderValue="00"
                              endAdornment={"count"}
                              label={repetitionHalfLifeLabel}
                              inputValue={row.repititionHalfLife}
                              valueFunc={ratingValueFunc}
                            />
                          </TableCell>
                        </TableRow>
                        </AccordionSummary>                        
                        <AccordionDetails>
                        <Typography className={classes.typo}>Engagement Where</Typography>
                        <Paper style={{ margin: '16px', padding: '16px',borderRadius:'8px',border:"1px solid #ABBBD8" }} elevation={0}>
                        {Array.isArray(row?.activityRule)&&(row?.activityRule[0]?.relation?.toLowerCase()!=="between"&&row?.activityRule[0]?.value!==undefined&&row?.activityRule[0]?.value?.trim() ||
                    row?.activityRule[0]?.relation?.toLowerCase()==="between"&&Array.isArray(row?.activityRule[0]?.value)&&row?.activityRule[0]?.value[0]!==undefined&&row?.activityRule[0]?.value[0]?.trim()&&row?.activityRule[0]?.value[1]!==undefined&&row?.activityRule[0]?.value[1]?.trim()
                    )
                      &&<div className={
                        Array.isArray(row?.activityRule)&&(row?.activityRule[0]?.relation?.toLowerCase()!=="between"&&row?.activityRule[0]?.value!==undefined&&row?.activityRule[0]?.value?.trim())?
                        classes.boosterVal:classes.boosterValBet
                      }>Booster Value</div>}
                        {Array.isArray(row?.activityRule) && row?.activityRule?.map((rule,ruleIndex)=>(
                        <EngagementTabAddFieldComponent  rows = {rows} setRows = {setRows} index = {row.rowId} ruleIndex = {ruleIndex} 
                         attributeConfig={attributeConfig} relationConfig={relationConfig} initialData = {initialData} setInitialData = {setInitialData} errorDisabled = {errorDisabled}
                        buttonDisable = {buttonDisable} setButtonDisable = {setButtonDisable} setErrorDisable = {setErrorDisabled} activityType = {row.engagementId.engagementType}
                        />
                        ))}
                        </Paper>
                        <div className={classes.summary_and}>
                    <Button 
                        variant="outlined"
                        onClick={() => {addRuleCriteria(index)}}
                        className = {isDisabled(row.rowId)?classes.setRuleDisabled:classes.setRule}
                       disabled={isDisabled(row.rowId)}
                    >
                        Add New Rule
                    </Button>
                </div>
                        </AccordionDetails>
                        </Accordion></Paper>:<TableRow>
                          <TableCell
                            classes={{ root: classes.root }}
                            component="th"
                            scope="row"
                          >
                            <div className="imgblock">
                              <div style={{paddingRight:'8px'}}>
                              <img
                                width="20"
                                height="20"
                                src={`${B2_INTENT_SETTING}/logos/${row.source?.toLowerCase()}.png`}
                              ></img>
                              </div>
                              {row.engagementId.engagementType}
                            </div>
                            {/* {getImage()} */}
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.root }}
                            align="right"
                          >
                            <RatingLevelComboBoxNew
                              rowId={row.rowId}
                              disable={enable}
                              comboBoxData={importanceLevel.level}
                              label={importanceLabel}
                              inputValue={row.weightage}
                              valueFunc={ratingValueFunc}
                            />
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.root }}
                            align="center"
                          >
                            <SimpTextField
                              rowId={row.rowId}
                              placeHolderValue="00"
                              endAdornment={"min"}
                              label={thresholdIntervalLabel}
                              inputValue={
                                row.thresholdInterval ||
                                0
                              }
                              valueFunc={ratingValueFunc}
                            />
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.root }}
                            align="center"
                          >
                            <SimpTextField
                              rowId={row.rowId}
                              placeHolderValue="00"
                              endAdornment={"days"}
                              label={halfLifeLabel}
                              inputValue={row.halfLife}
                              valueFunc={ratingValueFunc}
                            />
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.root }}
                            align="center"
                          >
                            <SimpTextField
                              rowId={row.rowId}
                              placeHolderValue="00"
                              endAdornment={"count"}
                              label={repetitionHalfLifeLabel}
                              inputValue={row.repititionHalfLife}
                              valueFunc={ratingValueFunc}
                            />
                          </TableCell>
                        </TableRow>}
                        </>
                      ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="buttonflexbox">
            <Button
              variant="contained"
              // classes={{ contained: classes.setRule }}
              disabled={buttonDisable || errorDisabled==2}
              // disabled = {false}
              size="middle"
              startIcon={<CheckIcon />}
              style={!buttonDisable&&errorDisabled!=2?{
               position:'relative',
                float: 'right',
                background:"#004B6B",
                color:"#ffffff",
                textTransform: "none",
                borderRadius: "8px",
                right:'6%',
                marginTop:'20px'
              }:{ position:'relative',
              float: 'right',
              textTransform: "none",
              borderRadius: "8px",
              right:'6%',
              marginTop:'20px'}}
              // className={classes.setRule}
              onClick={() => onUpdateEngagement()}
            >
              UPDATE
            </Button>
          </div>
        </React.Fragment>
      )}
      <Dialog
        open={props.enableDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            There are unsaved changes. Do you want to update?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.button}
            onClick={handleSave}
            color="primary"
            variant="outlined"
          >
            Yes
          </Button>
          <Button
            classes={{ contained: classes.contained }}
            className={classes.button}
            onClick={handleClose}
            color="primary"
            variant="contained"
            autoFocus
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default EngagementTypeTable;
