import { Typography } from '@mui/material';
import * as React from 'react';
// import TextField from "@material-ui/core/TextField";
import { CustomAutoComplete } from './CustomAutoComplete';
import { DeleteButton } from './DeleteButton';


export const FilterAttribute=({options,name,selectedWeightage,updateWeightage,disabled,deleteCondition})=>{
    console.log("props from data",name);
    return(
        <>
            <div style={{width:"800px"}}>
                <div style={{display:"flex",alignItems:"center"}}>
                    <Typography variant='h6' sx={{fontSize:"16px",margin:"10px",marginRight:"4px",fontFamily:"Nunito Sans",fontWeight:800}}>{name}</Typography>
                    <Typography variant='body1' sx={{margin:"10px",marginLeft:"4px",fontFamily:"Nunito Sans"}}>is weighed</Typography>
                    <CustomAutoComplete styles={{width:250}} options={options} variant={"outlined"} selected={selectedWeightage} updateSelected={updateWeightage} disabled={disabled} multiple={false}/>
                    <DeleteButton handleDelete={deleteCondition} name={name} showDailog={true}/>
                </div>
            </div>
        </>
    )
}