import React, { useEffect, useState } from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {FilterPage} from './FilterPage';
import ClearIcon from '@mui/icons-material/Clear';
import { CustomLabel } from './CustomLabel';
import axios from "axios"
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Alert from '@mui/material/Alert';
import { REACT_APP_API_URL } from "../../Constant";
import { useAuthState } from "@bamboobox/b2logincheck"
import './filterTabs.css'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 ,paddingLeft:"0px",paddingRight:"0px"}}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export const FilterTabs=(props)=>{
  const userData = useAuthState()
  toast.configure({
    autoClose: 6000,
    draggable: true,
    hideProgressBar: false,
    position: toast.POSITION.BOTTOM_LEFT,
    icon: false,
  });
    console.log("filters coming from showFilters",props);
    const [filterConditions,setFilterConditions]=useState(props.filterCondition);
    const IcpValuesData=props.valuesData?.filter((obj)=>(obj.ICP?.toLowerCase()==='yes' && obj.name));
    const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleDeleteCondition=(condition,name)=>{
    console.log("big delete object",condition);
    setFilterConditions((latestData)=>{
        const newData=[...latestData];
        newData.map((obj)=>{
            if(obj.filterName===name){
                obj.icpCondition=condition;
                return obj;
            }
        })
        console.log("latest obj is",newData);
        return newData;
    })
    
  }

  const onDelete = (filterId) => {
    try {
        axios.delete(REACT_APP_API_URL + '/filter/' + userData.user.tenantId + '/' + props.icpName + '/delete/' + filterId, { withCredentials: true }).then((response) => {
                toast.success(<Alert severity="success">ICP filter got deleted successfully</Alert>,{
                    closeOnClick: true,
                    autoClose: true,
                    closeButton: true,
                    newestOnTop: true
                  });
            })
            props.setRender(true)
    } catch (error) {
        console.log(error)
    }
}
  
  
    return (
        <div className='customTabUi'>
        <Tabs  
        value={value} 
        onChange={handleChange} 
        aria-label="basic tabs example"
        >
        {
            filterConditions.map((condition,index)=>{
                if(condition.filterName==='default'){
                    return(
                    <Tab key={condition.filterName}  label={"DEFAULT CRITERIA"} {...a11yProps(index)}/>
                )
                }else{
                    return(
                    <Tab key={condition.filterName}  label={<CustomLabel onDelete={onDelete} id={condition.filterId}  name={condition.filterName}/>} {...a11yProps(index)}/>
                )
                }
            })
        }
        </Tabs>
        {
            filterConditions.map((condition,index)=>{
                return(
                    <TabPanel value={value} index={index} >
                    <FilterPage setRender={props.setRender} render={props.render} icpName={props.icpName} key={condition.filterName} filtersData={condition} ValuesData={IcpValuesData} newAttributes={props.newAttributes} handleDeleteCondition={handleDeleteCondition} />
                </TabPanel>
                )
                
            })
        }
        </div>
        
    )
}