import ClearIcon from '@mui/icons-material/Clear';
import './customLabel.css'
import React from 'react';
import IconButton from '@mui/material/IconButton';
export const CustomLabel=({name,id,onDelete})=>{
    return(
        <div className='tab-label'>
        <p>{name}</p>
        <div className='icon'>
        <IconButton onClick={()=>onDelete(id)}>
        <ClearIcon fontSize='small' />
        </IconButton>
        </div>
        
        
    </div>
    )
    
}