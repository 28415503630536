import {TENANTCONFIG_API} from "./../Constant"



const setTenantConfig = async(tenantId,tool,tenantConfigKeysValues) => {

    try {
    
        const tenantConfigResponse = await fetch(`${TENANTCONFIG_API}/tenant/set-tenant-config`,{
            method: "POST",
            headers : {
                "Content-Type": "application/json"
            },
            body : JSON.stringify({
                "tenantId" : tenantId,
                "tool" : tool,
                "configArray" : tenantConfigKeysValues
            }),
            credentials: "include"
        });
        const tenantConfigResponseJson = await tenantConfigResponse.json();
        return tenantConfigResponseJson.success;

    }
    catch(err) {

        console.error(`Error occured in setTenantConfig for ${tenantId} , tool -> ${tool} , tenantConfigKeys -> ${tenantConfigKeysValues}:  `,err)
        return false;

    }

}

export default  setTenantConfig