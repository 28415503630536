import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { styled } from "@mui/system";
import setTenantConfig  from '../utility/setTenantConfig';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Alert from '@mui/material/Alert';
import { useAuthState } from "@bamboobox/b2logincheck";


const Dialog1 = styled(Dialog)(({ theme }) => ({

  [`.MuiPaper-rounded`]:{
    borderRadius: '18px',
  },
}))

const createBulkJob = async(tenantId,selection,oldSelection) => {
  try {
      const jobCreationResp = await fetch(`${window._env_.CONTACT_TITLE_TO_PERSONA}/jobs/jobQueue/${tenantId}`,{
          method: "POST",
          headers : {
              "Content-Type": "application/json"
          },
          body : JSON.stringify({
              "tenantId" : tenantId,
              "task": "TITLE_TO_PERSONA_BULK",
              "enabled": true,
              "persistent": true,
              "selection":selection,
              "oldSelection":oldSelection
          }),
          credentials: "include"
      });
      const jobCreationRespJson = await jobCreationResp.json();
      return jobCreationRespJson;

  }
  catch(err) {
      console.error(`Error occurred in createBulkJob for ${tenantId} `,err);
      return false;
  }

}

const AlertDialogSwitch = (props) => {
  //console.log(props)
  //const [open, setOpen] = React.useState(props.alertDialogStatus);
  const userData = useAuthState();
   
  const confirmSwitchConfg = async(e)=>{
      let overAllSuccessFlag = false;
      console.log("switch Selected Value",e)
      const switchValueSet =  await setTenantConfig(userData.user.tenantId,"CONTACT_DS",[{tenantConfigKey:"SELECTED_TITLE_PREDICTION",tenantConfigValue:e}]);
      if(switchValueSet){
        let jobCreation = await createBulkJob(userData.user.tenantId,e,props.switchValue);
        if(jobCreation?.status?.toLowerCase() === 'pending'){
          overAllSuccessFlag = true
        }else{
          const switchValueSet =  await setTenantConfig(userData.user.tenantId,"CONTACT_DS",[{tenantConfigKey:"SELECTED_TITLE_PREDICTION",tenantConfigValue:props.switchValue}]);
        }
      }

      if(overAllSuccessFlag)
      {
        toast.success(<Alert severity="success">Configuration added successfully</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
        props.setSwitchValue(e)
      }
      else{
        toast.error(<Alert severity="error">Configuration added unsuccessfully</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
      console.log("switchValueSet",switchValueSet) 
  }
}



  const handleClickOpen = () => {
    //setOpen(true);
    props.setChangeSwitchStatus(false) 
    confirmSwitchConfg(props.switchValueSelected)
    // props.setChangeSwitch(true)
  };

  const handleClose = () => {
    props.setChangeSwitchStatus(false)
    // props.setChangeSwitch(false)
    //setOpen(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog1
        open={props.changeSwitchStatus}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Update Persona Configuration"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to update the configuration? It might take upto 24 hours for the changes to reflect.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{display:'flex',justifyContent: 'space-evenly',marginBottom: '20px'}}>
          <Button onClick={handleClose} color="primary"
          
          style={{ 
            color: '#21DA8C',
            border: '1px solid rgba(33, 218, 140, 0.5)'
          }}
          >
            NO
          </Button>
          <Button onClick={handleClickOpen} color="primary" autoFocus
          style={{
            color: '#ffffff',
            background: '#21DA8C'
          }}
          >
            YES
          </Button>
        </DialogActions>
      </Dialog1>
    </div>
  );
}


export default AlertDialogSwitch
