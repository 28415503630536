import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import getTenantConfig from '../utility/getTenantConfig'
import setTenantConfig  from '../utility/setTenantConfig';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import AlertDialogSwitch from './AlertDialogSwitch'
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import EngagementComboBox from "./EngagementComboBox";
import RatingLevelComboBox from "./RatingLevelComboBox";
import { useAuthState } from "@bamboobox/b2logincheck";
import { Typography, Box } from "@material-ui/core";
import axios from "axios";
import { REACT_APP_API_URL2 } from "./../Constant";
import Button from '@mui/material/Button';
import Icon from "@material-ui/core/Icon";
import CheckIcon from '@mui/icons-material/Check';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Alert from '@mui/material/Alert';
import InfoIcon from '@mui/icons-material/Info';
import Divider from '@material-ui/core/Divider';
import "./../toast.css"
toast.configure({
  autoClose: 6000,
  draggable: true,
  hideProgressBar: false,
  position: toast.POSITION.BOTTOM_LEFT,
  icon: false,
});

const useStyles = makeStyles({
  table: {
    minWidth: 300,
    border:'none'
  },
  root: {
    width:'9vw',
    border: "none",
    fontFamily:'Nunito Sans'
  },
  contained: {
    backgroundColor: "#82B440"
  }
  ,
   header:{
    width: '100%',
    display: 'flex',
    background: 'linear-gradient(0deg, rgba(55, 234, 158, 0.08), rgba(55, 234, 158, 0.08)), #FFFFFF !important',
    height: '63px',
    borderRadius: '20px 20px 0px 0px ',
    justifyContent: 'left',
    alignItems: 'center',
  },
  button:{
    background: '#21DA8C',
    borderRadius: '18px',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '24px',
    /* identical to box height, or 171% */

    letterSpacing: '0.4px',
    textTransform: 'uppercase',

    /* primary / contrastText */

    color: '#FFFFFF',
    marginRight: '32px'

  },
  selected: {
    borderRadius:"16px !important",
    background: "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #21DA8C !important"

    // background:"#d2f8d2 !important" 
},
text:{
  fontStyle: 'normal',
fontWeight: '700',
fontSize: '14px',
lineHeight: '157%',
/* identical to box height, or 22px */

letterSpacing: '0.1px',

color:'#000000',
margin:'14px'
}
});

const personaLevelLabel = "Persona Level"
const departmentLabel = "Department"
const importanceLabel = "Importance"
const importanceLevel = {
  "level": [
    {
      "levelName": "Very High",
      "levelRating": 4
    },
    {
      "levelName": "High",
      "levelRating": 3
    },
    {
      "levelName": "Medium",
      "levelRating": 2
    },
    {
      "levelName": "Low",
      "levelRating": 1
    },
    {
      "levelName": "Very Low",
      "levelRating": 0
    }
  ]
};

const AttributeList = ["ONLY B2","ONLY CRM","MIXED"]
const attributesDetails = {"ONLY B2":"Values will be updated as per Bamboobox's prediction logic","ONLY CRM":'Values will be updated as per CRM records',"MIXED":'Wherever empty, values will be updated as per Bamboobox\'s prediction logic. CRM values will always take precedence'}

const PersonaDepartmentTable = (props) => {

  const [rows, setRows] = React.useState();
  const userData = useAuthState();
  const [personaLevel, setPersonaLevel] = useState([])
  const [department, setDepartment] = useState([])
  const [changeSwitchStatus,setChangeSwitchStatus] = useState(false)

  const [switchValue,setSwitchValue] = useState()
  const [switchValueSelected,setSwitchValueSelected] = useState()

  const getSwitchConfig = async()=>{

      const switchValueSet =  await getTenantConfig(userData.user.tenantId,"CONTACT_DS",["SELECTED_TITLE_PREDICTION"])
      console.log("switchValueSet",switchValueSet) 
      if(parseInt(switchValueSet.length)>0)
       {
        setSwitchValue(switchValueSet[0].configVal)
       }
       else{
        const switchValueSet =  await setTenantConfig(userData.user.tenantId,"CONTACT_DS",[{tenantConfigKey:"SELECTED_TITLE_PREDICTION",tenantConfigValue:"ONLY CRM"}])
        setSwitchValue('ONLY CRM')
        console.log("adding defaul config",switchValueSet)
       }


  }

 
  
  const setSwitchConfig = async(e)=>{
    setSwitchValueSelected(e)
     setChangeSwitchStatus(true)

}

  const updateRows = async () => {
    await axios.get(REACT_APP_API_URL2 + '/personaconfig/' + userData.user.tenantId + '/' + props.icpname, { withCredentials: true }).then((result) => {
      let personaDepartmentRowId = [];
      for (let personaDepartment of result.data.personaConfig) {
        personaDepartmentRowId.push(personaDepartment.rowId);
      }
      personaDepartmentRowId.sort(function (a, b) { return b - a });
      let newRowId = personaDepartmentRowId[0] + 1;
      setPersonaValue()
      setDepartmentValue()

      let newPersonaDepartment = {
        "personaid": {
          "b2level": "",
          "department": "",
          "icp": props.icpname
        },
        "weightage": 0,
        "rowId": newRowId
      }

      result.data.personaConfig.push(newPersonaDepartment);
      setRows(result.data)
      onEdit(newRowId)
    });
  }

  useEffect(() => {
    updateRows();
  }, []);
  
  useEffect(() => {
    updateRows();
  }, [props.icpname]);
  useEffect(()=>{
    getSwitchConfig()
  },[switchValue,props.icpname])
  const setDepartmentValue = async () => {
    let departmentValues = []
    let departmentObject
    await axios.get(REACT_APP_API_URL2 + '/personaconfig/departmentList', { withCredentials: true }).then((result) => {
      result.data.forEach((department) => {
        departmentObject = {
          name: department.parent_value
        }

        departmentValues.push(departmentObject)
      })
      console.log(departmentValues)
      setDepartment(departmentValues)
      console.log(department)

    })

  }

  // useEffect(() => {
  //   setDepartmentValue();
  // }, []);


  const setPersonaValue = async () => {
    let personaValues = []
    let personaObject
    await axios.get(REACT_APP_API_URL2 + '/personaconfig/personaList', { withCredentials: true }).then((result) => {
      result.data.forEach((persona) => {
        personaObject = {
          name: persona.parent_value
        }

        personaValues.push(personaObject)
      })
      setPersonaLevel(personaValues)
      console.log(personaLevel)
    })

  }

  // useEffect(() => {
  //   setPersonaValue();
  // }, []);

  const classes = useStyles();

  const [disable, setDisable] = useState(true);
  const [enable, setEnable] = useState(false);

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowId: null,
    disabled: true
  })

  const onEdit = (rowId) => {
    setInEditMode({
      status: true,
      rowId: rowId,
      disabled: false
    })
  }

  const onCancel = () => {
    setInEditMode({
      status: false,
      rowId: null,
      disabled: true
      // recordId: recordid
    })
  }

  const personDepartmentValueFunc = (personaDepartmentValue, type, rowId) => {
    //console.log(personaDepartmentValue);
    const rowsData = { ...rows };
    let departmentValue;
    let b2levelValue;
    let importanceValue;
    let indexOfSelectedPersonaDepartmentComboBox = rowsData.personaConfig.findIndex(function (i) {
      return i.rowId === rowId;
    })
    if (type === 'Department') {
      departmentValue = personaDepartmentValue;
      rowsData.personaConfig[indexOfSelectedPersonaDepartmentComboBox].personaid.department = departmentValue;
    }
    else if (type === "Persona Level") {
      b2levelValue = personaDepartmentValue;
      rowsData.personaConfig[indexOfSelectedPersonaDepartmentComboBox].personaid.b2level = b2levelValue;
    }
    else {
      importanceValue = personaDepartmentValue;
      rowsData.personaConfig[indexOfSelectedPersonaDepartmentComboBox].weightage = importanceValue;

    }




    // setRows({
    //   ...rowsData,
    //   personaConfig: [...rowsData.personaConfig]
    // });
    setRows(rowsData);

  }

  const onAddNewPersonaDepartment = (rowId) => {
    //postcall for saving in the backend
    const rowsData = { ...rows };
    let indexOfEditablePersonaDepartment = rows.personaConfig.findIndex(function (i) {
      return i.rowId === rowId;
    })
    if(rows.personaConfig[indexOfEditablePersonaDepartment]?.personaid?.b2level=="" || rows.personaConfig[indexOfEditablePersonaDepartment]?.personaid?.department==""){
      console.log("rows.personaConfig[indexOfEditablePersonaDepartment] condition",rows.personaConfig[indexOfEditablePersonaDepartment]);
      toast.warn(<Alert severity="warning">persona Level and department attribute are required</Alert>,{
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true
      });
      return;
    }
    else{
      props.setBackdropStateFromChildComponent(true)
    axios.post(REACT_APP_API_URL2 + '/personaconfig/' + userData.user.tenantId + '/' + props.icpname, rows.personaConfig[indexOfEditablePersonaDepartment], { withCredentials: true }).then((result) => {
      props.setBackdropStateFromChildComponent(false)

      if (result.data.code === "-1") { //If the combination is already present
        const rowsData = { ...rows };
        rowsData.personaConfig.splice(rowsData.personaConfig.findIndex(function (i) {
          return i.rowId === rowId;
        }), 1);


        setRows({
          ...rowsData,
          personaConfig: [...rowsData.personaConfig]
        });
        // props.snackbarCallbackToTab({
        //   open: true,
        //   severity: "warning",
        //   message: "Duplication of persona-department combination"
        // })
        toast.warn(<Alert severity="warning">Duplication of persona-department combination</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
      }
    });
    let newPersonaDepartment;

    let personaDepartmentRowId = []
    for (let personaDepartment of rowsData.personaConfig) {
      personaDepartmentRowId.push(personaDepartment.rowId);
    }
    personaDepartmentRowId.sort(function (a, b) { return b - a });
    let newRowId = personaDepartmentRowId[0] + 1;

    if (rowId === personaDepartmentRowId[0]) {
      newPersonaDepartment = {
        "personaid": {
          "b2level": "",
          "department": ""
        },
        "weightage": 0,
        "rowId": newRowId
      };
      onCancel();
      setRows({
        ...rowsData,
        personaConfig: [...rowsData.personaConfig, newPersonaDepartment]
      });
      onEdit(newRowId);
    }
    //we will take values of newPersonaDepartment in save button and in turn function and then set the row
    else {
      onCancel();
      setRows({
        ...rowsData,
        personaConfig: [...rowsData.personaConfig]
      });
    }
  }
  }

  const onDeletePersonaDepartment = (rowId, b2level, department) => {
    if(!(b2level==='default') || !(department==='default')){
      props.setBackdropStateFromChildComponent(true)
    console.log('b2level', encodeURIComponent(b2level))
    console.log('department', department)
    axios.delete(REACT_APP_API_URL2 + '/personaconfig/' + userData.user.tenantId + '/' + props.icpname + '/' + department, 
    { withCredentials: true,  data: { b2level: encodeURIComponent(b2level)} }).then((response) => {
      props.setBackdropStateFromChildComponent(false)
    });
    const rowsData = { ...rows };
    rowsData.personaConfig.splice(rowsData.personaConfig.findIndex(function (i) {
      return i.rowId === rowId;
    }), 1);


    setRows({
      ...rowsData,
      personaConfig: [...rowsData.personaConfig]
    });
    }
    console.log("You Cant delete")
    
  }

  return (
    <React.Fragment>
      {rows === undefined ?
        <div style={{ position: "absolute", top: "40%", left: "50%", display: "flex", justifyContent: "center" }}>
          <React.Fragment><CircularProgress size="5rem" style={{color:'#21DA8C'}} /></React.Fragment>
        </div> :
        <React.Fragment>
          <AlertDialogSwitch switchValue={switchValue} setSwitchValue={setSwitchValue} changeSwitchStatus={changeSwitchStatus} setChangeSwitchStatus={setChangeSwitchStatus} switchValueSelected={switchValueSelected}> </AlertDialogSwitch>
             <div className={classes.header}>
          {/* <Button
              variant="outlined"
              color="primary"
              className={classes.button}
                variant="contained"
                size="middle"
                startIcon={<CheckIcon />}
                style={{
                  float: "right",
                margin:'3%',
                borderRadius: "4px",
                background: '#21DA8C',
                borderRadius: '18px',
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '24px',
              
            
                letterSpacing: '0.4px',
                textTransform: 'uppercase',
            
              
            
                color: '#FFFFFF'
              }}
              startIcon={<Icon>add</Icon>}
              
            >
              New Weightage
            </Button> */}
            <p className={classes.text}>Department Persona Weightages</p>

          </div>
          <div style={{ display: "flex", flexWrap:"wrap", padding: "10px 12px", zIndex:"1",fontFamily:'Nunito sans',alignItems:'center'}}>
          <div style= {{paddingRight:'130px'}}>
          <Typography
          style = {{
            height: "24px",
            fontFamily: 'Nunito Sans',
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "150%",
            color: "#000000"
          }}
          >Generate Score Based On</Typography>
          </div>
          <div style={{ display: "flex", flexWrap:"wrap",justifyContent: "space-between", zIndex:"1",fontFamily:'Nunito sans',width:'60%'}}>
          {AttributeList.map((e) => (
                  <>
                    <Box style={switchValue === e ? { marginBottom:"15px", paddingRight: "8px", paddingLeft: "8px", paddingTop: "12px", paddingBottom: '3px', background: 'none', borderRadius: '5px',display:'flex' , alignItems:'center' } : {marginBottom:"15px", paddingLeft: "8px", paddingBottom: '3px', paddingTop: "12px", paddingRight: "8px",fontFamily:'Nunito sans',display:'flex' , alignItems:'center' }}>
                      <Typography style={ switchValue==e?({ color:"#595959", fontSize:"14px", display: 'flex',fontFamily:'Nunito sans',paddingRight:'5px' }):({ color:"#A0A0A0", fontSize:"14px",cursor: "pointer", display: 'flex',fontFamily:'Nunito sans',paddingRight:'5px' })}  onClick={() => { setSwitchConfig(e)}} >{switchValue==e?<RadioButtonCheckedIcon stage={e} sx={{color:"#016B40"}} />:<RadioButtonUncheckedIcon stage={e} />}<span style={switchValue === e ? { fontWeight: '500', padding: '2px',fontFamily:'Nunito sans' } : { padding: '2px' , fontFamily:'Nunito sans' }}>{e}</span></Typography>
                     <Tooltip title= {attributesDetails[e]}>
                     <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z" fill="#030303" fill-opacity="0.6"/>
                      </svg>
                    </Tooltip>
                    </Box>
                  </>
                ))}
          </div>
          </div>
          <div
           style={{
            width: '88%',
            marginLeft: '15px',
            marginBottom: '12px'
           }}
          >         
            <Divider/>
            </div>
          <TableContainer style={{ height:'370px'}}>
            <Table className={classes.table} aria-label="simple table" >
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.root }}>{props.configMapper.department == undefined?"Department":props.configMapper.department.label}</TableCell>
                  <TableCell classes={{ root: classes.root }} align="left">{props.configMapper.personalevel == undefined?"Persona Level":props.configMapper.personalevel.label}</TableCell>
                  <TableCell classes={{ root: classes.root }} align="left">Weightage</TableCell>
                  <TableCell classes={{ root: classes.root }} align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.personaConfig.map((row) => (
                  <TableRow key={row.rowId}>

                    <React.Fragment>
                      <TableCell classes={{ root: classes.root }} component="th" scope="row" >
                        {
                          inEditMode.status === true && inEditMode.rowId === row.rowId ?
                            row.personaid.department !== 'default' && row.personaid.b2level !== 'default' ?
                              <EngagementComboBox rowId={row.rowId} comboBoxData={department} label={departmentLabel} disable={enable} inputValue={row.personaid.department} valueFunc={personDepartmentValueFunc} /> :
                              <EngagementComboBox rowId={row.rowId} comboBoxData={department} label={departmentLabel} disable={disable} inputValue={row.personaid.department} valueFunc={personDepartmentValueFunc} />
                            :
                            <EngagementComboBox rowId={row.rowId} comboBoxData={department} label={departmentLabel} disable={disable} inputValue={row.personaid.department} valueFunc={personDepartmentValueFunc} />
                        }
                      </TableCell>

                      <TableCell classes={{ root: classes.root }} align="left" >
                        {
                          inEditMode.status === true && inEditMode.rowId === row.rowId ?
                          row.personaid.department !== 'default' && row.personaid.b2level !== 'default' ?
                          <EngagementComboBox rowId={row.rowId} disable={enable} comboBoxData={personaLevel} label={personaLevelLabel} inputValue={row.personaid.b2level} valueFunc={personDepartmentValueFunc} />:
                            <EngagementComboBox rowId={row.rowId} disable={disable} comboBoxData={personaLevel} label={personaLevelLabel} inputValue={row.personaid.b2level} valueFunc={personDepartmentValueFunc} /> 
                            :
                            <EngagementComboBox rowId={row.rowId} disable={disable} comboBoxData={personaLevel} label={personaLevelLabel} inputValue={row.personaid.b2level} valueFunc={personDepartmentValueFunc} />
                        }
                      </TableCell>
                      <TableCell classes={{ root: classes.root }} align="left" >
                        {
                          inEditMode.status === true && inEditMode.rowId === row.rowId ?

                            <RatingLevelComboBox rowId={row.rowId} disable={enable} comboBoxData={importanceLevel.level} label={importanceLabel} inputValue={row.weightage} valueFunc={personDepartmentValueFunc} /> :
                            <RatingLevelComboBox rowId={row.rowId} disable={disable} comboBoxData={importanceLevel.level} label={importanceLabel} inputValue={row.weightage} valueFunc={personDepartmentValueFunc} />

                        }
                      </TableCell>
                    </React.Fragment>

                    <TableCell classes={{ root: classes.root }} style={{display: 'flex'}}>
                      {
                        inEditMode.status === true && inEditMode.rowId === row.rowId ?

                          <React.Fragment>
                            <IconButton aria-label="edit" disabled className={classes.margin}>
                              <EditIcon />
                            </IconButton>
                            <Tooltip title="Save" arrow>
                              <IconButton aria-label="save" className={classes.margin} onClick={() => onAddNewPersonaDepartment(row.rowId)}>
                                <SaveIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                              </IconButton>
                            </Tooltip>
                            <IconButton aria-label="delete" disabled wclassName={classes.margin}>
                              <DeleteIcon />
                            </IconButton>
                          </React.Fragment> :

                          <React.Fragment>
                            <Tooltip title="Edit" arrow>
                              <IconButton aria-label="edit" className={classes.margin} onClick={() => onEdit(row.rowId)}>
                                <EditIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                              </IconButton>
                            </Tooltip>
                            <IconButton aria-label="save" disabled className={classes.margin}>
                              <SaveIcon />
                            </IconButton>
                            <Tooltip title="Delete" arrow>
                              <IconButton aria-label="delete" className={classes.margin} onClick={() => onDeletePersonaDepartment(row.rowId, row.personaid.b2level, row.personaid.department)}>
                                <DeleteIcon style={{ color: " #939393" }} />
                              </IconButton>
                            </Tooltip>
                          </React.Fragment>
                      }

                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </React.Fragment>}
    </React.Fragment>
  );
}


export default PersonaDepartmentTable