import React ,{useState,useEffect} from 'react';
import Icon from '@material-ui/core/Icon'
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SimpleCheckbox from "./SimpleCheckbox";
import axios from 'axios';
import { REACT_APP_API_URL, REACT_APP_API_URL2, REACT_APP_API_URL3 } from "./../Constant";
import { useAuthState } from "@bamboobox/b2logincheck";
import Close from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { DeleteButton } from './Filters/DeleteButton';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import "./createIcpForm.css";
import "./../toast.css"
const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: '1.1rem'
    }
}));


var icpname1 = "";
var comments1 = "";
var setAttribute = {
    "id": {
        "custid": "",
        "icpName": ""
    },
    "comments": "",
    "filterCondition": [
        {
            "filterId": "",
            "filterName": "default",
            "filterRank": 0,
            "icpFilter": [],
            "icpCondition": []
        }
    ],
    "icpVersion": "1.0"
}

const CreateICPForm = (props) => {
    toast.configure({
        autoClose: 6000,
        draggable: true,
        hideProgressBar: false,
        position: toast.POSITION.BOTTOM_LEFT,
        icon: false,
      });
    var list=[]
    const userData = useAuthState();
     const [selected, setSelected] = useState(0);
     const [total,setTotal] = useState();
    const onSubmit = (e) => {
        if (props.previousICP === 'noICP') {
            e.preventDefault();
            
            setAttribute.id.custid = userData.user.tenantId

            if (setAttribute.id.icpName?.trim() === "" && setAttribute.comments?.trim() === "" && (Array.isArray(setAttribute.filterCondition[0].icpCondition) && !setAttribute.filterCondition[0].icpCondition.length)) {
                //console.log("ICP name, description and atleast one attribute are required")
                // props.snackbarCallbackToFormdialog({
                //     open: true,
                //     severity: "warning",
                //     message: "ICP name, description and atleast one attribute are required"
                // })
                toast.warn(<Alert severity="warning">ICP name, description and atleast one attribute are required</Alert>,{
                    closeOnClick: true,
                    autoClose: true,
                    closeButton: true,
                    newestOnTop: true
                  });
            }
            else if (setAttribute.id.icpName?.trim() === "") {
                //console.log("ICP name is required")
                // props.snackbarCallbackToFormdialog({
                //     open: true,
                //     severity: "warning",
                //     message: "ICP name is required"
                // })
                toast.warn(<Alert severity="warning">ICP name is required</Alert>,{
                    closeOnClick: true,
                    autoClose: true,
                    closeButton: true,
                    newestOnTop: true
                  });
            }else if (setAttribute.id.icpName?.trim().includes(" ")) {
                toast.warn(<Alert severity="warning">ICP name can not contain spaces</Alert>,{
                    closeOnClick: true,
                    autoClose: true,
                    closeButton: true,
                    newestOnTop: true
                  });
            }else if (setAttribute.comments?.trim() === "") {
                //console.log("Description is required")
                // props.snackbarCallbackToFormdialog({
                //     open: true,
                //     severity: "warning",
                //     message: "Description is required"
                // })
                toast.warn(<Alert severity="warning">Description is required</Alert>,{
                    closeOnClick: true,
                    autoClose: true,
                    closeButton: true,
                    newestOnTop: true
                  });
            }
            else if (Array.isArray(setAttribute.filterCondition[0].icpCondition) && !setAttribute.filterCondition[0].icpCondition.length) {
                //console.log("Atleast one attribute must be selcted")
                console.log(Array.isArray(setAttribute.filterCondition[0].icpCondition) && !setAttribute.filterCondition[0].icpCondition.length)
                // props.snackbarCallbackToFormdialog({
                //     open: true,
                //     severity: "warning",
                //     message: "Atleast one attribute must be selected"
                // })
                toast.warn(<Alert severity="warning">Atleast one attribute must be selected</Alert>,{
                    closeOnClick: true,
                    autoClose: true,
                    closeButton: true,
                    newestOnTop: true
                  });
            }
            else {
                try {
                    axios.post(REACT_APP_API_URL + '/scoredefinition/newIcp', setAttribute, { withCredentials: true })
                        .then((result) => {
                            console.log(result)
                            if (result.data === "Cannot create Icp with the existing Icp name") {
                                console.log("inIf")
                                //call back function for routing from bar and in turn from icplist page
                                // props.snackbarCallbackToFormdialog({
                                //     open: true,
                                //     severity: "warning",
                                //     message: "Cannot create Icp with the existing Icp name"
                                // })
                                toast.warn(<Alert severity="warning">Cannot create Icp with the existing Icp name</Alert>,{
                                    closeOnClick: true,
                                    autoClose: true,
                                    closeButton: true,
                                    newestOnTop: true
                                  });

                            }
                            else if (result.data === "Naming Standanrd convention should follow, only alphabets and numbers are allowed") {
                                console.log("inIfelse")
                                // props.snackbarCallbackToFormdialog({
                                //     open: true,
                                //     severity: "ing",
                                //     message: "Naming Standard convention should follow, only alphabets and numbers are allowed"
                                // })
                                toast.warn(<Alert severity="warning">Naming Standard convention should follow, only alphabets and numbers are allowed</Alert>,{
                                    closeOnClick: true,
                                    autoClose: true,
                                    closeButton: true,
                                    newestOnTop: true
                                  });
                            }
                            else {
                                console.log("inelse")
                                setAttribute = {
                                    "id": {
                                        "custid": userData.user.tenantId,
                                        "icpName": ""
                                    },
                                    "comments": "",
                                    "filterCondition": [
                                        {
                                            "filterId": "",
                                            "filterName": "default",
                                            "filterRank": 0,
                                            "icpFilter": [],
                                            "icpCondition": []
                                        }
                                    ],
                                    "icpVersion": "1.0"
                                }
                                props.routeToIcpConditionFromFormDialog(icpname1);
                            }
                        })
                } catch (error) {
                    console.log(error)
                }
            }
        }
        else {
            e.preventDefault();
            props.handleClose();
        }

    };
   
    // let total = 
    // const updateSelected = (e)=>{
    //     selected = e
    // }
    

    const updateAttribute = (attributeToBeAdded, name) => {
        // console.log("updated Attr:",attributeToBeAdded);
        setAttribute.id.icpName = icpname1?.trim();
        setAttribute.comments = comments1;
        console.log("setAttribute:",setAttribute);
        if (Array.isArray(setAttribute.filterCondition[0].icpCondition) && !setAttribute.filterCondition[0].icpCondition.length) {
            setAttribute.filterCondition[0].icpCondition.push(attributeToBeAdded);
            increaseSelectedAttributes()
        
        }
        else {
            var indexOfSelectedAttribute = setAttribute.filterCondition[0].icpCondition.findIndex(function (i) {
                return i.name == name;
            });
            if (indexOfSelectedAttribute == -1) {
                setAttribute.filterCondition[0].icpCondition.push(attributeToBeAdded);
                increaseSelectedAttributes()
            }
            else {
                setAttribute.filterCondition[0].icpCondition[indexOfSelectedAttribute] = attributeToBeAdded;
            }
        }

    }

    const updateUncheckAttribute = (name) => {
        console.log(setAttribute.filterCondition[0].icpCondition)
        setAttribute.filterCondition[0].icpCondition.splice(setAttribute.filterCondition[0].icpCondition.findIndex(function (i) {
            return i.name === name;
        }), 1);
        console.log(setAttribute.filterCondition[0].icpCondition)
        decreaseSelectedAttributes();
    }

    const onChangeIcpname = (e) => {
        icpname1 = e.target.value;
        console.log(icpname1);
        setAttribute.id.icpName = icpname1;

    }

    const onChangeComments = (e) => {
        comments1 = e.target.value;
        console.log(comments1);
        setAttribute.comments = comments1;
    }

    const handleClose = () => {
        props.handleClose();
    }
    const increaseSelectedAttributes = () => {
        setSelected(selected + 1);
        
    }
    const decreaseSelectedAttributes = () => {
        setSelected(selected - 1);
    }

    const classes = useStyles();

    console.log("selected = ",selected);
    const getAttributeData = async () => {
        try {
            //get request for the attribute data from nitin
            await axios.get(REACT_APP_API_URL3 + '/' + userData.user.tenantId + '/describe.json', { withCredentials: true }).then((result) => {

                var list = [];

                if (result.status === 200) {
                    console.log('inside if condition')
                    var json = JSON.parse(JSON.stringify(result.data));

                    //checking the size of json
                    console.log(json.length);

                    for (let i = 0; i < json.length; i++) {

                        var obj =
                        {
                            name: "",
                            type: "",
                            position: "",
                            hierarchy: ""
                        };

                        if (json[i].ICP === 'yes' && json[i].name && (json[i]?.position < 2 || json[i]?.position === undefined)) {
                            //Here assign the object value into new object.

                            obj.name = json[i].name;
                            obj.type = json[i].type

                            if (json[i].type === 'hierarchical') {
                                obj.position = json[i].position;
                                obj.hierarchy = json[i].hierarchy;
                            }

                            list.push(obj);

                        }

                    }
                }
                
                let len = list.length;
                for (let i = 0; i < len; i++) {
                    list[i] = Object.assign(list[i], {
                        status: true
                    });
                }
                //setAttributeData([...rowsData]);
                setTotal(list.length);
    
            });

        } catch (err) {
            console.error(err.message);
        }
    }
    useEffect(() => {
        getAttributeData();
    }, []);
    

    return (
        <React.Fragment>
            <form onSubmit={onSubmit} autoComplete="off">
                <div style={{margin:"10px",marginBottom:"15px"}} className='textbox'>
                    <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",marginBottom:"10px"}}>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="icpname"
                        id="icpName"
                        // label="ICP Name"
                        placeholder='New Profile Name'
                        size="small"
                        style={{ width: "50%" }}
                        variant="outlined"
                        onChange={onChangeIcpname}
                        />
                    <IconButton color="blueGrey" onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                    </div>
                    <div >
                    <TextField
                        margin="dense"
                        fullWidth
                        name="comments"
                        id="comments"
                        size="small"
                        label="Description"
                        variant="outlined"
                        onChange={onChangeComments}
                        type="Text"
                        multiline
                        />
                    </div>
                </div>
                        <Divider style={{marginLeft:"20px",marginRight:"20px"}}/>
                <SimpleCheckbox updateAttribute={updateAttribute} updateUncheckAttribute={updateUncheckAttribute} />
                <DialogActions style={{width: "91%" ,display:"flex",justifyContent:"space-between",marginTop:"10px"}}>
                    
                {/* <p style={{  position: "static",width: "195px",height: '22px',left: '10px',top:'15px'}}>selected value of 5</p> */}
                {/* <div style={{marginTop:"10px"}}> */}
                <div style={{display:"block"}}><span style={{fontSize:"14px"}} >Selected {selected} of {total} </span></div>
                <div>
                <Button variant="outlined"
                     style={{
                        color:'#A0A0A0',
                        borderColor: "#A0A0A0",
                        backgroundColor: "#FFFFFF",
                        boxShadow: "0px 4px 5px -2px rgba(200, 200, 200, 0.7)",
                        borderRadius:"18px",
                        padding:"2px",
                        width:"104px"
                      }}
                    
                    // classes={{ root: classes.root }}
                    
                    size="medium" onClick={handleClose}>
                    {/* <div style={{paddingRight:"8px"}}>
                    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.66896 6.92443L6.15443 4.40952L8.4878 2.07615C8.92925 1.63527 8.92925 0.920166 8.4878 0.478909C8.04673 0.0378426 7.33162 0.0380338 6.89094 0.478909L4.55719 2.81228L2.10935 0.364247C1.66809 -0.0768192 0.952983 -0.0768192 0.512109 0.364247C0.0712337 0.805504 0.0712337 1.52023 0.512109 1.96149L2.96014 4.40952L0.330943 7.03852C-0.110314 7.47997 -0.110314 8.1947 0.330943 8.63595C0.772009 9.07683 1.48693 9.07683 1.9278 8.63595L4.55719 6.00676L7.07211 8.52167C7.51317 8.96274 8.22809 8.96274 8.66896 8.52167C9.11041 8.08022 9.11022 7.36512 8.66896 6.92443Z" fill="#A0A0A0"/>
                    </svg>
                    </div> */}

                        CANCEL
                    </Button>
                    <Button  size="medium" type="submit"
                    // style={{ textTransform: 'none' ,backgroundColor: "#21DA8C" , width :"300px",borderRadius: "18px", color:"white"}}
                    style={{ marginLeft: "10px", 
                    color: "#FFFFFF",
                    backgroundColor: "#21DA8C",
                    boxShadow: "0px 4px 5px -2px rgba(200, 200, 200, 0.7)",
                      borderRadius:"18px",
                      padding:"2px",  
                      width:"110px",
                    }}
                    
                    >
                    {/* <div style={{paddingRight:"8px"}}>
                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.2955 2.82895L5.36063 8.76339C4.92115 9.20296 4.20824 9.20296 3.76834 8.76339L0.704737 5.69955C0.265088 5.25999 0.265088 4.54699 0.704737 4.10734C1.14447 3.66761 1.85732 3.66761 2.29686 4.10718L4.56472 6.37507L9.7031 1.23666C10.1428 0.796924 10.8557 0.797257 11.2953 1.23666C11.7349 1.67631 11.7349 2.38905 11.2955 2.82895Z" fill="#FFFFFF"/>
                    </svg>
                  </div> */}
                    NEXT
                    {/* to Setup Filter */}
                    </Button>
                </div>
                    
                {/* </div> */}
                   
                </DialogActions>
            </form>
        </React.Fragment>
    )
}

export default CreateICPForm