import IconButton from '@mui/material/IconButton';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import { useState } from 'react';
import { CustomDialogBox } from './CustomDialogBox';

// import './deleteButton.css';

export const DeleteButton=({handleDelete,name,showDailog})=>{
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    }; 

    const handleDeleteClick=()=>{
        handleDelete();
    }

    return(
        <>
        {showDailog?<>
            <IconButton color="blueGrey" aria-label="upload picture" component="label" sx={{margin:"10px"}} onClick={e=>handleClickOpen()}>
                    <DoNotDisturbOnOutlinedIcon/>
        </IconButton>
      <CustomDialogBox open={open} name={name} setOpen={setOpen} handleDelete={handleDelete}/>
        </>:<>
        <IconButton color="blueGrey" aria-label="upload picture" component="label" sx={{margin:"10px"}} onClick={e=>handleDeleteClick()}>
                    <DoNotDisturbOnOutlinedIcon/>
        </IconButton>
        </>}
        
        
        </>
        
    )
}