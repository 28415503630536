import { CircularProgress, IconButton, LinearProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import "./ExportFilterCriteriaSectionField.css";
import CheckBoxIcon from '@mui/icons-material/CheckBox';


import { useAuthState } from "@bamboobox/b2logincheck";
import ExportFilterValuesField from "./ExportFilterValuesField";
//import getTenantConfigJSON from "./getTenantConfigJson";
import _, { values } from 'lodash'
import { CheckBoxOutlineBlank } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import Box from '@material-ui/core/Box';
import { B2_INTENT_SETTING} from "./../Constant";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';




const useStyles = makeStyles({
  customTextField: {
    "& input::label": {
      fontStyle: "italic"
    }
  },
  root:{
    '& .MuiInputBase-root': {
        borderRadius:"8px",
        height:"36px",
        padding: "1px 0px !important"
      }
} 
});






const ExportFilterCriteriaSectionField = (props) => {
  
  console.log("props in section field", props);
 
  const classes = useStyles();
  const [attributeRelations, setAttibuteRelations] = useState([]);
  const [attributeValues, setAttributeValues] = useState([]);
  const [attribute,setAttributes] = useState();
  const [relation,setRelations] = useState();
  const [type , setType] = useState('');

  /**commented --- tenant config mapper */
  const [accTenantConfigMapper, setAccTenantConfigMapper] = useState();
  const [contTenantConfigMapper,setContTenantConfigMapper] = useState();
  const [departmentV,setDepartmentV] = useState([])
  const [personalevelV,setPersonalevelV] = useState([])

  /**commented --- tenant config mapper */
  const tenantId = useAuthState().user.tenantId;
  const META_BASE_URL = window._env_.META_BASE_URL;
  const PROS_BASE_URL = window._env_.PROS_BASE_URL;
  const ENG_SCR_BASE_URL = window._env_.ENG_SCR_BASE_URL;
  const CDP_BASE_URL = window._env_.CDP_BASE_URL;
  const INTENT_TOPICS_URL = window._env_.INTENT_TOPICS_URL;
  const TENANT_CONFIG = window._env_.TENANT_CONFIG;
  const AGGREGATOR_API = window._env_.AGGREGATOR_API;
  const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


  const fetchAttributeValues = async (value) => {
    console.log("value from fetchAttributevalues", value)
    
    if(value?.toLowerCase() === 'product') {
      if(props?.filterAttribute?.valueSource){

        fetch(window._env_.REACT_APP_API_URL3+'/opportunity/products?tenantId='+tenantId,{ headers: { "Content-Type": "application/json" },credentials: "include"})
      .then(resp => resp.json())
      .then(respData => {
        if(respData?.success){
          let prodData = respData?.data?.map(pro => {return {"products":pro, "source":props?.filterAttribute?.valueSource}});
          setAttributeValues(prodData);
        }else{
          setAttributeValues([])
        }
      }).catch(e => {
        console.error(e);
        setAttributeValues([])})
      }
    } else if(value === 'Product Name') {
      if(props?.filterAttribute?.valueSource){
        fetch(window._env_.AGGREGATOR_API+"/aggregate/productName/"+tenantId+'?source='+props?.filterAttribute?.valueSource?.toLowerCase(), {
          credentials:'include'
        })
          .then((result) => result.json())
          .then((json) => {
            console.log("json ",json.data)
            let productNames = [];
            
            json.data.forEach((obj) => {
              let pushObj={
                productName: obj,
                "source":props?.filterAttribute?.valueSource
              };
              console.log("pushObj ", pushObj);
              productNames.push(pushObj);
            });
            console.log("productNames ", productNames)
  
            if (productNames?.length > 0) {
              setAttributeValues(productNames);
            } else {
            }
          }).catch(e => {
            console.error(e);
            setAttributeValues([])})
      }
    }else if(value?.toLowerCase() === "Campaign Name".toLowerCase()){
      if(props?.filterAttribute?.valueSource){
        fetch(window._env_.CDP_API + "/program/get-all-programs?tenantId=" + tenantId, {
        credentials: "include",
      })
        .then((result) => result.json())
        .then((json) => {
          let campaignTypes = [];
          campaignTypes = json?.["data"]?.["programs"]?.filter(pro => pro.source?.toLowerCase() === props?.filterAttribute?.valueSource?.toLowerCase() )?.map((obj) => {
            let pushObj={
              "campaignName": obj.programName,
              "source": obj.source,
            };
            console.log("changes done",pushObj);
            return pushObj;
          });
          if (campaignTypes?.length > 0) {
            setAttributeValues(campaignTypes);
          } else {
          }
        }).catch(e => {
          console.error(e);
          setAttributeValues([])})
      }
      
      }else if(value?.toLowerCase() === 'Event Name'?.toLowerCase()){
        if(props?.filterAttribute?.valueSource){
          console.log("value ",value)
         fetch(AGGREGATOR_API+"/aggregate/attributes/"+tenantId+'?attributeName=activityAttributes.eventName&source='+props?.filterAttribute?.valueSource?.toLowerCase(), {
          credentials:'include'
        })
          .then((result) => result.json())
          .then((json) => {
            let eventNames = [];
            console.log("json ",json.data)
            json.data.forEach((obj) => {
              let pushObj={
                "eventName": obj,
                "source": props?.filterAttribute?.valueSource
              };
              console.log("pushObj ", pushObj);
              eventNames.push(pushObj);
            });
              setAttributeValues(eventNames || []);
          }).catch(e => {
            console.error(e);
            setAttributeValues([])});
        }
        
      }
  };

 
  

  useEffect(()=>{
    if(props.filterAttribute?.attribute!=undefined){
      fetchAttributeValues(props.filterAttribute?.attribute)
    }
  },[props.filterAttribute])
  const onChangeAttribute = (value) => {
    
    let [type,relations,custom] = ['','',''];
    const newFilterAttribute = { ...props.filterAttribute };
    setAttributeValues([]);
    setAttibuteRelations([]);
    if ((value?.title === null || value?.title === "") && "attribute" in newFilterAttribute) {
      delete newFilterAttribute["attribute"];
      delete newFilterAttribute["type"];
    } else {
      if("attribute" in newFilterAttribute && "relation" in newFilterAttribute && "values" in newFilterAttribute){
        newFilterAttribute["attribute"] = value?.title;
        delete newFilterAttribute["values"]
        delete newFilterAttribute["valueSource"]
        setRelations(null);
      } else {
        newFilterAttribute["attribute"] = value?.title;

      }
      setAttributes(value);
      [type,relations,custom] = getRelations(value?.title);
      console.log('type and relation ',type,' ',relation)
      setAttibuteRelations(relations);
      //fetchAttributeValues(value?.title);
    }
    if ("relation" in newFilterAttribute) {
      delete newFilterAttribute["relation"];
    }
    if ("values" in newFilterAttribute) {
      delete newFilterAttribute["values"];
    }
    if ("tempValues" in newFilterAttribute) {
      delete newFilterAttribute["tempValues"];
    }
    if ("attribute" in newFilterAttribute) {
      if (["Contact Engaged"].includes(newFilterAttribute["attribute"])) {
        newFilterAttribute["relation"] = "isEqual (=)";
      }
    }
    else{
      // if ("type" in newFilterAttribute) {
      //   delete newFilterAttribute["type"];
      // }
      if ("relationMain" in newFilterAttribute) {
        delete newFilterAttribute["relationMain"];
      }
    }
    newFilterAttribute['valueSource'] = value?.label;
    newFilterAttribute['type'] = type;
    let attributekey = getKeyByValue(props?.groupnamemap , props?.groupName)
    let source = props?.configJSON[0]?.configVal[attributekey]?.source;
    newFilterAttribute['source'] = source
    let fieldName = findAttributeKey(value?.title, source)
    newFilterAttribute['fieldName'] = fieldName
    newFilterAttribute['custom'] = custom;
    newFilterAttribute['icpName'] = props?.icp;
    //get value body
   
    const newFilterAtributes = [...props.filterAttributes];
    newFilterAtributes[props.filterAttributeIndex] = { ...newFilterAttribute };
    const newFilterAttributesArray = [...props.filterAttributesArray];
    console.log( newFilterAttributesArray[0][props?.groupNameKey])
    console.log(newFilterAtributes)
    newFilterAttributesArray[0][props?.groupNameKey][props.filterAttributesIndex] = [
      ...newFilterAtributes,
    ];
    console.log("line no 669 newFilterAttributesArray onChangeAttribute",newFilterAttributesArray)
    props.setFilterAttributesArray(newFilterAttributesArray);
    if(newFilterAtributes[props?.filterAttributeIndex] && "attribute" in newFilterAtributes[props?.filterAttributeIndex]){
      fetchAttributeValues(value?.title);
    }
  };

  const onChangeRelation = (value) => {
    setRelations(value);
    // const newFilterAttribute = { ...props.filterAttribute[props?.filterAttributeIndex] };
    const newFilterAttribute = { ...props.filterAttribute };
    if ((value === null || value === "") && "relation" in newFilterAttribute) {
      delete newFilterAttribute["relation"];
    } else if (value !== null && value !== "") {
      newFilterAttribute["relation"] = value;
    }
    if ("values" in newFilterAttribute) {
      delete newFilterAttribute["values"];
    }
    if ("relation" in newFilterAttribute) {
      if (
        ["is empty", "is not empty"].includes(newFilterAttribute["relation"])
      ) {
        newFilterAttribute["values"] = [];
      } 
    }
   

    const newFilterAtributes = [...props.filterAttributes];
    newFilterAtributes[props.filterAttributeIndex] = { ...newFilterAttribute };
    const newFilterAttributesArray = [...props.filterAttributesArray];
    newFilterAttributesArray[0][props?.groupNameKey][props.filterAttributesIndex] = [
      ...newFilterAtributes,
    ];
    props.setFilterAttributesArray(newFilterAttributesArray);
    // }
  };

 

  const isDisabledClose = () => {

    if (props.filterAttributes.length > 0) {
      return false;

  }
    else return true;
  };

  function getKeyByValue(object, value) {
    console.log('object and value ',object , value)
    return Object.entries(object).find(([key, val]) => val === value)[0];
  }

  const getRelations = (value) => {
    let valueSelectedInAttr = value;
    let groupnamekey = getKeyByValue(props?.groupnamemap,props?.groupName);
    let source = props?.configJSON[0]?.configVal[groupnamekey]?.source;
    let attributeKey = findAttributeKey( valueSelectedInAttr,source)
    console.log('attrkey ',attributeKey);
    let custom = (props?.mapper[source?.toLowerCase()][attributeKey]?.custom);
    let type = (props?.mapper[source?.toLowerCase()][attributeKey]?.type)?.toLowerCase();
    setType(type);
    //get the relation of specific type
    let relations = props?.relationConfigJSON[0]?.configVal[type]?.operators || []
    return [type, relations , custom];
    //setRelation(relations);


  }

  const findAttributeKey = ( valueSelectedInAttr,source) => {
   
    let fieldName = '';
    let sourceField = Object.keys(props?.mapper[source?.toLowerCase()]) || []; 
    for(let fields of sourceField) {
        if(props?.mapper[source?.toLowerCase()][fields]?.Label === valueSelectedInAttr) {
            fieldName = fields;
        }
    }
    return fieldName;
  }
  

  const onClickClose = () => {
    
    let newFilterAtributes = [...props.filterAttributes];
    if(props?.filterAttributes?.length === 1) {
      props?.filterAttributes?.splice(0,1);
      props?.filterAttributes.push({});
      newFilterAtributes = props?.filterAttributes;
      const newFilterAttributesArray = [...props.filterAttributesArray];
      newFilterAttributesArray[0][props?.groupNameKey][props.filterAttributesIndex] = [
        ...newFilterAtributes,
      ];
      props.setFilterAttributesArray(newFilterAttributesArray);
    } else {
      newFilterAtributes.splice(props.filterAttributeIndex, 1);
      if(newFilterAtributes?.length === 0){
        newFilterAtributes.splice(props?.filterAttributesIndex,0,{})
      }
      const newFilterAttributesArray = [...props.filterAttributesArray];
      newFilterAttributesArray[0][props?.groupNameKey][props.filterAttributesIndex] = [
        ...newFilterAtributes,
      ];
      props.setFilterAttributesArray(newFilterAttributesArray);
    }
    const newFilterAttributesArray = [...props.filterAttributesArray];
    newFilterAttributesArray[0][props?.groupNameKey][props.filterAttributesIndex] = [
      ...newFilterAtributes,
    ];
    props.setFilterAttributesArray(newFilterAttributesArray);
  };

  const getAttributeValue = (value) => {
    
    console.log(value);
    if (props.filterAttribute?.attribute === undefined) {
      console.log("coming inside props.filterAttribute.attribute");
      return null;
     } else {
      //setAttributes(value);
      return value;
     }
  };

  

  const getAttributeValues = (filterAttribute) => {
    let attributeArr = [];
    console.log('filterattr in getAttVal ',filterAttribute);
    if(filterAttribute?.length > 0){
        for(let attr of filterAttribute){//attr = {"products":"salesforce"}
            console.log('attr ',attr)
            console.log('configjson ',props?.configJSON[0])
            let attributekey = getKeyByValue(props?.groupnamemap , props?.groupName)
            console.log('attrkey ',attributekey);
            let source = props?.configJSON[0]?.configVal[attributekey]?.source;
            console.log('source ',source);
            console.log('source lowercase ',props?.mapper[source?.toLowerCase()])
    
            let label = props?.mapper[source?.toLowerCase()][Object?.keys(attr)]?.Label || [];
            let newobj = {};
            newobj[label] = attr[Object.keys(attr)];
            attributeArr.push(newobj);
        }
    }
    console.log('attributes to display ',attributeArr);

    const transformedArray = attributeArr.map(element => {
      return {
        title: Object?.keys(element)[0],
        label: element[Object?.keys(element)]
      };
    });
   
    console.log('transformedArray to display ',transformedArray);
    return transformedArray;
    //setAttribute(attributeArr);
   
  } 


  const renderOption = () => {

  }

  
  const getValue = () => {
    if (props.filterAttribute.values === undefined) {
      return null;
    } else {
      return props.filterAttribute.values[0];
    }
  };
  useEffect(()=>{
   // fetchRelationType(props.filterAttribute.attribute);
},[])


const getFilterAttribute = () => {
  
  console.log('inside auto ',props?.filterAttribute?.attribute)
  if(props?.filterAttribute === undefined){
    return null;
  }if(props?.filterAttribute !== undefined){
    let obj = {};
    obj['title'] = props?.filterAttribute?.attribute;
    obj['label'] = props?.filterAttribute?.valueSource
    console.log('obj in getfilteratt ',obj);
    return obj
  } else {
    return attribute
  }
}

const getFilterRelation = () => {
  
  console.log('inside auto relation ',props?.filterAttribute?.relation)
  if(props?.filterAttribute === undefined){
    return null;
  }if(props?.filterAttribute !== undefined){
    return props?.filterAttribute?.relation;
  } else {
    return relation
  }
}

  return (
    <div className="export-filter-crieria-section-and-field">
      <div className="export-filter-crieria-section-and-field-elements">
        <Autocomplete
        disableClearable
        className={classes.root}
          //value={getAttributeValue(props.filterAttribute?.attribute)}
          // value={props?.filterAttribute?.attribute === undefined? null:( props?.filterAttribute?.attribute? props?.filterAttribute?.attribute: attribute)}
          value = {
            getFilterAttribute()}
          onChange={(event, newValue) => {
            onChangeAttribute(newValue);
          }}
          style={{ minWidth: "175px", marginRight: "32px" , borderRadius:"16px"}}

          options={getAttributeValues(props?.taggingBasedOn || [])}//{title,labdl}
          
          popupIcon={<KeyboardArrowDownRoundedIcon style={{ color: "rgba(3, 3, 3, 0.6)" }} />}
          getOptionLabel={(option) => option.title}
            getOptionSelected={(option,value) =>  {
              console.log("option.title and option.value", option.title, option.label, value.title, value.label)
              return option.title === value.title && option.label === value.label}}
          renderOption={(props, option) => (
            
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
        <div> <img
                                width="20"
                                height="20"
                                src={`${B2_INTENT_SETTING}/logos/${props.label?.toLowerCase()}.png`}
                              ></img>
          {props.title}</div> 
        </Box>
      )}
          renderInput={(params) => (
            <TextField {...params} placeholder="Attribute" variant="outlined" />
          )}
        />
      
        
        
      
        {
          ((props?.filterAttribute && "attribute" in props?.filterAttribute) || 
          ((props?.filterAttribute)?.[props?.filterAttributeIndex]) 
          && "attribute" in (props?.filterAttribute)?.[props?.filterAttributeIndex])
    
         && (
            <Autocomplete
              //value={props?.filterAttribute?.relation === undefined?null: relation}
              value = {getFilterRelation()}
              onChange={(event, newValue) => {
                onChangeRelation(newValue);
              }}
              options={attributeRelations}
              //getOptionLabel={(option) => option.title}
              className={classes.root}
              popupIcon={<KeyboardArrowDownRoundedIcon style={{ color: "rgba(3, 3, 3, 0.6)" }} />}
              style={{ minWidth: "175px", marginRight: "32px" }}

              renderInput={(params) => (
                <TextField {...params} placeholder="Relation" variant="outlined" />
              )}
            />
          )}
          

        {
          props?.filterAttribute && (props?.filterAttribute?.relation === 'is empty' || props?.filterAttribute?.relation === 'is not empty')?
          null:
            ((props?.filterAttribute && "attribute" in props?.filterAttribute && "relation" in props?.filterAttribute) || 
            ((props?.filterAttribute)?.[props?.filterAttributeIndex]) 
            && "attribute" in (props?.filterAttribute)?.[props?.filterAttributeIndex]
            && "relation" in (props?.filterAttribute)?.[props?.filterAttributeIndex])
            && (
            
            <ExportFilterValuesField
              attributeValues={attributeValues}
              groupNameKey = {props?.groupNameKey}
              filterAttributes={props.filterAttributes}
              filterAttributeIndex={props.filterAttributeIndex}
              filterAttribute={props.filterAttribute}
              filterAttributesArray={props.filterAttributesArray}
              filterAttributesIndex={props.filterAttributesIndex}
              setFilterAttributesArray={props.setFilterAttributesArray}
            />
          )}

      </div>
      <IconButton
        onClick={() => {
          onClickClose();
        }}
        style={{ marginLeft: "93%", "position": "absolute",
        backgroundColor: "white",color: "#939393",
        color:"black"
        }}
        disabled={isDisabledClose()}
        >
    
      
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5V0.5C18.6274 0.5 24 5.87258 24 12.5V12.5C24 19.1274 18.6274 24.5 12 24.5V24.5C5.37258 24.5 0 19.1274 0 12.5V12.5Z" fill="#ffffff"/>
<path d="M19 6.91L17.59 5.5L12 11.09L6.41 5.5L5 6.91L10.59 12.5L5 18.09L6.41 19.5L12 13.91L17.59 19.5L19 18.09L13.41 12.5L19 6.91Z" fill="#939393"/>
</svg>

      </IconButton>
    </div>
  );
  
};

export default ExportFilterCriteriaSectionField;
