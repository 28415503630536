import {TENANTCONFIG_API} from "./../Constant"


const getTenantConfig = async(tenantId,tool,tenantConfigKeys) => {

    try {
        const tenantConfigResponse = await fetch(`${TENANTCONFIG_API}/tenant/get-tenant-config`,{
            method: "POST",
            headers : {
                "Content-Type": "application/json"
            },
            body : JSON.stringify({
                "tenantId" : tenantId,
                "tool" : tool,
                "keys" : tenantConfigKeys
            }),
            credentials: "include"
        });
        const tenantConfigResponseJson = await tenantConfigResponse.json();
        const tenantConfigResponseJsonArray = {};
        for( const tenantConfig of tenantConfigResponseJson) {
            tenantConfigResponseJsonArray[tenantConfig.configKey] = tenantConfig.configVal;
        }
        const tenantConfigResponseArray = [];
        for(const key of tenantConfigKeys) {
            if(key in tenantConfigResponseJsonArray) {
                tenantConfigResponseArray.push({ configKey: key, configVal: tenantConfigResponseJsonArray[key]});
            }
        }
        return tenantConfigResponseArray;
    }
    catch(err) {

        console.error(`Error occured in getTenantConfig for ${tenantId} , tool -> ${tool} , tenantConfigKeys -> ${tenantConfigKeys}:  `, err.message)
        return [];

    }

}

export default getTenantConfig