import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import FilterNameComboBox from './FilterNameComboBox';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

var name;
var type;
var relation;
var value;

const AddNewFilterCondition = (props) => {
    const [open, setOpen] = React.useState(false);
    console.log(props);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        props.setOpenCondition(false);
        // setOpen(false);
    };

    const dataFromFilterName = (name, type, relation) => {
        onChangeName(name);
        onChangeType(type);
        onChangeRelation(relation);
    }

    const onChangeName = (name1) => {
        name = name1;
        console.log(name);
    }

    const onChangeType = (type1) => {
        type = type1;
        console.log(type);
    }

    const onChangeRelation = (relation1) => {
        relation = relation1;
        console.log(relation);
    }

    const onChangeValue = (e) => {
        value = e.target.value;
        console.log(value);
    }


    const onSubmit = (e) => {
        e.preventDefault();
        console.log(name);
        console.log(type);
        console.log(value);
        console.log(relation);
        props.onAddNewCondition(props.filterName, props.icpFilter, props.filterId, name, type, value, relation);
        props.setOpenCondition(false);
    };

    const useStyles = makeStyles((theme) => ({
        table: {
            Width: 200,
        },
        button: {
            margin: theme.spacing(1),
            width: 170,
            height: 35
        },
    }));

    const classes = useStyles();
    return (
        <React.Fragment>
            {/* <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<Icon>add</Icon>}
                onClick={handleClickOpen}
            >
                New Condition
           </Button> */}

            {/* <Tooltip title="Add new filter condition" arrow>
                <IconButton aria-label="add" className={classes.margin} onClick={handleClickOpen}>
                    <AddCircleIcon style={{ color: "#5D9CEC" }} />
                </IconButton>
            </Tooltip> */}
            {/* <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{props.filterName}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To create new filter condition for {props.filterName} filter
                    </DialogContentText> */}
                    <form onSubmit={onSubmit}>
                        <div style={{ display: 'flex', flexDirection: 'row',alignItems: 'center'}}>
                            <FilterNameComboBox dataFromFilterName={dataFromFilterName} filterAttribute={props.filterAttribute} />
                            <TextField
                                margin="dense"
                                name="value"
                                id="value"
                                label="Value"
                                height="300px"
                                variant="outlined"
                                style={{width:'25ch',marginRight:'20px'}}
                                onChange={onChangeValue}
                                type="Text"
                            />
                            <div>
                            <IconButton style={{ textTransform: 'none', fontSize: '1.1rem' ,marginRight:'10px',
                              background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F44336',height:'30px',width:'30px',
                             color:'#F7685B'
                           }} onClick={handleClose} color="primary">
                            
                            <ClearIcon/>
                            </IconButton>
                            <IconButton style={{ textTransform: 'none', fontSize: '1.1rem',height:'30px',width:'30px', 
                            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4CAF50',
                            color:'#7BC67E'
                            
                           }} type="submit" color="primary">
                            <CheckIcon/>
                            </IconButton>
                            </div>
                        </div>
                        {/* <DialogActions> */}
                            
                        {/* </DialogActions> */}
                    </form>
                {/* </DialogContent>
            </Dialog> */}
            </React.Fragment>
    );
}

export default AddNewFilterCondition
