import React from 'react'
// import { useAuthState } from "@bamboobox/b2logincheck"
import _uniqueId from 'lodash/uniqueId'
import { ExpandMore } from '@material-ui/icons'
// import Menu from '@material-ui/core/Menu'
import MuiMenuItem from '@material-ui/core/MenuItem'
// import ListItemText from '@mui/material/ListItemText';
// import MenuList from '@material-ui/core/MenuList';

import Checkbox from '@mui/material/Checkbox';
//var stringify = require('json-stringify-safe')
import { MuiThemeProvider } from '@material-ui/core'
import  MuiAccordion from '@material-ui/core/Accordion'
import { AccordionSummary, AccordionDetails } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

//import axios from 'axios';

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiAccordion);


const MenuItem = withStyles({
  root: {
    justifyContent: "flex-end"
  }
})(MuiMenuItem);

const Dropdown = React.memo((props) => {

  // const userData = useAuthState();
  // const tenantId = userData.user.tenantId
  const [id] = React.useState(_uniqueId('check-box-demo-'));

  // const [categories, setCategories] = React.useState(props?.categories || [])

  console.log("topicsChips", props)

  

  const [expanded, setExpanded] = React.useState(false);
  // const [topics, setTopics] = React.useState([])
  // const [selectedTopic, setSelectedTopic] = React.useState([...props?.topicsChips] || []);

  React.useEffect(() => {
    console.log("Unchecked topicsChips", props?.searchTopicsList, props?.categories)
  }, [props?.categories, props?.searchTopicsList])

  const handleChange = (panel, categoryId) => (event, isExpanded) => {
    // props?.topicsList[category.provider_category_id]
    // if (isExpanded === true) {
    //   let topicByIdArray = props?.topicsList[categoryId];
    //   setTopics(topicByIdArray || [])
    // }
    setExpanded(isExpanded ? panel : false);
  };
//, boxShadow: "rgb(63 63 68 / 5%) 0px 0px 2px 1px, rgb(63 63 68 / 15%) 0px 1px 3px 3px"
  return (
    <MuiThemeProvider>
      <div style={{marginBottom: 10}}>
      {
        !Array.isArray(props?.categories) || props?.categories?.length <=0 ? <React.Fragment><div></div></React.Fragment> :
        props?.categories.map(category => (
            <Accordion style={{zIndex: 1000}} TransitionProps={{ unmountOnExit: true }} expanded={expanded === category.category_name} onChange={handleChange(category.category_name, category.provider_category_id)}>

              <AccordionSummary expandIcon={<ExpandMore />} TransitionProps={{ unmountOnExit: true }}>
                {category.category_name}
              </AccordionSummary>
              {/* style={{ maxHeight: "300px", overflow: "auto", width: '560px' }} */}
              <AccordionDetails>
                <div style={{maxHeight: "150px", overflow: "auto", width: "100%"}}>{
                  (props?.searchTopicsList[category.provider_category_id] || []).map((topic, index) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        color="primary"
                        key={index}
                        id={id}
                        checked={props.topicsChips.findIndex((obj) => (obj?.topic_name === topic?.topic_name)) !== -1}
                        onChange={e => {
                          if (e.target.checked) {
                            props.setTopicsChips([...props.topicsChips, topic]);
                            console.log("selectedTopic", props.topicsChips)
                            
                            // setStatusFalse(element.name);
                            // checkboxValueFunc(defaultRating, importanceLabel, element.name)

                          } else {
                            console.log("unchecking ", [...props.topicsChips], topic);
                            const indexOfAttr = props.topicsChips.findIndex((obj) => (obj?.topic_name === topic?.topic_name));
                            const newAttrs = [...props.topicsChips];
                            console.log("unchecking ", indexOfAttr, [...newAttrs]);
                            newAttrs.splice(indexOfAttr, 1);
                            console.log("unchecking ", [...newAttrs]);
                            props.setTopicsChips(newAttrs);
                            // decreaseSelectedAttributes();
                            // setStatusTrue(element.name);
                            // unCheckBox(element.name)

                          }
                        }}
                        value="checked"
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                        label="Include Child Attribute"
                      />

                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', alignItems: 'center' }}>
                      {topic?.topic_name}
                      </div>

                    </div>
                  ))
                }</div>
              </AccordionDetails>

            </Accordion>
          ))}
        </div>
    </MuiThemeProvider>



  )
})

export default Dropdown