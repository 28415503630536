import { Button, IconButton } from "@material-ui/core"
import FileCopyIcon from '@material-ui/icons/FileCopy';
import "./ExportFilterCriteriaSection.css"
import ExportFilterCriteriaSectionField from "./ExportFilterCriteriaSectionField";
import Close from "@material-ui/icons/Close";
import { useEffect, useState } from "react";
import Divider from '@mui/material/Divider';


const ExportFilterCriteriaSection = (props) => {
    
    console.log('props in export filter criteria section ',props);
    console.log('exportfiltercriteriasection - array', (props?.filterAttributesArray)[0])
    console.log('exportfiltercriteriasection - key',props?.groupNameKey)
    console.log('exportfiltercriteriasection - whole', (props?.filterAttributesArray)[0]?.[props?.groupNameKey])

    
    const isDisabled = () => {
        
        if(props.filterAttributes.length === 0) {
            return true;
        }
        else {
            for(const filterAttribute of props.filterAttributes){
                
                // if(filterAttribute.attribute === 'Buyer Group' && filterAttribute.values.length === '3' && filterAttribute.values['department']!==undefined &&filterAttribute.values['personalevel']!==undefined && filterAttribute.values['value']!==undefined) return false
                if(filterAttribute.attribute === "Buyer Group"){
                 if(filterAttribute.relation === undefined){
                     return true
                 }   
                 if(filterAttribute.values === undefined){
                    return true
                }
                 if(filterAttribute.values[0]?.value === undefined){
                     return true
                 }
                
                 if(filterAttribute.values[0].value[0] == null ){
                    return true
                 }
                }
                 if(!( "values" in filterAttribute)) {
                    return true;
                }
            }
            return false;
        }
    }
 

    const isDisabledOr = () => {
        
        if(props.filterAttributesIndex === (props.filterAttributesArray[0]?.[props?.groupNameKey].length-1)) {
            if(props.filterAttributes.length === 0) {
                return true;
            }
            else {
                for(const filterAttribute of props.filterAttributes){
                    if(!("values" in filterAttribute)) {
                        return true;
                    }
                }
                return false;
            }
        }
        else {
            if((!("values" in props.filterAttributesArray[0][props?.groupNameKey][props.filterAttributesIndex+1][0])) 
            && (!("values" in props.filterAttributes[0]))) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    const addFilterCriteria = () => {
        
        const newFilterAtributes = [...props.filterAttributesArray][0][props?.groupNameKey];
        newFilterAtributes.push([{}]);
       // newFilterAtributes[props?.filterAttributesIndex+1] = [{}];
        const newFilterAttributesArray = [...props.filterAttributesArray];
        console.log(newFilterAtributes);
        //newFilterAttributesArray[0][props?.groupNameKey][props.filterAttributesIndex]=[...newFilterAtributes];
        // newFilterAttributesArray[0][props?.groupNameKey]=[...newFilterAtributes];

        props.setFilterAttributesArray(newFilterAttributesArray);
    }

    const orFilterCriteria = () => {
        const newFilterAttributesArray = [...props.filterAttributesArray];
        const lastIndex = newFilterAttributesArray[0][props?.groupNameKey][props?.filterAttributesIndex].length - 1;
        console.log(lastIndex)
        newFilterAttributesArray[0][props?.groupNameKey][props?.filterAttributesIndex].splice(lastIndex+1,0,{});
        props.setFilterAttributesArray(newFilterAttributesArray);
    }
    
    return (
        <div className="export-filter-crieria-section">
            <div className="export-filter-crieria-section-and-section">
                <div className="export-filter-crieria-section-and-fields">
                { 
                   props?.filterAttributes?.map((filterAttribute,filterAttributeIndex) => (
                        <ExportFilterCriteriaSectionField
                            key={`filterAttributes${props.filterAttributesIndex}filterAttribute${filterAttributeIndex}`}
                            filterAttributes={props.filterAttributes}
                            filterAttributeIndex={filterAttributeIndex} 
                            filterAttributesIndex={props?.filterAttributesIndex} 
                            filterAttribute={filterAttribute} 
                            filterAttributesArray={props.filterAttributesArray}
                            setFilterAttributesArray={props.setFilterAttributesArray}
                            icp={props.icp}
                            groupNameKey = {props?.groupNameKey}
                            mapper={props?.mapper} configJSON={props?.configJSON} relationConfigJSON={props?.relationConfigJSON}
                            numPapers={props?.numPapers} groupname={props?.groupname} groupName={props?.groupName} index={props?.index} 
                            groupnamemap ={props?.groupnamemap}
                            taggingBasedOn = {props?.taggingBasedOn}
                        />
                    ))
                }
                </div>
                <div className="export-filter-crieria-section-or">
                <Button 
                    color="primary"
                    variant="contained"
                    onClick={() => {orFilterCriteria()}}
                    disabled={isDisabledOr()}
                    style={{ "background-color":isDisabledOr()?"rgba(0, 0, 0, 0.12)":"#21DA8C", height:"32px", minWidth:"44px",fontSize:"14px",fontWeight:"500",borderRadius:"18px"}}
                >
                   OR
                </Button>
            </div>
               
            </div>
            <div className="export-filter-crieria-section-and">
                    <Button 
                        color="primary"
                        variant="contained"
                        onClick={() => {addFilterCriteria()}}
                       disabled={isDisabled()}
                        style={{ "background-color":isDisabled()?"rgba(0, 0, 0, 0.12)":"#21DA8C", height:"32px",width:"55px",fontSize:"14px",fontWeight:"500",borderRadius:"18px"}}
                    >
                        AND
                    </Button>
                </div>
               
        </div>
    );
}

export default ExportFilterCriteriaSection;