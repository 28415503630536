import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import './customAutoComplete.css'
import { useState } from 'react';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

import { ThemeProvider, createTheme } from '@mui/material/styles';


  const theme = createTheme({
    components: {
        MuiAutocomplete: {
            styleOverrides: {
              listbox: {
                '& .MuiAutocomplete-option[aria-selected="true"]': {  // works
                    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #21DA8C`,
                },
                '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': { // works
                    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #21DA8C`,
                },
                '& .MuiAutocomplete-option:hover': { // works
                    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #21DA8C`,
                },
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#21DA8C"
                }
              }
            }
          }
    }
  });

export const CustomAutoComplete=({options,styles,variant,disabled,placeholder,selected,updateSelected,readOnly,filterOptions})=>{
    const [value,SetValue]=useState(selected || "");
    const handleChange=(value)=>{
        console.log("changed",value);
        if(value===null){
            SetValue("");
        }else{
            SetValue(value);
            updateSelected(value)
        }
        
    }
    return (
        <>
         {disabled?
            <div className="coloured">
            <Autocomplete
        options={options}
        disabled={disabled}
        getOptionLabel={(option) => option.label || option}
        value={value}
        // defaultValue={value}
        filterOptions={filterOptions}
        readOnly={readOnly}
        onChange={(e,value)=>handleChange(value)}
        popupIcon={<KeyboardArrowDownRoundedIcon style={{ color: "rgba(3, 3, 3, 0.6)" }} />}
        sx={styles}
        renderInput={(params) => 
        <TextField {...params} placeholder={placeholder} variant={variant}/>}
        />
        </div>
         :<div className="Normal">
         <ThemeProvider theme={theme}>
         <Autocomplete
        options={options}
        disabled={disabled}
        getOptionLabel={(option) => option.label || option}
        value={value}
        // defaultValue={value}
        filterOptions={filterOptions}
        readOnly={readOnly}
        onChange={(e,value)=>handleChange(value)}
        popupIcon={<KeyboardArrowDownRoundedIcon style={{ color: "rgba(3, 3, 3, 0.6)" }} />}
        sx={styles}
        renderInput={(params) => 
        <TextField {...params} placeholder={placeholder} variant={variant}/>}
        />
        </ThemeProvider>
         </div>}
         </>
        
        
    )
        }