import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
// import './Toggler.css';
import { Button } from '@mui/material';
import { right } from "@popperjs/core";


const useStyles = makeStyles((theme) => ({
    buttonStyleClick:{
        variant:"contained",
        width: "109px",
        height: "42px",
        borderRadius: "8px !important",
        backgroundColor: "#004B6B !important",
        color:"#FFFFFF !important",
        fontFamily: "Inter !important",
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "19px",
        letterSpacing: "0em",
        textAlign: "left",
        marginRight:"30px !important",
        border: "1px solid #004B6B !important",
        '&:hover': {
            backgroundColor: '#7986cb', // Your hover button color
          },
    },
    buttonStyle:{
        variant:'outlined',
        width: "109px",
        height: "42px",
        borderRadius: "8px !important",
        backgroundColor:"#FFFFFF !important",
        color:"#004B6B !important",
        fontFamily: "Inter important",
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "19px",
        letterSpacing: "0em",
        textAlign: "left",
        marginRight:"30px !important",
        border: "1px solid #004B6B !important",
        '&:hover': {
            backgroundColor: '#7986cb', // Your hover button color
          }
    },
    // toggleContainer: {
        backgroundColor: theme.palette.primary.background,
        height: "39px",
        margin: theme.spacing(2, 0),
        // borderRadius: "4px",
        // border: `1px solid #109CF1`
        background: '#FFFFFF',
/* primary/border */

        border: '1px solid rgba(55, 234, 158, 0.5)',
        boxSizing: 'border-box',
        borderRadius: '32px'
    // }
}));

export default function FrequencyToggler(props) {

    const [frequency, setFrequency] = React.useState("All");
    const handleButtonChanges = (buttonName)=>{
           setFrequency(buttonName)
           props.setSelectedFrequency(buttonName)
    }
    const classes = useStyles();

    return (
        <div style={{display:'flex'}}>
        <Button
        className={frequency === 'All'?classes.buttonStyleClick:classes.buttonStyle}
        onClick={() => handleButtonChanges('All')}
      >
        All
      </Button>
      <Button
        className={frequency === 'Contact'?classes.buttonStyleClick:classes.buttonStyle}
        onClick={() => handleButtonChanges('Contact')}
      >
        Contact
      </Button>
      <Button
        className={frequency === 'account'?classes.buttonStyleClick:classes.buttonStyle}
        onClick={() => handleButtonChanges('account')}
      >
        Account
      </Button>
        </div>
    );
}

