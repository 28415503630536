import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ExportFilterCriteria from './ExportFilterCriteria';
import Box from '@material-ui/core/Box';



const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled(({ ...props }) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function CustomizedAccordions(props) {
    console.log('props in groupname ',props)
  const [expanded, setExpanded] = React.useState([]);

  // update expanded state whenever groupname prop changes
  React.useEffect(() => {
    setExpanded(props.groupname?.map((_, index) => "panel" + index));
  }, [props.groupname]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded((prevExpanded) =>
      prevExpanded.includes(panel)
        ? prevExpanded.filter((p) => p !== panel)
        : [...prevExpanded, panel]
    );
  };

  

  return (
    <div style={{ width:"100%"}}>
     { props?.groupname?.map((item, index) => (
      <div style={{"width": "100%","padding":"0px"}}>
        <Accordion style={{"width": "100%","padding":"0px"}} key={index} expanded={expanded.includes("panel" + index)} onChange={handleChange("panel" + index)}>
        <AccordionSummary aria-controls={"panel" + index + "d-content"} id={"panel" + index + "d-header"}>
          <Typography style={{
            "width": "90px",
            "height": "20px",
            "font-family": "Nunito Sans",
            "font-style": "normal",
            "font-weight": "bold",
            "font-size": "14px",
            "line-height": "143%",
            "color": "#000000",
            "flex": "none",
            "order": "0",
            "flex-grow": "0",
            "padding":"0px",
            textTransform:"uppercase"
          }}>{item}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{width:"100%"}}>
          <ExportFilterCriteria mapper={props?.mapper} configJSON={props?.configJSON} relationConfigJSON={props?.relationConfigJSON}
            numPapers={props?.numPapers} groupname={props?.groupname} groupName={item} index={index} 
            groupnamemap ={props?.groupnamemap} 
            filterAttributesArray={props?.filterAttributesArray} setFilterAttributesArray={props?.setFilterAttributesArray}
            icp = {props?.icp}
            />
        </AccordionDetails>
        
      </Accordion >
      <Divider style={{  width:"inherit"}} />
      </div>
      //  </Box>
      
    ))}
    </div>
    
  );
}
