import {FilterHeader} from "./FilterHeader";
// import {FilterAttribute} from "./FilterAttribute"
import AlertTitle from '@mui/material/AlertTitle';
import { Filter } from "./FIlter";
import React, { useEffect, useState } from "react";

import CheckIcon from '@mui/icons-material/Check';

import Button from '@mui/material/Button';
import { useAuthState } from "@bamboobox/b2logincheck"

import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios"
import Alert from '@mui/material/Alert';
import { makeStyles } from '@material-ui/core/styles';
// import "./../toast.css"
// import { NewAttriBute } from "./NewAttribute";
import { NewAttriButeCondition } from "./NewAttributeCondition";
import { REACT_APP_API_URL } from "../../Constant";
import { NewConditionFilter } from "./NewConditionFilter";
import {useRef} from 'react';


// const IcpValuesData=props.ValuesData?.filter((obj)=>(obj.ICP.toLowerCase()==='yes' && obj.name));
// const props={}
const options=[
    {
        label:"Very High",
        value:4
    },
    {
        label:"High",
        value:3
    },
    {
        label:"Medium",
        value:2
    },
    {
        label:"Low",
        value:1
    },
    {
        label:"Very Low",
        value:0
    }
]

const useStyles = makeStyles((theme) => ({
    textField:{
        height:'38px',
    },
    head:{
        width:'36ch',
        // marginRight:'60px',
        fontWeight: '700',
        fontSize: '11px',
        lineHeight: '6px',
        letterSpacing: '0.06em',
        textTransform: 'uppercase',
        color: '#595959'
        
    },
    auto:{
        marginRight:'60px',
    }
}));


export const FilterPage=(props)=>{
    const buttonRef = useRef(null);
    // buttonRef.current.disabled = true;
    // buttonRef.current.style.background='rgba(0, 0, 0, 0.26)'
    const classes = useStyles();
    toast.configure({
        autoClose: 5000,
        draggable: true,
        hideProgressBar: false,
        position: toast.POSITION.BOTTOM_LEFT,
        icon: false,
      });
    
    console.log("props from filters--",props);
    const userData = useAuthState()
    var numericalRelationValue = [
        {
            "value": ">",
            "label":'>'
        },
        {
            "value": "<",
            "label":'<'
        }
    ]
    var hierarchicalRelationValue = [
        {
            "value": "=",
            "label":'='
        }
    ]
    const getValuesData=(values,attributes)=>{
        const newValues=values.map((obj)=>{
            const newObj={...obj}
            newObj.label=attributes[newObj.name]?.label || newObj.name;
            newObj.value=newObj.name;
            if (newObj.type === 'hierarchical' || newObj.type === 'categorical') {
                // console.log(json[i].type)
                newObj.relation = hierarchicalRelationValue
            }
            else {
                obj.relation = numericalRelationValue
            }
            return newObj;
        })
        return newValues;
    }
    props.filtersData.icpCondition.sort((a,b)=>{
        return a.name -b.name;
    })

    props.filtersData.icpFilter.map((obj)=>{
        obj.id=Math.random().toString()+obj.name;
        return obj;
    })
    const filterConditionObject=props?.filtersData;
    const [filtersData,setFiltersData]=useState(props?.filtersData.icpCondition || [])
    const [icpFilter,setIcpFilter]=useState(props?.filtersData.icpFilter || []);
    const [name,setName]=useState(props.filtersData.filterName);
    const [valuesData,setValuesData]=useState(getValuesData(props.ValuesData,props.newAttributes));
    console.log("props from filters val",valuesData);
    const [isError,setIsError]=useState(false);
    const [isNewAttributeClicked,setIsNewAttributeClicked]=useState(false);
    const [rendered,setRendered]=useState(false);
    

    const getOptions=(name)=>{
        const options=valuesData.filter((obj)=>obj.name===name);
        if(options && options.length>0){
            return options[0].values || [];
        }
        return [];
    }

    const updateFiltersData=(inputObj)=>{
        console.log("updates",buttonRef.current.disabled,isError);
        if(!isError){
            buttonRef.current.disabled = false;
        buttonRef.current.style.background='#21DA8C'
        }else{
            buttonRef.current.disabled = true;
            buttonRef.current.style.background='rgba(0, 0, 0, 0.26)';
        }
        
        setFiltersData((latestData)=>{
            const updatedData=[...latestData]
            updatedData.map(obj=>{
                if(obj.name===inputObj.name){
                    obj.parameters=inputObj.parameters;
                    obj.attributeWeightage=inputObj.attributeWeightage;
                    return obj;
                }
            })
            // updatedData.push(inputObj);
            return updatedData;
        })
    }

    const addNewCondition=(inputObj)=>{
        if(!isError){
            buttonRef.current.disabled = false;
        buttonRef.current.style.background='#21DA8C'
        }else{
            buttonRef.current.disabled = true;
            buttonRef.current.style.background='rgba(0, 0, 0, 0.26)';
        }
        setFiltersData((latestData)=>{
            const updatedData=[inputObj, ...latestData];
            // updatedData.push(inputObj);
            return updatedData;
        })
        setIsNewAttributeClicked(false);
    }


    const deleteCondition=(inputObj)=>{
        
        if(!isError){
            buttonRef.current.disabled = false;
        buttonRef.current.style.background='#21DA8C'
        }else{
            buttonRef.current.disabled = true;
            buttonRef.current.style.background='rgba(0, 0, 0, 0.26)';
        }
        const updatedData=filtersData.filter(obj=>obj.name!==inputObj.name);
        updatedData.sort((a,b)=>{
            return a.name -b.name;
        })
        setFiltersData(updatedData)

    }

    const toastMesage=()=>{
        return (
            <div>

            </div>
        )
    }

    useEffect(() => {
        buttonRef.current.disabled = true;
        buttonRef.current.style.background='rgba(0, 0, 0, 0.26)'
    }, [rendered])
    

    const saveToDb=async()=>{
        console.log("save clicked");
        buttonRef.current.disabled = true;
        buttonRef.current.style.background='rgba(0, 0, 0, 0.26)'
        try {
            
            const newFilterCondition=filterConditionObject
            newFilterCondition.icpCondition=filtersData;
            newFilterCondition.icpCondition.map((obj)=>{
                // let icpCon={...obj}
                const newObj=obj.parameters.filter((param)=>!((param.valueMin===null || param.valueMin===0) && (param.valueMax===null || param.valueMax===0) && (param.value==='' || param.value===null)))
                console.log("updated obj",newObj)
                obj.parameters=newObj;
                return obj;
            })
            const icpFilters=icpFilter.filter((obj)=>!((obj.name==="" || obj.name===null) || (obj.type===null || obj.type==='') || (obj.relation===null || obj.relation==='') || (obj.value===""|| obj.value===null))).map((obj)=>{
                delete obj.id;
                return obj
            })
            newFilterCondition.icpFilter=icpFilters;
            console.log("entireobjectTobesaved",newFilterCondition);
            axios.post(REACT_APP_API_URL + '/filter/updateFilterCondition/' + userData.user.tenantId + '/' + props.icpName + '/' + filterConditionObject.filterId, newFilterCondition, { withCredentials: true }).then((response) => {
                console.log("save response from db",response.data);
                if(response.data==="updated sucessfully"){
                    buttonRef.current.disabled = false;
                    buttonRef.current.style.background='#21DA8C'
                    toast.success(<Alert severity="success">
                    <AlertTitle>Configuration saved succesfully</AlertTitle>
                    The Configuration is updated and will take effect from the next cycle
                    </Alert>,{
                        closeOnClick: true,
                        autoClose: true,
                        closeButton: true,
                        newestOnTop: true
                      });
                      props.setRender(!props.render);
                }else{
                    buttonRef.current.disabled = false;
                    buttonRef.current.style.background='#21DA8C'
                    toast.error(<Alert severity="warning">Update was unsuccessful</Alert>,{
                        closeOnClick: true,
                        autoClose: true,
                        closeButton: true,
                        newestOnTop: true
                      });
                }
            })
        } catch (error) {
            buttonRef.current.disabled = false;
            buttonRef.current.style.background='#21DA8C'
            toast.error(<Alert severity="warning">Update was unsuccessful</Alert>,{
                closeOnClick: true,
                autoClose: true,
                closeButton: true,
                newestOnTop: true
              });
            console.log("err",error);
        }
        // setIsError(false); 
        // setInProgress(false)   
       
    }

    const handleSave=()=>{
            saveToDb();
    }

    const AddnewIcpFilter=()=>{
        if(!isError){
            buttonRef.current.disabled = false;
        buttonRef.current.style.background='#21DA8C'
        }else{
            buttonRef.current.disabled = true;
            buttonRef.current.style.background='rgba(0, 0, 0, 0.26)';
        }
        const newObject=
            {
                "id":Math.random().toString(),
                "name": "",
                "type": "",
                "relation": "",
                "value":"" 
            }
        setIcpFilter((latestFilters)=>{
            const newList=[...latestFilters];
            console.log("newlist",newList);
            newList.push(newObject);
            console.log("updatedlist",newList);
            return newList;
        })
    }

    const updateFilterObject=(inputObj)=>{
        if(!isError){
            buttonRef.current.disabled = false;
        buttonRef.current.style.background='#21DA8C'
        }else{
            buttonRef.current.disabled = true;
            buttonRef.current.style.background='rgba(0, 0, 0, 0.26)';
        }
        setIcpFilter(latestData=>{
            const updatedData=latestData.filter((obj)=>obj.id!==inputObj?.id);
            updatedData.push(inputObj);
            return updatedData;
        })
    }

    const deleteIcpFilter=(inputObj)=>{
        if(!isError){
            buttonRef.current.disabled = false;
        buttonRef.current.style.background='#21DA8C'
        }else{
            buttonRef.current.disabled = true;
            buttonRef.current.style.background='rgba(0, 0, 0, 0.26)';
        }
        console.log("inputObj is",inputObj)
        setIcpFilter(latestData=>{
            const updatedData=latestData.filter((obj)=>obj.id!==inputObj?.id); 
            console.log("deleted",updatedData);
            return updatedData;
        })
    }



    return(
        <>
        {/* <p>{JSON.stringify(icpFilter)}</p> */}
        {name!=='default' && <>
        <FilterHeader setIsNewAttributeClicked={AddnewIcpFilter} text={"FILTER CRITERIA (STEP 1 OF 2)"} />
            <div style={{display :"flex" , flexDirection :"row", margin:'20px 0px', marginRight:"10px",justifyContent:"space-between" }}>
                    <div className={classes.head} >Attribute</div>
                    <div className={classes.head} >Relation</div>
                    <div className={classes.head} >Value</div>
            </div>
        </>}
        
        {name!=='default' && icpFilter.map((obj)=>{
            return(<NewConditionFilter filters={icpFilter} key={obj.id} updateFilterObject={updateFilterObject} deleteIcpFilter={deleteIcpFilter} valuesData={valuesData} icpFilter={obj}/>)
        })}
        
            <FilterHeader text={name!=='default'?"WEIGHTAGES (STEP 2 of 2)":"WEIGHTAGES"} setIsNewAttributeClicked={setIsNewAttributeClicked}/>
            {isNewAttributeClicked? <NewAttriButeCondition filtersData={filtersData} valuesData={valuesData} addNewCondition={addNewCondition} setIsNewAttributeClicked={setIsNewAttributeClicked} />:<></>}
            
        {
        filtersData.map((obj,index)=>{
                {/* console.log("rerendered",obj); */}
                return(<Filter key={obj.name} filterObj={obj} buttonRef={buttonRef} setIsError={setIsError} valuesData={getOptions(obj.name)} labels={valuesData} updateFiltersData={updateFiltersData} deleteCondition={deleteCondition} />)
                
            })
            }
            <Button
            ref={buttonRef} 
            variant="contained"
            size="middle"
            // disabled={isError}
            startIcon={<CheckIcon />}
            style={{
              float: "right",
              margin:'3% 65px',
              borderRadius: "4px",
              background: 'rgba(0, 0, 0, 0.26)',
              borderRadius: '18px',
              fontWeight: '700',
              fontSize: '14px',
              lineHeight: '24px',
              position:"fixed",

              right:0,
              bottom:'20px',

              /* identical to box height, or 171% */
          
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
          
              /* primary / contrastText */
          
              color: '#FFFFFF'
            }}
            // className="button"
            onClick={() => handleSave()}
          >
            UPDATE
          </Button>    
        </>
    )

}