import React, { useEffect, useState } from "react";
import { FilterAttribute } from "./FilterAttribute"
import { DefaultFilterValues } from "./DefaultFilterValues"
import { NewFilterValues } from "./NewFilterValues"
import { NewConditionButton } from "./NewConditionButton"
import { NewFilterValuesNumerical } from "./NewFilterValuesNumerical";
const options=[
    {
        label:"Very High",
        value:4
    },
    {
        label:"High",
        value:3
    },
    {
        label:"Medium",
        value:2
    },
    {
        label:"Low",
        value:1
    },
    {
        label:"Very Low",
        value:0
    }
]
export const Filter=(props)=>{
    console.log("props from filterpage",props);
    props.filterObj.parameters.sort((a,b)=>{
        return a.rowid-b.rowid;
    })
 
    const [filterObj,setFilterObj]=useState(props.filterObj)
    // const [rendered,setRendered]=useState(false);
    // console.log("object is",props.filterObj,filterObj);
    const getRowId=()=>{
        let newRowId=0
        filterObj?.parameters?.map((obj)=>{
            if(newRowId<obj.rowid){
                newRowId=obj.rowid;
            }
        })
        return newRowId;
    }
    const [currentRowId,setCurrentRowId]=useState(getRowId());
    
    const getSelectedOption=(input)=>{
        // console.log("as",array)
        const selected=options.filter((obj)=>obj.value.toString()===input.toString());
        if(selected && selected.length>0){
            return selected[0]
        }else{
            return {"label":"","value":""}
        }
    }

    const getSelectedValue=(input)=>{
        
        // const data=;
        const newData=input.split(',').map((obj)=>{
            return {"label":obj,"value":obj}
        })
        // console.log("selected",newData);
        return newData;
    }
    const updateAttributeWeightage=(latestValue)=>{
        setFilterObj((latestObj)=>{
            const updateObj={...latestObj}
            updateObj.attributeWeightage=latestValue.value.toString();
            // updateObj.parameters.sort((a,b)=>{
            //     return a.rowid-b.rowid;
            props.updateFiltersData(updateObj);
            return updateObj;
        })
        // console.log("latestValue",latestValue);
    }

    const validateNumericalParameters=(obj)=>{
        
        let array=[]
        obj.parameters.map((obj)=>{
            if(obj.value!=='default'){
                array.push(obj.valueMin);
                array.push(obj.valueMax);
            }
        })
        // array.map((obj)=>{
        //     if(min>obj){
        //         return false;
        //     }
        // })
        // console.log("array is",array);
        // let min=0;
        // for(const val of array){
        //     console.log("values",min,val)
        console.log("array is",array);
        let min=array[0];
        for(let i=1; i<array.length;i++){
            let val=array[i]
            console.log("values",min,val);
            if(min===val){
                return {success:false, error:"Incorrect range value"}
            }else if(min>val){
                return {success:false,error:"Incorrect range value"}
            }
            min=val;
        }
        return {success:true}
    }

    const updateParameters=async(inputObj)=>{
        let error="";
        if(filterObj.type==='Numerical'){
            setFilterObj((latestObj)=>{
                const updateObj={...latestObj}
            updateObj?.parameters.map((obj)=>{
                if(obj.rowid===inputObj.rowid){
                    obj.importance=inputObj.importance;
                    obj.valueMin=parseInt(inputObj.valueMin);
                    obj.valueMax=parseInt(inputObj.valueMax);
                    return obj;
                }
            })
            let result=validateNumericalParameters(updateObj)
            console.log("inside result",result);
            if(result.success===true){
                props.setIsError(false);
                props.buttonRef.current.disabled = false;
                props.buttonRef.current.style.background='#21DA8C'
                props.updateFiltersData(updateObj);
            return updateObj;
            }else{
                props.setIsError(true);
                props.buttonRef.current.disabled = true;
                props.buttonRef.current.style.background='rgba(0, 0, 0, 0.26)'
                error=result?.error;
                return latestObj;
            }
            })
            return error;
        }else{
            setFilterObj((latestObj)=>{
                const updateObj={...latestObj}
                updateObj?.parameters.map((obj)=>{
                    if(obj.rowid===inputObj.rowid){
                        obj.importance=inputObj.importance;
                        obj.value=inputObj.value;
                        return obj;
                    }
                })
                props.updateFiltersData(updateObj);
                return updateObj;
            })
        }
        
    }

    const handleDeleteParameter=(rowId)=>{
        setFilterObj((latestObj)=>{
            const updateObj={...latestObj}
            const newObj=updateObj?.parameters.filter((obj)=>obj.rowid!==rowId)
            updateObj.parameters=newObj;
            props.updateFiltersData(updateObj);
            return updateObj;
        })
    }

    const addNewParameter=()=>{
        const newRow={
            "rowid": 0,
            "valueMin": null,
            "valueMax": null,
            "importance": 0,
            "value": "",
            "comments": "Comments"
        }
        newRow.rowid=currentRowId+1;
        setCurrentRowId((latest)=>latest+1);
        const updateObj={...filterObj}
        updateObj.parameters.push(newRow);
        setFilterObj(updateObj);
        props.updateFiltersData(updateObj);
    }

    const handleDeleteCondition=()=>{
        console.log("delete clicked",filterObj,getLabel(filterObj?.name,props.labels));
        props.deleteCondition(filterObj);
        // setRendered(!rendered)
        
    }

    // useEffect(() => {
    //   console.log("triggered")
    // }, [rendered])
    

    const getLabel=(name,values)=>{
        let label='';
        for(const obj of values){
            if(obj.name?.toLowerCase()===name?.toLowerCase()){
                label=obj?.label;
                break;
            }
        }
        return label;
    }

    const getOptions=(options)=>{
        if(options.length>0){
            const newOptions=options.map((obj)=>{
                obj.label=obj.value;
                return obj
            })
            return newOptions;
        }else{
            return [];
        }
        
    }
    // useEffect(() => {
      
    // }, [filterObj])
    
    return (
        <>
           {/* <h4>{JSON.stringify(filterObj)}</h4> */}
           <FilterAttribute styles={{margin:"10px",width:250}} options={options} name={getLabel(filterObj?.name,props.labels)} deleteCondition={handleDeleteCondition} selectedWeightage={getSelectedOption(filterObj?.attributeWeightage)} updateWeightage={updateAttributeWeightage} disabled={false} /> 
           {filterObj?.parameters.map((obj)=>{
            if(obj.value==="default" ){
                return(
                    <DefaultFilterValues key={obj.rowid} styles={{margin:"10px",width:250}} obj={obj} options={options}  selectedWeightage={getSelectedOption(obj?.importance)} updateObj={updateParameters} />
                )
            }
            else{
                if(filterObj.type==='Numerical'){
                    return <NewFilterValuesNumerical key={obj.rowid} obj={obj} options={options} selectedWeightage={getSelectedOption(obj?.importance)} updateObj={updateParameters} handleDelete={handleDeleteParameter}/>
                }else{
                    return(
                    <NewFilterValues key={obj.rowid} styles={{margin:"10px",width:250}} obj={obj} options={options} valueOptions={getOptions(props.valuesData)} selectedValue={getSelectedValue(obj.value)}  selectedWeightage={getSelectedOption(obj?.importance)} updateObj={updateParameters} handleDelete={handleDeleteParameter}/>
                )
                }
                
            }
           })}
           
           
           <div style={{margin:'10px'}}>
           <NewConditionButton addNewCondition={addNewParameter} />
           </div>
           <hr style={{color:"#E0E0E0",opacity:"0.5",marginTop:'30px',marginBottom:"20px"}}></hr>
        </>
    )
}