import React, { useEffect } from "react"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import _uniqueId from 'lodash/uniqueId'

//var importanceType = "level"
var importanceLevel = {
    "level": [
        {
            "levelName": "Very High",
            "levelRating": 4
        },
        {
            "levelName": "High",
            "levelRating": 3
        },
        {
            "levelName": "Medium",
            "levelRating": 2
        },
        {
            "levelName": "Low",
            "levelRating": 1
        },
        {
            "levelName": "Very Low",
            "levelRating": 0
        }
    ]
}  

var valueRatingLevel = 1
const ComboBoxImportance = (props) => {
    console.log(props)
    const [valueLevelComboBox, setValueLevelComboBox] = React.useState()
    const [id] = React.useState(_uniqueId('combo-box-demo-'))

    const setimportanceData = () => {
        props.currentImportance(props.imp)
        setImportanceName()
    }

    const setImportanceName = () => {
        let importanceObject = importanceLevel.level.find(function (i) {
            console.log(i.levelRating)
            return i.levelRating == props.imp
        })
        setValueLevelComboBox(importanceObject.levelName)
    }

    useEffect(() => {
        setimportanceData()
    })

    const [inputLevelValue, setInputLevelValue] = React.useState('')

    const calculateImportance = (valueRatingLevel) => {
        props.onUpdateImportance(valueRatingLevel)
    }


    return (
        <div style={{marginLeft: "10px"}}>
            <Autocomplete
                id={id}
                size="small"
                options={importanceLevel.level}
                getOptionLabel={option => ((option === null) ? "" : option.levelName)}
                disableClearable
                disabled={props.disable}
                autoSelect
                onChange={(event, newValue) => {
                    newValue === null ? valueRatingLevel = 1:
                            valueRatingLevel = newValue.levelRating
                            
                            newValue === null ?  //condition of what to set in combobox  
                            setValueLevelComboBox(""):
                            setValueLevelComboBox(newValue.levelName)  //This is to set the name in combobox
                            calculateImportance(valueRatingLevel)  
                }}

                inputValue={inputLevelValue}
                onInputChange={(event, newInputValue) => {
                    setInputLevelValue(newInputValue)
                }}

                style={{ width: 130 }}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={valueLevelComboBox}
                        variant="outlined"
                        fullWidth
                    />
                )}
            />
        </div>

    )
}


export default ComboBoxImportance