import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { useState } from 'react';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './customDialogBox.css'
export const CustomDialogBox=(props)=>{
    // const [open, setOpen] = useState(props.state);
    const handleClose = () => {
        props.setOpen(false);
    };

    // const handleClickOpen = () => {
    //     setOpen(true);
    // }; 

    const handleDeleteClick=()=>{
        props.handleDelete();
        props.setOpen(false);
    }
    return(
        <Dialog
        className='deleteUser'
        open={props.open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
        <div style={{position:"absolute",left:"87%"}}>
        <Button variant='text' style={{color:"rgba(3, 3, 3, 0.6)",minWidth:"28px",minHeight:"28px",borderRadius:"20px"}} onClick={handleClose}>
        <CloseIcon/>
        </Button>
        </div>
            <div>
            <Typography variant='h6' style={{fontFamily:"Nunito Sans",fontWeight:800,fontSize: "16px" ,lineHeight: "36px",marginBottom:"20px"}}>Are you sure you want to remove the {props.name} attribute</Typography>
            <Typography variant='body1' style={{fontFamily:"Nunito Sans",fontWeight:400,fontSize: "16px"}}>All the value weightage pairs associated with the attribute will be lost</Typography>
            </div>  
        <DialogActions>
        <div style={{display:"flex",alignItems:"center","justifyContent":"flex-end",marginTop:"20px"}}>
        <Button onClick={handleClose}  variant="text" style={{color:"#21DA8C"}}>
          <Typography variant='button medium' style={{fontFamily:"Nunito Sans",fontWeight:800, fontSize: "14px",lineHeight: "24px"}}>No</Typography>
          </Button>
          <Button onClick={handleDeleteClick}  variant="contained" style={{backgroundColor:"#21DA8C",color:"#fff",borderRadius:"45px",width:"45px",minWidth:"28px",height:"32px"}}>
          <Typography variant='button medium' style={{fontFamily:"Nunito Sans",fontWeight:800, fontSize: "14px",lineHeight: "24px"}}>Yes</Typography>
          </Button>
        </div>
        </DialogActions>
        </DialogContent>
      </Dialog>
    )
}