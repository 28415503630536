import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import _uniqueId from 'lodash/uniqueId';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         display: 'flex',
//         justifyContent: 'center',
//         flexWrap: 'wrap',
//         '& > *': {
//             margin: theme.spacing(0.5),
//         },
//         fontSize: '0.912rem',
//         borderRadius: '4px',

//     }
// }));

const Chips = (props) => {
    //const classes = useStyles();
    const [format, setFormat] = useState('');
    ///const [id] = React.useState(_uniqueId('chips-demo-'));

    // const displayFilter = () => {
    //     setFormat(props.topic);
    // }

    // useEffect(() => {
    //     displayFilter();
    // }, [])

    const handleDelete = () => {
        console.info('You clicked the delete icon.');
        console.log("unchecking ", [...props.topicsChips], props.topic);
        const indexOfAttr = props.topicsChips.findIndex((obj) => (obj?.topic_name === props.topic?.topic_name));
        const newAttrs = [...props.topicsChips];
        console.log("unchecking ", indexOfAttr, [...newAttrs]);
        newAttrs.splice(indexOfAttr, 1);
        console.log("unchecking ", [...newAttrs]);
        props.setTopicsChips(newAttrs);
    };

    const handleClick = () => {
        console.info('You clicked the Chip.');
    };
    return (
        <React.Fragment>
            <div style={{ paddingLeft: "10px",paddingTop:"6px"}}>
                <Chip
                    id='chip'
                    variant="outlined"
                    size="small"
                    style={{ margin: "10px", backgroundColor: "#8A8A8A", color: "#FFFFFF", fontSize: "small" }}
                    //classes={{ root:classes.root }}
                    label={props?.topic?.topic_name}
                    onClick={handleClick}
                    onDelete={handleDelete}
                    deleteIcon={<CloseRoundedIcon style={{ color: "#FFFFFF", fontSize: "medium", fontWeight: "700" }} />}


                />
            </div>
        </React.Fragment>
    )
}

export default Chips
