import React from 'react'
import { Typography } from '@mui/material';
import SearchBar from 'material-ui-search-bar';
import Dropdown from './Dropdown';




const DropdownSearch = (props) => {

     console.log("drop",props.categories)
     console.log("drop",props.searchTopicsList)

    function handleChange(typedTopicName) {
        // let typedTopicName = e.target.value;
        // let keyListSearch = Object.keys(props.searchTopicsList)

        let removeTopics = [...props.otherSelectedTopics[0], ...props.otherSelectedTopics[1]]
        //console.log("removeTopics", removeTopics)
        let tempTopicsListObject = { ...props.topicsList }
        // console.log("tempTopicsListObject", tempTopicsListObject)

        removeTopics.forEach(topic => {
            //console.log("tempTopicsListObject", JSON.stringify(tempTopicsListObject[topic.provider_category_id]))
            let newArray = tempTopicsListObject[topic.provider_category_id].filter((item) => item.topic_name !== topic.topic_name);
            //console.log('newArray', JSON.stringify(newArray))
            //delete tempTopicsListObject[topic.provider_category_id]
            //console.log('delete', JSON.stringify(tempTopicsListObject))
            tempTopicsListObject[topic.provider_category_id] = newArray
            //console.log('addition', JSON.stringify(tempTopicsListObject))

        });


        let keyListTopic = Object.keys(tempTopicsListObject)
        let searchedObj = {};
        let categories = [];

        //console.log("DropdownSearch searching topicName", typedTopicName)
        
        for (let i=0; i < keyListTopic?.length; i++) {
            let categoryArray = tempTopicsListObject[keyListTopic[i]];
            let result = categoryArray.filter(o => (o.topic_name || '').toLowerCase().includes((typedTopicName || '').toLowerCase()));
            if (result.length > 0) {
                searchedObj[keyListTopic[i]] = [...result]
            }

            //console.log("DropdownSearch searching", result[0]?.category_name, result)
            
          let tempObj = {};
          if(categoryArray[0] && result.length > 0) {
            tempObj.provider_category_id = keyListTopic[i];
            tempObj.category_name = categoryArray[0].category_name;
            categories.push({...tempObj})
          }
        }

        // if (categories?.length <=0) {
        //     props.handleSearchClose();
        // }

        //console.log("DropdownSearch searching", searchedObj, categories)
        props.setSearchTopicsList({...searchedObj})
        props.setCategories([...categories])
    }

    function handleClick(e) {


        props.setSearchTopicsList({...props.topicsList})

        let keyListTopic = Object.keys(props.topicsList)
        let categories = [];

        for (let i=0; i < keyListTopic?.length; i++) {
            let categoryArray = props.topicsList[keyListTopic[i]];

          let tempObj = {};
          if(categoryArray[0]) {
            tempObj.provider_category_id = keyListTopic[i];
            tempObj.category_name = categoryArray[0].category_name;
            categories.push({...tempObj})
          }
        }

        props.setCategories([...categories])
    }
    //, boxShadow: "rgb(63 63 68 / 5%) 0px 0px 2px 1px, rgb(63 63 68 / 15%) 0px 1px 3px 3px"
    return (
        <>
            <SearchBar
                className="search"
                onChange={handleChange}
                placeholder="Search"
                onCancelSearch={handleClick}
                style={
                    
                    { margin: '0 auto' },
                    { maxwidth: 800 },
                    {marginBottom: 3, zIndex: 1000
                }

                }
            />
            { !(Array.isArray(props.categories) && !props.categories.length) && !(Array.isArray(props.topicsList) && !props.topicsList.length) ? <Dropdown  handleSearchClose={props.handleSearchClose} categories={props.categories} searchTopicsList={props.searchTopicsList || []}  setTopicsChips={props.setTopicsChips} levelName={props.levelName} topicsChips={props.topicsChips || []} topicsList={props.topicsList || []}></Dropdown>:<React.Fragment style={{align: 'center'}}><Typography style={{align: 'center', margin: 10}}>No Results Found</Typography></React.Fragment>}
        </>
    )
}

export default DropdownSearch