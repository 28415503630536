import React, { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import _uniqueId from 'lodash/uniqueId'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiInputBase-root': {
            borderRadius:"8px",
            height:"32px",
            padding: "0px 0px !important"
          },
        '& > * + *': {
            marginTop: theme.spacing(2),
           
        },
        "& .MuiInputBase-root.Mui-disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)" 
          }
    },
    

}))


const RatingLevelComboBoxNew = (props) => {
    //console.log(props)
    const [ratingValue, setRatingValue] = React.useState(props.inputValue)
    let indexOfRatingValue = props.comboBoxData.findIndex(function (i) {
        return i.levelRating == props.inputValue
    })
    const [ratingName, setRatingName] = useState()
    const setData = () => {
        console.log(props.inputValue)
        console.log(props.comboBoxData)
        console.log(indexOfRatingValue)
        if(indexOfRatingValue===-1){
            setRatingName(props.comboBoxData[0].levelName)
        }
        else{
            setRatingName(props.comboBoxData[indexOfRatingValue].levelName)
        }
        
    }

    useEffect(() => {
        setData();
    },[])

    useEffect(()=>{
setData();
    },[props])

    const [id] = React.useState(_uniqueId('rating-level'))
    const classes = useStyles()

    const newValueNotNull = (newValue) => {
        setRatingValue(newValue.levelRating)
        setRatingName(newValue.levelName)
    }
    const newValueNull = () => {
        setRatingValue(0)
        setRatingName("")
    }

    const notInProperFormat = (newValue) => {
        console.log(newValue)
        console.log(props.comboBoxData)
        props.comboBoxData.forEach((importance) => {
            console.log(newValue.levelName)
            console.log(importance)
            if(importance.levelName === newValue.levelName){
                console.log("triggered")
                props.valueFunc(newValue.levelRating, props.label, props.rowId)
            }
        })

    }
    const styles = {
        disabledInput: {
          "& .MuiInputBase-root.Mui-disabled": {
            color: "black"
          }
        }
      };
      

    return (
        <div className={classes.root} >
            <Autocomplete
                id={id}
                size="small"
                options={props.comboBoxData}
                popupIcon={<KeyboardArrowDownIcon />}
                getOptionLabel={(option) => (option === null) ? "" : option.levelName}
                autoSelect
                disableClearable
                onChange={(event, newValue) => {
                    //console.log(newValue)
                    newValue === null ? newValueNull() : newValueNotNull(newValue)
                    notInProperFormat(newValue)
                }}
                disabled={props.disable}
                
                onInputChange={(event, newValue) => {
                    newValueNotNull(newValue)
                }}

                //defaultValue={comboBoxData[0].name}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder={ratingName} />
                )}
            />
        </div>
    )
}

export default RatingLevelComboBoxNew