import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import FormDialog from "./FormDialog";import Button from '@mui/material/Button';
import Icon from '@material-ui/core/Icon';

const DefaultScreenConfig =(props)=>{

    const getData = () => {
        //call back function for populating data in table after change in ICP list due to CRUD operation 
        props.getData();
    }

    const routeToIcpConditionFromBar = (icpname1) => {
        props.routeToIcpConditionFromIcpList(icpname1);
    }

    const snackbarCallbackToBar = (snackbarStateFromFormDialog) => {
        props.snackbarCallbackToIcplist(snackbarStateFromFormDialog)
    }

    return(
        <div>
            {
            props.previousICP === 'ICPcondition' ?
                <React.Fragment></React.Fragment> : 
                <div style={{marginLeft :"2%",flexDirection :"column",marginLeft :"2%",marginRight:'1%' , height:'87vh'}}>
                    <div style={{textAlign :"center", position:'relative',top:'40%'}}>
                        <div style={{ 
                        marginBottom :"1%",
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '18px',
                        lineHeight: '18px',
                        color: '#CACACA'
                        }}>Create an ICP to start scoring.</div>
                        {/* <Button style={{backgroundColor: "#21DA8C" , borderRadius: "18px", color:"white",width: "170px" , height:"50px"}}> */}
                            <FormDialog icpname={props.icpname} previousICP={props.previousICP} ICPlistData={props.ICPlistData} currentICPFilterlength={props.currentICPFilterlength} content={props.content} getData={getData} routeToIcpConditionFromBar={routeToIcpConditionFromBar} copyOfIcpConditionAttribute={props.copyOfIcpConditionAttribute} snackbarCallbackToBar={snackbarCallbackToBar} setBackdropTrue={props.setBackdropTrue} setBackdropFalse={props.setBackdropFalse} snackbarCallbackToShowFilter={props.snackbarCallbackToShowFilter} icptext={"CREATE PROFILE"} />
                        {/* </Button> */}
                    </div>
                </div>
        }
        </div>
        
    )
}

export default DefaultScreenConfig;