import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ContainedButtons from "./ContainedButtons"
import CreateICPForm from "./CreateICPForm"
import axios from 'axios'
import { REACT_APP_API_URL } from "./../Constant"
import Icon from '@material-ui/core/Icon'
import { makeStyles } from '@material-ui/core/styles'
import { useAuthState } from "@bamboobox/b2logincheck"
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from "@mui/system";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Alert from '@mui/material/Alert';
import "./../toast.css"
toast.configure({
  autoClose: 6000,
  draggable: true,
  hideProgressBar: false,
  position: toast.POSITION.BOTTOM_LEFT,
  icon: false,
});
const Dialog1 = styled(Dialog)(({ theme }) => ({

  [`.MuiPaper-rounded`]:{
    borderRadius: '18px',
  },
}))

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
    width: 170,
    height: 35,
    textTransform: 'none',
  },
  containedPrimary: {
    color: "#FFFFFF",
    opacity: "90%"
  },
  paperWidthSm: {
    maxWidth: '600px',
    minWidth: '600px'
  },
  paperWidthSm10: {
    maxWidth: '400px'
  }

}))

var newICP1
var filterName
var industry1CC
var comment1

const CREATE_ICP_BUTTON_SVG = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.25 6.75H6.75V11.25H5.25V6.75H0.75V5.25H5.25V0.75H6.75V5.25H11.25V6.75Z" fill="white"/>
  </svg>
)

const FormDialog = (props) => {
  const userData = useAuthState()

  const [open, setOpen] = React.useState(false)
  const [buttonContent, editButtonContent] = React.useState(props.content)
  const [previousICP, editPreviousICP] = React.useState(props.previousICP)
  const [filterId, editfilterId] = React.useState(props.filterId)
  const [currentICPFilterlength, editcurrentICPFilterlength] = React.useState(props.currentICPFilterlength)
  const startIconAdd = CREATE_ICP_BUTTON_SVG;
  const buttonContentCreateICP = props.icptext

  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onChangeICP = (e) => {
    newICP1 = e.target.value
  }

  const onFilterName = (e) => {
    filterName = e.target.value
  }

  const onChangeIndustryCC = (e) => {
    industry1CC = e.target.value
  }

  const onChangeComment = (e) => {
    comment1 = e.target.value
  }
  const onSubmitfilter = (e) => {
    e.preventDefault()
    props.setBackdropTrue()
    var icpNameForAddingFilter = props.icpname
    console.log(props.copyOfIcpConditionAttribute)
    let map = {}
    let newArray = []
    props.copyOfIcpConditionAttribute.forEach(el => {
      if (!map[JSON.stringify(el)]) {
        el["parameters"]=[{comments: null,
          importance: 0,
          rowid: 0,
          value: "default",
          valueMax: 0,
          valueMin: 0}]
        map[JSON.stringify(el)] = true
        newArray.push(el)
      }
    })
    console.log("newArray",newArray)
    var newFilterFormat = {
      "filterName": filterName?.trim(),
      "filterRank": "",
      "icpFilter": [],
      "icpCondition": newArray
    }
    try {
      axios.post(REACT_APP_API_URL + '/filter/' + userData.user.tenantId + '/' + icpNameForAddingFilter, newFilterFormat, { withCredentials: true })
        .then((result) => {
          if (result.data === "can't create the filter with the same FilterName") {
            // props.snackbarCallbackToShowFilter({
            //   open: true,
            //   severity: "success",
            //   message: "Cannot create Filter with same name"
            // })
            toast.error(<Alert severity="error">Cannot create Filter with same name</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
          }
          else {
            getTableData()
            // props.snackbarCallbackToShowFilter({
            //   open: true,
            //   severity: "success",
            //   message: "Filter added successfully"
            // })
            toast.success(<Alert severity="success">Filter added successfully</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
          }
          props.setBackdropFalse()
        })
    } catch (error) {
      console.log(error)
    }
    setOpen(false)
  }

  const onCopyCreate = (e) => {
    e.preventDefault()
    try {
      axios.post(REACT_APP_API_URL + '/scoredefinition/' + userData.user.tenantId + '/' + newICP1 + '/' + industry1CC + '/' + filterId + '/' + comment1, { withCredentials: true })
        .then(() => {
          getTableData()
        })
    } catch (error) {
      console.log(error)
    }
    setOpen(false)
  }

  const getTableData = () => {
    props.getData()
  }

  const routeToIcpConditionFromFormDialog = (icpname1) => {
    props.routeToIcpConditionFromBar(icpname1)
  }

  const snackbarCallbackToFormdialog = (snackbarStateFromCreateIcpFormdialog) => {
    // console.log(snackbarStateFromCreateIcpFormdialog)
    // console.log("formdialog")
    props.snackbarCallbackToBar(snackbarStateFromCreateIcpFormdialog)
  }

  return (
    <div>
      { previousICP === 'noICP' && buttonContent !== 1 ?
        <React.Fragment>
          <ContainedButtons startIconAdd={startIconAdd} buttonContentCreateICP={buttonContentCreateICP} handleClickOpen={handleClickOpen} />
          <Dialog1 classes={{ paperWidthSm: classes.paperWidthSm }} style={{ minWidth: '600px' }} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
           
            <DialogContent>
             
              <CreateICPForm handleClose={handleClose} handleClickOpen={handleClickOpen} previousICP={previousICP} getTableData={getTableData} routeToIcpConditionFromFormDialog={routeToIcpConditionFromFormDialog} snackbarCallbackToFormdialog={snackbarCallbackToFormdialog} />
            </DialogContent>
          </Dialog1></React.Fragment> :

        previousICP !== 'noICP' && buttonContent === 3 ?
          <div>
            <Tabs>
            <Tab
              style={{color:'#21DA8C'}}
              label ="+ New Filter"
              startIcon={<Icon>add</Icon>}
              onClick={handleClickOpen}
            />
            </Tabs>

            <Dialog1 open={open} onClose={handleClose} aria-labelledby="form-dialog-title" 
            style={{borderRadius:'16px'}}
            >
              <DialogTitle id="form-dialog-title" style={{
               fontStyle: 'normal',
               fontWeight:'700',
               fontSize: '20px',
               lineHeight: '24px',
               color: '#595959',
               paddingBottom:'0px'

              }}>Add New Filter</DialogTitle>
              <DialogContent>
                {/* <DialogContentText>
                  To create new filters
                </DialogContentText> */}
                <form onSubmit={onSubmitfilter}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="filterName"
                    variant="outlined"
                    id="filterName"
                    label="Filter Name"
                    onChange={onFilterName}
                    type="Text"
                    fullWidth
                    style={{marginBottom:'10px'}} 
                  />
                  <DialogActions>
                    <Button 
                    
                    // style={{ textTransform: 'none', fontSize: '1.1rem' }} 
                    style={{
                      color:'#A0A0A0',
                      borderColor: "#A0A0A0",
                      backgroundColor: "#FFFFFF",
                      boxShadow: "0px 4px 5px -2px rgba(200, 200, 200, 0.7)",
                      borderRadius:"18px",
                      padding:"2px",
                      width:"104px"
                    }}
                    onClick={handleClose} color="primary">
                    <div style={{paddingRight:"8px"}}>
                    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.66896 6.92443L6.15443 4.40952L8.4878 2.07615C8.92925 1.63527 8.92925 0.920166 8.4878 0.478909C8.04673 0.0378426 7.33162 0.0380338 6.89094 0.478909L4.55719 2.81228L2.10935 0.364247C1.66809 -0.0768192 0.952983 -0.0768192 0.512109 0.364247C0.0712337 0.805504 0.0712337 1.52023 0.512109 1.96149L2.96014 4.40952L0.330943 7.03852C-0.110314 7.47997 -0.110314 8.1947 0.330943 8.63595C0.772009 9.07683 1.48693 9.07683 1.9278 8.63595L4.55719 6.00676L7.07211 8.52167C7.51317 8.96274 8.22809 8.96274 8.66896 8.52167C9.11041 8.08022 9.11022 7.36512 8.66896 6.92443Z" fill="#A0A0A0"/>
                    </svg>
                    </div>

                      Cancel
                    </Button>
                    <Button 
                    
                    // style={{ textTransform: 'none', fontSize: '1.1rem' }} 
                   
                    style={{ marginLeft: "10px", 
                      color: "#FFFFFF",
                      backgroundColor: "#21DA8C",
                      boxShadow: "0px 4px 5px -2px rgba(200, 200, 200, 0.7)",
                        borderRadius:"18px",
                        padding:"2px",
                        width:"110px"
                      
                      }}

                    type="submit" color="primary">
                       <div style={{paddingRight:"8px"}}>
                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.2955 2.82895L5.36063 8.76339C4.92115 9.20296 4.20824 9.20296 3.76834 8.76339L0.704737 5.69955C0.265088 5.25999 0.265088 4.54699 0.704737 4.10734C1.14447 3.66761 1.85732 3.66761 2.29686 4.10718L4.56472 6.37507L9.7031 1.23666C10.1428 0.796924 10.8557 0.797257 11.2953 1.23666C11.7349 1.67631 11.7349 2.38905 11.2955 2.82895Z" fill="#FFFFFF"/>
                    </svg>
                  </div>
                      Create
                    </Button>
                  </DialogActions>
                </form>
              </DialogContent>
            </Dialog1></div>
          : <div><Button
            variant="contained"
            color="primary"
            classes={{ containedPrimary: classes.containedPrimary }}
            className={classes.button}
            value={props.previousICP}
            startIcon={<Icon>create</Icon>}
            onClick={handleClickOpen}
            style={{ marginLeft: "10px", 
            color: "#FFFFFF",
            backgroundColor: "#21DA8C",
            boxShadow: "0px 4px 5px -2px rgba(200, 200, 200, 0.7)",
              borderRadius:"18px",
              padding:"2px",
              width:"110px"
            
            }}

          >
            COPY & CREATE
  </Button>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  To create new ICP with the existing Data of the chosen ICP
      </DialogContentText>
                <form onSubmit={onCopyCreate}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="Current ICP"
                    id="currentICP"
                    label="Current ICP"
                    value={previousICP}
                    type="Text"
                    fullWidth
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    name="New ICP"
                    id="newICP"
                    label="New ICP"
                    onChange={onChangeICP}
                    type="Text"
                    fullWidth
                  />

                  <TextField
                    autoFocus
                    margin="dense"
                    name="Industry"
                    id="industry"
                    label="Industry"
                    onChange={onChangeIndustryCC}
                    type="Text"
                    fullWidth
                  />

                  <TextField
                    autoFocus
                    margin="dense"
                    name="filterID"
                    id="filterID"
                    label="FilterID"
                    value={filterId}
                    type="Text"
                    fullWidth
                  />

                  <TextField
                    autoFocus
                    margin="dense"
                    name="comment"
                    id="comment"
                    label="Comment"
                    onChange={onChangeComment}
                    type="Text"
                    fullWidth
                  />


                  <DialogActions>
                    <Button onClick={handleClose} color="primary"
                    style={{
                      color:'#A0A0A0',
                      borderColor: "#A0A0A0",
                      backgroundColor: "#FFFFFF",
                      boxShadow: "0px 4px 5px -2px rgba(200, 200, 200, 0.7)",
                      borderRadius:"18px",
                      padding:"2px",
                      width:"104px"
                    }}
                    >
                    <div style={{paddingRight:"8px"}}>
                    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.66896 6.92443L6.15443 4.40952L8.4878 2.07615C8.92925 1.63527 8.92925 0.920166 8.4878 0.478909C8.04673 0.0378426 7.33162 0.0380338 6.89094 0.478909L4.55719 2.81228L2.10935 0.364247C1.66809 -0.0768192 0.952983 -0.0768192 0.512109 0.364247C0.0712337 0.805504 0.0712337 1.52023 0.512109 1.96149L2.96014 4.40952L0.330943 7.03852C-0.110314 7.47997 -0.110314 8.1947 0.330943 8.63595C0.772009 9.07683 1.48693 9.07683 1.9278 8.63595L4.55719 6.00676L7.07211 8.52167C7.51317 8.96274 8.22809 8.96274 8.66896 8.52167C9.11041 8.08022 9.11022 7.36512 8.66896 6.92443Z" fill="#A0A0A0"/>
                    </svg>
                    </div>
                      Cancel
      </Button>
                    <Button type="submit" color="primary"
                    style={{ marginLeft: "10px", 
                    color: "#FFFFFF",
                    backgroundColor: "#21DA8C",
                    boxShadow: "0px 4px 5px -2px rgba(200, 200, 200, 0.7)",
                      borderRadius:"18px",
                      padding:"2px",
                      width:"110px"
                    
                    }}
                    >
                    <div style={{paddingRight:"8px"}}>
                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.2955 2.82895L5.36063 8.76339C4.92115 9.20296 4.20824 9.20296 3.76834 8.76339L0.704737 5.69955C0.265088 5.25999 0.265088 4.54699 0.704737 4.10734C1.14447 3.66761 1.85732 3.66761 2.29686 4.10718L4.56472 6.37507L9.7031 1.23666C10.1428 0.796924 10.8557 0.797257 11.2953 1.23666C11.7349 1.67631 11.7349 2.38905 11.2955 2.82895Z" fill="#FFFFFF"/>
                    </svg>
                  </div>
                      Create
      </Button>
                  </DialogActions>
                </form>
              </DialogContent>
            </Dialog></div>

      }
    </div>
  )
}

export default FormDialog
