import { InputAdornment, makeStyles, TextField } from "@material-ui/core"
import { useEffect, useState } from "react"
import _uniqueId from 'lodash/uniqueId';


const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    root: {
      border: "none"
    },
    textField:{
      '& .MuiOutlinedInput-root': {
        position: "relative",
        borderRadius: "8px",
        width: "130px",
        height: "32px",
    }
  },
    contained: {
      backgroundColor: "primary"
    }
  })

const SimpTextField = (props) => {
    const classes = useStyles()
    const regexp = new RegExp(`^(0|[1-9]+[0-9]*)`)
    const [id] = useState(_uniqueId('SimpleTextField-'))
    const [inputValue, setInputValue] = useState(props.inputValue)
    const [error, seterror] = useState(false)
    
  
    const errorStatePass = (changedTextFieldValue) => {
      seterror(false);
      setInputValue(changedTextFieldValue)
    }
  
    const errorStateFail = () => {
      seterror(true)
    }
    
  
  
    const onChange = (e) => {
      let changedTextFieldValue;
      e.target.value === "" ? (changedTextFieldValue = 0) : (changedTextFieldValue = parseInt(e.target.value))
      regexp.test(changedTextFieldValue) ? errorStatePass(changedTextFieldValue) : errorStateFail()
      if (error === false) { props.valueFunc(changedTextFieldValue, props.label, props.rowId); }
    }
  
    useEffect(()=>{
      console.log('value is', inputValue);
      console.log('props inputvalue is', props.inputValue);
      console.log('rendering simptextfield'); 
    })

    useEffect(()=>{
      setInputValue(props.inputValue)
    },[props.inputValue]);
  
    // const debouncedOnChange = useMemo(
    //   () => debounce(onChange, 300)
    // , []);
  
    // useEffect(() =>{
    //   const timeout = setTimeout(() => {
    //     setInputValue(TFRef.current.value);
    //     if (error === false) { props.valueFunc(inputValue, props.label, props.rowId); }
    //   }, 600);
    //   return () => {
    //     clearTimeout(timeout);
    //   };
    // },[inputValue,error,props])
    
     return (
       <>
         <form className={classes.root} noValidate autoComplete="off">
           <TextField
             className={classes.textField}
             style={{ width: "130px",height:"32px !important" }}
             size="small"
             value={isNaN(inputValue) ? '' : inputValue}
             placeholder={props.placeHolderValue}
             variant="outlined"
             id={id}
             error={error}
             onChange={onChange}
             InputProps={{
               endAdornment: (
                 <InputAdornment position="end">{props.endAdornment}</InputAdornment>
               ),
             }}
           />
         </form>
       </>
     );
   }


   export default SimpTextField;