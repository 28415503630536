import { FilterHeader } from "./FilterHeader"
import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CustomAutoComplete } from "./CustomAutoComplete";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DeleteButton } from "./DeleteButton";

const useStyles = makeStyles((theme) => ({
    textField:{
        height:'38px',
    },
    head:{
        width:'36ch',
        // marginRight:'60px',
        fontWeight: '700',
        fontSize: '11px',
        lineHeight: '6px',
        letterSpacing: '0.06em',
        textTransform: 'uppercase',
        color: '#595959'
        
    },
    auto:{
        marginRight:'60px',
    }
}));

// const props={}

// props.icpFilters=[
//     {
//         "name": "accountRegion",
//         "type": "hierarchical",
//         "relation": "=",
//         "value": "India"
//     }
// ]


export const NewConditionFilter =(props)=>{
    console.log("icp props are",props);
    const classes = useStyles();
    // const [showForm, setShowForm]=useState(false);
    const [attribute,setAttribute]=useState(props?.icpFilter?.name || "");
    const [relation,setRelation]=useState(props?.icpFilter?.relation || "");
    const [relations,setRelations]=useState([]);
    const [value,setValue]=useState(props?.icpFilter?.value || "");
    const [values,setValues]=useState([]);
    const [filterObj,setFilterObj]=useState(props.icpFilter);
    const [numerical,setNumerical]=useState(false)
    // const [icpFilters,setIcpFilters]=useState(props.icpFilter || [])
    
    // const options=

    // const getRelations=()=>{
    //     props.valuesData.map((obj)=>{
    //         return {
    //             "label":""
    //         }
    //     })
    // }

    const attributeChange=(value)=>{
        setFilterObj((latestObj)=>{
            const newObj={...latestObj};
            newObj.name=value;
            newObj.type=getType(value);
            newObj.relation=""
            newObj.value=""
            if(newObj.type==='Numerical'){
                setNumerical(true);
            }else{
                setNumerical(false);
            }
            console.log("updatedObject",newObj);
            props.updateFilterObject(newObj);
            return newObj;
        })
        setAttribute(value);
        setRelations(getRelations(value));
        setValues(getValues(value));
        setRelation("");
        setValue("");
    }

    const getOptions=()=>{
        let options=[]
        const existingOptions=props.filters.map((obj)=>obj.name);
        options=props?.valuesData?.filter((obj)=>!existingOptions.includes(obj.name)).map((obj)=>{
            return obj.label;
        })
        // console.log("options are",options);
        return options;
    }

    // const filterOptions=()=>{
    //     const existingOptions=props.filters.map((obj)=>obj.name);
    //     const newOptions=attributes.filter((obj)=>!existingOptions.includes(obj.name));
    //     // console.log("existing",existingOptions,newOptions)
    //     return newOptions;
    // }

    const getRelations=(attribute)=>{
        let options=[]
        if(attribute && attribute.length>0){
            options=props.valuesData.filter((obj)=>obj.label===attribute).map((obj)=>obj.relation);
            console.log("options are",options);
            if(options.length>0){
                return options[0];
            }
            
        }
        return options
    }

    const getType=(attribute)=>{
        let options=[]
        if(attribute && attribute.length>0){
            options=props.valuesData.filter((obj)=>obj.label===attribute).map((obj)=>obj.type);
            console.log("type is",options);
            if(options.length>0){
                return options[0];
            }
            
        }
    }

    const getValues=(attribute)=>{
        let options=[]
        if(attribute && attribute.length>0){
            options=props.valuesData.filter((obj)=>obj.label===attribute).map((obj)=>obj?.values || []);
            console.log("options are",options);
            if(options.length>0){
                return options[0];
            }
            
        }
        return options
    }
    // const attributeOptions=getOptions("name");
    // const Relations=getRelations();

    // const addCondition=()=>{
    //     props.AddnewIcpFilter();
    // }

    const handleChange=(value,type)=>{
        console.log("value",value);
        setFilterObj((latestObj)=>{
            const newObj={...latestObj};
            if(type==='relation'){
                newObj.relation=value?.value;
                setRelation(value)
            }else if(type==='value'){
                newObj.value=value?.value;
                setValue(value.value);
            }
            console.log("newObject",newObj);
            props.updateFilterObject(newObj);
            return newObj;
        })
    }

    const handleDelete=()=>{
        props.deleteIcpFilter(filterObj);
    }

    return (
        <>
            <div style={{display:"flex",margin:'20px 0px', marginRight:"10px",justifyContent:"space-between",alignItems:"center"}}>
            <Autocomplete
                options={getOptions()}
                value={attribute}
                onChange={(e,value)=>attributeChange(value)}

                sx={{width:250}} 
                renderInput={(params) => 
                <TextField {...params} placeholder={"Attribute"} variant={"outlined"}/>}
            />
            <Autocomplete
                options={relations}
                value={relation}
                onChange={(e,value)=>handleChange(value,"relation")}
                sx={{width:250}} 
                renderInput={(params) => 
                <TextField {...params} placeholder={"Attribute"} variant={"outlined"}/>}
            />
            {numerical? <div className="numerical">
                <TextField 
                     options={values}
                value={value}
                type="number"
                onChange={(e)=>handleChange({"value":e.target.value},"value")}
                sx={{width:250}} 
                />
            </div>:<>
            <Autocomplete
                options={values}
                value={value}
                onChange={(e,value)=>handleChange(value,"value")}
                sx={{width:250}} 
                renderInput={(params) => 
                <TextField {...params} placeholder={"Attribute"} variant={"outlined"}/>}
            />
            </>}
           
            <div style={{marginLeft:"10px"}}>
            <DeleteButton handleDelete={ handleDelete} />
            </div>
            
            </div>
        </>
    )
}